$dark-green: #125454; /* stylelint-disable-line color-no-hex */

.superadmin-wrapper {
  .sa {
    color: $superadmin;

    .round-icon {
      border: 1px solid $superadmin;

      i {
        color: $superadmin;
      }
    }
  }

  .btn {
    background-color: $white;
    border-color: $superadmin;
    color: $superadmin;

    &:hover, &:focus, &.btn-active {
      background-color: $white;
      border-color: $dark-green;
      color: $dark-green;

      .button-top-right {
        transform: scale(1.1);
        top: -43px;
      }
    }
  }

  .card {
    border: 1px solid $superadmin;
    width: 100%;
  }

  .flex-card {
    text-align: center;
  }

  .superadmin-wrapper-box {
    border-radius: $default-border-radius;
    border: 1px solid $superadmin;
    display: inline-block;
    margin-bottom: 20px;

    &.superadmin-wrapper-box-small {
      padding: 11px;
    }

    &.superadmin-wrapper-box-large {
      padding: 23px;
    }

    &.superadmin-wrapper-box-full {
      padding: 23px;
      width: 100%;
    }
  }

  .superadmin-wrapper-title {
    position: absolute;
    color: $superadmin;
    font-weight: 600;
    top: -25px;
    left: 75px;
  }

  legend {
    border-color: $superadmin;
    color: $superadmin;
    padding-left: 75px;
    padding-bottom: 2px;
    margin-top: -30px;
  }

  .superadmin-wrapper-container {
    position: relative;
    display: flex;
  }

  .button-bottom-right {
    position: absolute;
    right: 7px;
    bottom: 0px;
  }

  .top-left {
    position: absolute;
    top: -40px;
    left: 7px;
  }

  .button-top-right {
    position: absolute;
    top: -41px;
    right: 7px;
    transition: scale .2s ease-in-out;
  }

  .legend-top-left {
    position: absolute;
    top: -14px;
    left: 7px;
  }

  .superadmin-badge {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 99;

    .superadmin-wrapper-title {
      top: 8px;
      right: 50px;
      left: auto;
      width: 0;
      overflow: hidden;
      transition: width 0.5s;
      -webkit-transition: width 0.5s;
      text-align: right;
      white-space: nowrap;
    }

    img {
      border-radius: 50%;
      border: 1px solid $superadmin;
      height: 38px;
      width: 40px;
      background-color: $dark-solitude;
    }

    &:hover, &:focus, &:active {
      .superadmin-wrapper-title {
        width: 150px;
      }
    }
  }

  img {
    &.button-top-right, &.legend-top-left, &.top-left, &.button-bottom-right {
      width: 50px;
      height: auto;
    }
  }
}
