.feature-list {
  list-style-type: none;

  li {
    position: relative;
    padding-left: 56px;
    color: $default-font-color;
    font-weight: $default-normal-weight;
  }

  h4 {
    line-height: 36px;
    margin-bottom: 0;
    color: $smugglers-cove;
  }
}

[class^='feature-list-icon-'], [class*=' feature-list-icon-'] {
  &::before {
    position: absolute;
    top: -1px;
    left: 0;
    font-family: 'callrail';
    color: $smugglers-cove;
    width: 36px;
    height: 36px;
    border: 1px solid $smugglers-cove;
    display: block;
    text-align: center;
    line-height: 36px;
    border-radius: 50%;
    font-size: 20px;
  }
}

@each $name, $char in $icons {
  .feature-list-icon-#{$name}::before {
    content: $char;
  }
}

/*
  White Label
*/

.white-label .feature-list {
  h4 {
    color: $default-font-color;
  }

  [class^='feature-list-icon-'], [class*=' feature-list-icon-'] {
    &::before {
      color: $default-font-color;
      border-color: $mine-shaft;
    }
  }
}
