.cr-select {
  box-sizing: border-box;
  cursor: pointer;

  *, *::before, *::after {
    box-sizing: inherit;
  }

  &.btn:not(.btn--tertiary) {
    padding: 0;
  }

  &.dropdown-as-block {
    display: block;

    .cr-select-toggle {
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

ui-select-option-list {
  overflow-y: auto;
}

// the toggle button that opens/closes the dropdown
.cr-select-toggle {
  padding: 0 12px 0 16px;
  font-weight: 400;
  user-select: none;
}

.cr-select-placeholder {
  display: inline-block;
  line-height: 34px;
  vertical-align: top;
}

.cr-select-arrow {
  margin: 0 !important;
  float: none !important;
  vertical-align: top;
  line-height: 34px;
  padding-left: 8px;
  display: inline-block;
  @include transform(rotateX(0deg));
}

// the panel that holds the header, footer and list
.cr-select-panel {
  border: 1px solid $nepal;
  border-radius: $default-border-radius;
  @include box-shadow($default-shadow);
  min-width: 160px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 5px;

  *, *::before, *::after {
    box-sizing: inherit;
  }

  &--block {
    border-radius: 0 0 $default-border-radius $default-border-radius;
  }
}

// the scrollable list that holds the selectable items
.cr-option-list,
.cr-select-container {
  max-height: 370px;
  width: 100%;
  flex: 1 1 auto;

  &:focus {
    outline: none;
  }

  &:only-child .cr-option:last-child,
  &:only-child li:last-child {
    border-radius: 0 0 $default-border-radius $default-border-radius;
  }

  &:only-child .cr-option:first-child,
  &:only-child .cr-option:first-child {
    border-radius: $default-border-radius $default-border-radius 0 0;
  }

  .cr-option--has-description {
    padding: 5px 16px;
    width: 268px;
    line-height: 24px;

    &:first-of-type {
      margin-top: 8px;
    }

    &:last-of-type {
      margin-bottom: 8px;
    }

    .cr-option-description {
      display: block;
      font-size: 12px;
      line-height: 16px;
      color: $gun-powder;

      &.descrption-larger-font {
        font-size: 14px;
      }
    }
  }

  .cr-option--selected {
    background: $light-steel-blue !important;
    color: $attribluetion;
  }

  .cr-option--active {
    background: $dark-solitude;
  }
}

.cr-select-header {
  flex: 1 0 auto;
}

.cr-select-footer {
  flex: 1 0 auto;
}

.cr-select-overlay {
  opacity: 0;
}

// Modifiers
.cr-select--open {
  .cr-select-arrow.icon-arrow-down {
    @include transform(rotateX(180deg));
  }

  &.cr-select--block {
    border-radius: $default-border-radius $default-border-radius 0 0;
  }
}

// Disabled
.cr-select--disabled {
  &.btn {
    cursor: not-allowed;
    opacity: .45;
  }
}

.btn-group.btn-group--disabled {
  .cr-select.btn.btn--primary {
    opacity: 1;
    background-color: $silver-chalice;
    border-color: $silver-chalice;
    border-left-color: $silver;
    pointer-events: none;
  }
}

.pill-dropdown {
  .cr-select-toggle {
    padding: 0;
  }

  .cr-select-placeholder {
    line-height: 24px;
  }

  &.cr-select--open{
    background-color: $smugglers-cove;
    border-color: $smugglers-cove;
    color: $white;
  }
}

.controls-inline {
  .pill-dropdown-label {
    font-size: 14px;
    margin-right: 8px;
  }
}

/*
  White Label
*/

.white-label {
  .cr-select-panel {
    border-color: $gun-powder;
  }
}
