@mixin auto-spotted-pill($pill-color) {
  background-color: transparent;
  border: 1px solid $pill-color;
  color: $pill-color;
}

.conversation {
  margin-bottom: $default-margin-bottom;
  width: 100%;
  overflow: auto;
  position: relative;

  &.player-full-conversation {
    .u-scroll-y {
      @include transition(height 0.2s ease-in);
    }

    .conversation-ui {
      border-radius: $default-border-radius;
    }
  }

  .conversation-speaker-wrapper {
    padding: 10px 0px 0 10px;
    position: absolute;
    z-index: 5;
    background: $white;
    border-top: solid 1px $light-steel-blue;
    border-left: solid 1px $light-steel-blue;
    border-radius: $default-border-radius;
    height: 30px;
  }

  .conversation-ui {
    overflow: auto;
    border: solid 1px $silver;
    background: $solitude;
    font-weight: $default-semibold-weight;
    border-radius: $default-border-radius;
    color: $solitude;
    position: relative;
    @include box-sizing(border-box);
    font-size: 14px;

    .conversation-item-wrapper {
      .conversation-item {
        padding: 12px;
        position: relative;
        cursor: pointer;

        p {
          line-height: 20px;
        }

        .active-speaker {
          display: none;
          position: absolute;
          right: 0px;
          top: calc(50% - 18px);
          width: 60px;
          text-align: center;
          font-size: 10px;
          line-height: 12px;
        }

        .pills-pill {
          float: none;
        }

        &.speaker-left {
          color: $steel-blue;

          .pills-pill {
            color: $white;
            background-color: $steel-blue;

            &.auto-spotted {
              @include auto-spotted-pill($steel-blue);
            }
          }

          &.active, &:hover {
            background-color: rgba($nepal--persistent, 0.15) !important;
          }

          &.active {
            padding: 12px 60px 12px 12px;

            .active-speaker {
              display: block;
            }
          }
        }

        &.speaker-right {
          color: $attribluetion;

          .pills-pill {
            color: $white;
            background-color: $attribluetion;

            &.auto-spotted {
              @include auto-spotted-pill($attribluetion);
            }
          }

          &.active, &:hover {
            background-color: rgba($attribluetion--persistent, 0.15) !important;
          }

          &.active {
            padding: 12px 60px 12px 12px;

            .active-speaker {
              display: block;
            }
          }
        }
      }
    }
  }

  &:hover .btn.btn--round {
    display: block;
  }

  .btn.btn--round {
    display: none;
    position: absolute;
    bottom: 12px;
    right: 12px;
    color: $silver;
    border-color: $white;
    background-color: $white;
    z-index: 99;
  }
}

.no-summary-ui-bg, .summary-container .no-summary-ui {
  background: $solitude cr-image-url('bg-new-pattern.svg');
}

.summary {
  margin-bottom: $default-margin-bottom;
  width: 100%;
  overflow: auto;
  position: relative;

  .summary-ui {
    overflow: auto;
    border: solid 1px $silver;
    background: $solitude;
    font-weight: $default-semibold-weight;
    border-radius: $default-border-radius;
    color: $attribluetion;
    position: relative;
    @include box-sizing(border-box);
    font-size: 14px;

    .summary-item-wrapper {
      .summary-item {
        padding: 12px;
        position: relative;
        color: $steel-blue;

        &:hover {
          background-color: rgba($nepal--persistent, 0.15) !important;
        }

        .summary-headline {
          font-size: 18px;
        }

        .summary-start-time {
          color: $silver-chalice;
        }
      }
    }
  }

  .no-summary-ui {
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px $silver;
    font-weight: $default-semibold-weight;
    border-radius: $default-border-radius;

    .no-summary-wrapper {
      text-align: center;
      color: $mine-shaft;

      .no-summary-headline {
        font-size: 24px;
        font-weight: 600;
      }

      .no-summary-explanation {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

/*
  White Label
*/

.white-label {
  .conversation-speaker-wrapper {
    border-top: solid 1px $mine-shaft;
    border-left: solid 1px $mine-shaft;

    .conversation-speaker-left-label, .conversation-speaker-right-label {
      &.active {
        color: $mine-shaft;
      }
    }
  }

  .conversation-ui {
    background: none;
  }

  .conversation .conversation-ui {
    border: solid 1px $mine-shaft;
    color: $solitude;
    background-color: $solitude;

    .pills-pill {
      font-weight: normal;
    }

    .conversation-item-wrapper .conversation-item {
      &.speaker-left {
        color: $mine-shaft;

        .pills-pill {
          background-color: $silver-chalice;

          &.auto-spotted {
            @include auto-spotted-pill($mine-shaft);
          }
        }

        &.active, &:hover {
          background-color: rgba($generic-999, 0.15) !important;
        }
      }

      &.speaker-right {
        color: $gun-powder;

        .pills-pill {
          background-color: $gun-powder;

          &.auto-spotted {
            @include auto-spotted-pill($gun-powder);
          }
        }

        &.active, &:hover {
          background-color: rgba($generic-777, 0.15) !important;
        }
      }
    }
  }
}
