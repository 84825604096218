/**
  This is copied and pasted from the @angular/cdk/overlay css
  Attempt to @import the file did not work, so this is just a drop in.
  Do not modify the css block directly below, add custom css at the bottom
  https://github.com/callrail/callrail/pull/11595/files#r232806237
**/
// sass-lint:disable-all
.cdk-global-overlay-wrapper,.cdk-overlay-container{pointer-events: none;top: 0;left: 0;height: 100%;width: 100%}.cdk-overlay-container{position: fixed;z-index: 1000}.cdk-overlay-container:empty{display: none}.cdk-global-overlay-wrapper{display: flex;position: absolute;z-index: 1000}.cdk-overlay-pane{position: absolute;pointer-events: auto;box-sizing: border-box;z-index: 1000;display: flex;max-width: 100%;max-height: 100%;margin-bottom: 5px}.cdk-overlay-backdrop{position: absolute;top: 0;bottom: 0;left: 0;right: 0;z-index: 1000;pointer-events: auto;-webkit-tap-highlight-color: transparent;transition: opacity .4s cubic-bezier(.25,.8,.25,1);opacity: 0;}.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{opacity: 1}@media screen and (-ms-high-contrast: active){.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{opacity: .6}}.cdk-overlay-dark-backdrop{background: rgba(0,0,0,.288)}.cdk-overlay-transparent-backdrop,.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing{opacity: 0}.cdk-overlay-connected-position-bounding-box{position: absolute;z-index: 1000;display: flex;flex-direction: column;min-width: 1px;min-height: 1px}.cdk-global-scrollblock{position: fixed;width: 100%;overflow-y: scroll}

/**
  Custom CallRail CSS for Overlay
**/
.cdk-overlay-container,
.cdk-overlay-pane,
.cdk-global-overlay-wrapper,
.cdk-overlay-backdrop,
.cdk-overlay-connected-position-bounding-box {
  // If think you might need to change this z-index, you don't.
  // if you really need to change this, then bring
  // it up in the #frontend channel so we can discuss it.
  z-index: 10002;
}

.cdk-overlay-pane .dropdown-menu {
  position: relative;
}
