$avatar-dimensions: 36px;
$avatar-letter-size: 20px;
$avatar-dimensions--square: 32px;

.avatar {
  display: inline-block;
  border-radius: 50%;
  position: relative;
  text-align: center;
  line-height: $avatar-dimensions;
  height: $avatar-dimensions;
  width: $avatar-dimensions;
  font-size: 0px;
  font-weight: $default-semibold-weight;
  background: $solitude;
  border: 1px solid $solitude;
  user-select: none;

  // make sure icons are centered
  [class*='icon-'] {
    @include transform(translate(-50%, -50%));
    position: absolute;
    left: 50%;
    top: 50%;
  }

  &:empty::before {
    content: "?";
    color: $silver;
    font-size: $avatar-letter-size;
  }

  &::first-letter {
    font-size: $avatar-letter-size;
    color: $silver;
    text-transform: capitalize;
  }

  &.avatar--typing {
    .avatar-typing-svg {
      @include transform(translate(-50%, -50%));
      position: absolute;
      height: 20px;
      top: 50%;
      left: 50%;
    }
  }

  .avatar-typing-svg {
    circle {
      fill: $mine-shaft;
    }
  }

  &.avatar--available {
    background: $solitude;
    border-color: $gun-powder;
    color: transparent;

    &:empty::before, &::first-letter, i {
      color: $mine-shaft;
    }
  }

  &.avatar--icon {
    background: transparent;
    color: $attribluetion;
    border-color: currentColor;

    &:focus {
      box-shadow: 0 0 0 3px $white, 0 0 6px 5px $attribluetion;
      outline: none;
      border-color: $smugglers-cove;
      background-color: $smugglers-cove;
      color: $white;

      i::before {
        color: $white;
      }
    }

    [class*='icon-'] {
      @include transform(translate(-50%, -50%));
      position: absolute;
      left: 50%;
      top: 50%;
    }

    &.avatar--interactive:not(.disabled) {
      cursor: pointer;

      &:hover {
        border-color: $smugglers-cove;
        background-color: $smugglers-cove;

        i::before {
          color: $white;
        }
      }
    }

    &.disabled {
      color: $silver;
    }
  }

  &.avatar--danger {
    background: transparent;
    border-color: $valencia;
    color: $valencia;

    &:hover {
      background: $valencia;
      border-color: $valencia;
      color: $white;
    }
  }

  &.avatar--active {
    background: $light-steel-blue;
    border-color: transparent;
    color: transparent;

    &:empty::before, &::first-letter, i {
      color: $attribluetion;
    }

    &.avatar--selected {
      background: $attribluetion;
      border-color: transparent;
      color: transparent;

      &:empty::before, &::first-letter, i {
        color: $white;
      }
    }

    &.avatar--typing {
      background: $light-steel-blue;

      .avatar-typing-svg {
        circle {
          fill: $attribluetion;
        }
      }
    }
  }

  &.notification {
    position: relative;
    top: auto;
    right: auto;

    &::after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 50%;
      top: 2px;
      left: 28px;
      width: 8px;
      height: 8px;
      background: $nail-salon;
      border: 1px solid $solitude;
    }

    &.with-count::after {
      content: attr(data-count);
      color: $white;
      font-size: 12px;
      width: 18px;
      height: 18px;
      line-height: 18px;
      top: -8px;
      left: 24px;
    }
  }

  &.avatar--interactive {
    cursor: pointer;
  }

  &.avatar--square {
    width: $avatar-dimensions--square;
    height: $avatar-dimensions--square;
    background: $mine-shaft;
    color: $white;
    border-radius: $default-border-radius;
    text-align: center;
    padding: 0;
    border: none;
    position: relative;
    font-family: $default-font-stack;
    font-weight: $default-semibold-weight;
    white-space: nowrap;

    @each $avatar-color in $color-set-avatars {
      &.avatar--color-#{index($color-set-avatars, $avatar-color)} {
        background-color: background($avatar-color);
        color: foreground($avatar-color);

        &::first-letter {
          color: currentColor;
        }

        &.avatar--interactive {
          span, span::before {
            background-color: background($avatar-color);
          }

          &:hover, &:active {
            &::first-letter {
              color: currentColor;
            }
          }
        }
      }
    }

    &::first-letter {
      color: currentColor;
      @include rhythm(16px, $avatar-dimensions--square);
    }

    span {
      position: absolute;
      top: 0;
      left: $avatar-dimensions--square;
      line-height: $avatar-dimensions--square;
      padding: 0px 16px 0 12px;
      background: $mine-shaft;
      border-top-right-radius: $default-border-radius;
      border-bottom-right-radius: $default-border-radius;
      border-left: 1px solid rgba($black--persistent, 0.15);
      opacity: 0;
      pointer-events: none;
      transform: translate3d(-2px, 0, 0);
      transition: opacity $default-transition-ease, transform $default-transition-ease, background $default-transition-ease;

      &::before {
        display: block;
        content: " ";
        width: $default-border-radius;
        height: $avatar-dimensions--square;
        background: $mine-shaft;
        position: absolute;
        left: (-1 - $default-border-radius);
      }
    }

    &.avatar--interactive {
      &:hover, &:active {
        outline: none;
        text-decoration: none;

        &::first-letter {
          color: currentColor;
        }

        span {
          display: block;
          opacity: 1;
          transform: translate3d(0, 0, 0);
          pointer-events: all;
        }
      }
    }

    &.avatar--placeholder {
      background: $solitude;
      border: 1px solid $silver;
      color: $silver-chalice;
    }
    @include rhythm(16px, $avatar-dimensions--square);
  }
}

.white-label .avatar {
  &.avatar--available {
    border-color: $mine-shaft;
    background: $solitude;
  }

  &.avatar--active {
    background: $mine-shaft;
    border-color: transparent;

    i::before,
    &:empty::before {
      color: $white;
    }

    &::first-letter {
      color: $white;
    }

    &.avatar--typing {
      background: $dark-solitude;

      .avatar-typing-svg {
        circle {
          fill: $mine-shaft;
        }
      }
    }
  }

  &.notification {
    &::after {
      background: $valencia;
    }
  }

  &.avatar--icon {
    color: $mine-shaft;

    &.disabled {
      color: $silver-chalice;
    }

    &:focus {
      border-color: $mine-shaft;
      background-color: $mine-shaft;
    }

    &.avatar--interactive:not(.disabled) {
      &:hover {
        border-color: $mine-shaft;
        background-color: $mine-shaft;
      }
    }
  }
}
