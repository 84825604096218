$icons: (
  unique-users: "\e600",
  wordpress: "\e601",
  webhooks: "\e602",
  voicemail: "\e603",
  users: "\e605",
  user: "\e606",
  small-arrow-down: "\e608",
  sign-out: "\e609",
  salesforce: "\e60b",
  plus-sign: "\e60c",
  play: "\e60d",
  phone-menu: "\e60e",
  note: "\e60f",
  kissmetrics: "\e610",
  google: "\e611",
  export: "\e612",
  edit: "\e613",
  delete: "\e614",
  company: "\e615",
  cancel: "\e616",
  call-flow: "\e617",
  billing: "\e618",
  arrow-right: "\e619",
  arrow-right-02: "\e61a",
  arrow-down: "\e61b",
  api: "\e61c",
  add-note: "\e61d",
  dynamic-number: "\e61e",
  small-arrow-up: "\e61f",
  arrow-left: "\e623",
  arrow-both-ways: "\e624",
  spam: "\e625",
  alert-close: "\e62a",
  alert-error: "\e62b",
  alert-info: "\e62c",
  alert-success: "\e62d",
  alert-begincall: "\e649",
  alert-update: "\e674",
  call: "\e62e",
  referrer: "\e62f",
  tracking: "\e630",
  callforward: "\e631",
  calltag: "\e632",
  close-big: "\e633",
  greeting: "\e634",
  hang-up: "\e635",
  plus-sign-big: "\e636",
  ringphones: "\e637",
  schedule: "\e638",
  plus-sign-circle: "\e639",
  undefined: "\e63a",
  pause: "\e63b",
  megaphone: "\e63c",
  google-adwords: "\e63d",
  inbound-call: "\e63e",
  not-answered-call: "\e63f",
  repeat: "\e640",
  transcript: "\e642",
  help: "\e643",
  conversations: "\e644",
  archive: "\e645",
  outbound-call: "\e646",
  recording: "\e647",
  sip: "\e648",
  begincall: "\e649",
  arrow-expanded: "\e64a",
  arrow-collapsed: "\e64b",
  round-robin: "\e64c",
  send-message: "\e64d",
  documentation-api: "\e64e",
  documentation: "\e64f",
  search: "\e651",
  demo: "\e656",
  lead-capture: "\e657",
  websession: "\e658",
  occupation: "\e659",
  location: "\e65a",
  id: "\e65e",
  numbers: "\e65f",
  calendar: "\e661",
  news: "\e662",
  resources: "\e663",
  globe: "\e664",
  configure: "\e66b",
  branding: "\e66e",
  picture: "\e66f",
  popout: "\e670",
  activity: "\e671",
  bugfix: "\e673",
  update: "\e674",
  new-feature: "\e675",
  sip2: "\e677",
  duplicate: "\e678",
  print: "\e679",
  pause-notifications: "\e67a",
  play-listened: "\e67b",
  desktop: "\e681",
  mobile: "\e682",
  keyword-pool: "\e683",
  superhero: "\e684",
  pdf: "\e685",
  excel: "\e686",
  csv: "\e687",
  credit-card: "\e800",
  applications: "\e801",
  lock: "\e900",
  fullstory: "\e901",
  pipeline-deals: "\e902",
  acquisio: "\e903",
  bing-ads: "\e904",
  custom-cookie-capture: "\e905",
  hipchat: "\e906",
  hubspot: "\e907",
  marketo: "\e908",
  mixpanel: "\e909",
  optimizely: "\e910",
  slack: "\e911",
  vwo: "\e912",
  external-query: "\e913",
  toggle-on: "\e914",
  toggle-off: "\e915",
  subitem-arrow: "\e916",
  menu: "\e9bd",
  sort-amount-asc: "\ea4c",
  sort-amount-desc: "\ea4d",
  connect: "\ea4e",
  disconnect: "\ea4f",
  saved-filters: "\e90a",
  good-lead: "\e90b",
  not-good-lead: "\e90c",
  record: "\e90d",
  filter: "\e90f",
  transcriptions: "\e91b",
  volume-3: "\e91c",
  volume-2: "\e91d",
  volume-1: "\e91e",
  backspace: "\e922",
  live-console: "\e925",
  more-options: "\e926",
  hipaa-compliant: "\e921",
  nav-numbers: "\e927",
  referral: "\e924",
  audit: "\e928",
  monitor: "\e92b",
  active-speaker: "\e92c",
  arrow-vertical-expand: "\e92d",
  arrow-vertical-collapse: "\e92e",
  keyword-spotting: "\e92f",
  responsive-routing: "\e930",
  sms-inbox: "\e931",
  sms-all: "\e932",
  arrow-back: "\e933",
  arrow-forward: "\e919",
  move-to-inbox: "\e934",
  wat: "\e935",
  download: "\e626",
  check: "\e90e",
  search-tiny: "\e920",
  submit-ticket: "\e917",
  search-help: "\e918",
  good-lead-hollow: "\e92a",
  volume-none: "\e629",
  rocket: "\e923",
  attachment: "\e91f",
  phone-number: "\e929",
  view: "\e937",
  filter-active: "\e938",
  whisper: "\e939",
  sliders: "\e93a",
  chart-bar: "\e93b",
  chart-line: "\e93c",
  stop: "\e93e",
  flag: "\e940",
  accounts: "\e93f",
  clipboard: "\e941",
  save-star: "\e942",
  star-filled: "\e943",
  chart-scatter-plot: "\e944",
  wat-tiny: "\e945",
  arrow-left-02: "\e946",
  code: "\e947",
  form: "\e948",
  upgrade: "\e949",
  upgrade-filled: "\e94a",
  price-tag: "\e94b",
  repeat-tiny: "\e94c",
  info-tiny: "\e94d",
  notification-bell: "\e94e",
  hidden: "\e94f",
  bullet: "\e950",
  mute-mic: "\e951",
  chart-pie: "\e952",
  lead-customer: "\e953",
  live-chat: "\e954",
  chart-bubble: "\e955",
  direct-voicemail: "\e956",
  direct-voicemail-transcript: "\e957",
  quick-texts: "\e958",
  upload: "\e959",
  picture-broken: "\e95a",
  scrub-forward-15: "\e95d",
  scrub-backward-15: "\e95e",
  player-next: "\e95c",
  player-previous: "\e95b",
  resources-filled: "\e93d",
  missed-chat: "\e95f",
  lead-center: "\e960",
  lead-center-small: "\e961",
  call-tracking: "\e962",
  call-tracking-small: "\e963",
  conversation-intelligence: "\e964",
  conversation-intelligence-small: "\e965",
  premium-conversation-intelligence: "\eafb",
  premium-conversation-intelligence-small: "\eafc",
  convert-assist: "\ea5c",
  convert-assist-small: "\ea5d",
  form-tracking: "\e966",
  form-tracking-small: "\e967",
  check-filled: "\e968",
  crown: "\e969",
  configure-audio: "\e96c",
  sound-bars: "\e96d",
  sound-bars-filled: "\ea50",
  more-options-vertical: "\e96e",
  arrow-up: "\e96b",
  arrow-big-up: "\ea53",
  sentiment-negative: "\ea54",
  sentiment-neutral: "\ea55",
  sentiment-positive: "\ea56",
  labs: "\ea57",
  outbound-text: "\ea5a",
  inbound-text: "\ea5b",
  call-back: "\ea51",
  check-big: "\e936",
  end-monitor: "\ea52",
);
