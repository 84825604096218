$giant-input-shadow: rgba(59, 153, 252, 0.8);
$giant-input-shadow-amazonite: rgba(10, 183, 181, 0.8);
$giant-input-shadow-vin-rouge: rgba(162, 84, 126, 0.8);
$giant-input-shadow-smugglers-cove: rgba(23, 85, 160, 0.8);

.fake-label {
  font-size: $default-font-size;
  display: block;
  padding: 0;
  border-bottom: none;
  margin-bottom: calc(#{$default-margin-bottom} * 0.25);
  font-weight: $default-semibold-weight;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
  margin-bottom: $default-margin-bottom;

  p {
    margin-bottom: calc(#{$default-margin-bottom} * 0.25);
  }
}

legend {
  margin-bottom: $default-margin-bottom * 1.5;
  font-size: 18px;
  border-bottom: 1px solid $dark-solitude;
  color: $default-font-color;
  padding: 0 0 11px 0;
  width: 100%;
  position: relative;

  &.small {
    font-size: 14px;
    padding: 0;
    margin-bottom: 11px;
    font-weight: $default-normal-weight;
  }

  &.advanced-settings {
    margin-bottom: calc(#{$default-margin-bottom} * 0.5);
    border-bottom: none;
    cursor: default;
    color: $smugglers-cove;
  }
}

.numbered {
  color: $silver-chalice;
  font-size: 18px;
  display: inline-block;
  width: 24px;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    height: 1px;
    width: 24px;
    border-bottom: 1px solid $white;
  }
}

.numbered-content {
  margin-left: 24px;
}

.controls {
  margin-bottom: $default-margin-bottom;

  select, textarea, input[type="text"], input[type="password"], input[type="datetime"],
  input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"], input[type="url"],
  input[type="search"], input[type="tel"], input[type="color"], .mock-input, .input-with-prepend,
  .mock-textarea {
    & + .tooltip {
      font-size: 14px;
      margin-left: 8px;
      vertical-align: super;
    }
  }

  // Give dropdowns in controls the same bottom margin as inputs
  .dropdown {
    margin-bottom: 12px;
  }

  .btn, .btn.btn--tertiary, .dropdown {
    vertical-align: top;

    &.add-tag-menu {
      line-height: 20px;
    }
  }

  .multi-select-dropdown .dropdown-menu {
    margin-top: 0;
  }
}

label.control-label {
  @include rhythm(16px, $default-line-height);
  display: block;
  margin-bottom: calc(#{$default-margin-bottom} * 0.25);
  font-weight: $default-semibold-weight;

  &.label--muted {
    font-weight: $default-normal-weight;
    color: $silver-chalice;
  }
}

.control-label-default-cursor {
  cursor: inherit;
}

select, textarea, input[type="text"], input[type="password"], input[type="datetime"],
input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"],
input[type="week"], input[type="number"], input[type="email"], input[type="url"],
input[type="search"], input[type="tel"], input[type="color"], .input-prepend, .mock-input, .mock-textarea {
  display: inline-block;
  height: 36px;
  width: 100%;
  max-width: $input-default-max-width;
  margin: 0 0 12px 0; // remove top and left default margin from Safari
  vertical-align: middle;
  padding: 0px 8px;
  background: $white;
  border: solid 1px $silver;
  border-radius: $default-border-radius-small;
  font-family: $default-font-stack;
  font-size: $default-font-size;
  @include box-shadow(none);
  @include transition(border linear .2s, box-shadow linear .2s);
  @include box-sizing(border-box);

  // New selector; not stabilized
  &::placeholder,
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: $silver-chalice;
  }

  &:focus, &.mock-input-focus, &.mock-textarea-focus {
    border-color: $attribluetion;
    outline: 0;
    @include box-shadow(inset 0 1px 1px rgba($black--persistent, .075), 0 0 8px rgba(82, 168, 236, .3));
  }

  &.ng-invalid, &.input-invalid {
    border-color: $valencia;
    @include box-shadow(0 0 4px 0 rgba(210, 71, 69, 0.5));
    background: cr-image-url('alert-error@2x.png') no-repeat 97% 6px;
    background-color: $white;
    background-size: 20px;
    padding-right: 36px;

    &.ng-pristine {
      background: $white none;
      border-color: $silver;
      @include box-shadow(none);
    }
  }

  &.input-success {
    background: cr-image-url('checkmark.svg') no-repeat 97% 6px;
    background-color: $white;
    background-size: 20px;
    padding-right: 36px;
  }

  &.password-eye {
    padding-right: 36px;
    font-family: monospace;

    &.not-empty {
      background-position-x: calc(100% - 36px);
    }

    &.ng-invalid {
      padding-right: 60px;
    }
  }

  &.input-xs {
    width: 42px;

    &.ng-invalid {
      background: $white none; // `none` gets rid of the validation icon for xs inputs
      padding: 0px 8px;
    }
  }

  &.input-sm {
    width: 82px;
  }

  &.input-md {
    width: 122px;
  }

  &.input-lg {
    width: 202px;
  }

  &.input-xlarge {
    width: 475px;
    max-width: 475px;
  }

  &.input-full-width {
    max-width: none;
  }
}

select {
  @include appearance(none);
  height: 36px;
  font-size: $default-font-size;
  border: solid 1px $silver;
  border-radius: $default-border-radius-small;
  background-color: $white;
  color: $mine-shaft;
  padding: 5px 32px 5px 11px;
  background-image: cr-image-url('arrow-down.svg');
  background-repeat: no-repeat;
  background-position: right .7em top 50%;

  &::-ms-expand { // Hide dropdown arrow in IE11
    display: none;
  }

  &:-moz-focusring { // Hide dotted focus outline in Firefox
    color: transparent;
    text-shadow: 0px 0px 0px $black;
  }
}

textarea, .mock-textarea {
  height: auto;
  min-height: $default-line-height * 2 + 12;
  line-height: $default-line-height;
  padding: 6px 9px;
}

input[type="file"] {
  height: 30px;
  line-height: 16px;
  font-size: $default-font-size;
}

input[disabled], select[disabled], textarea[disabled],
.mock-input.disabled, .mock-textarea.disabled {
  cursor: not-allowed;
  pointer-events: all;
}

input[disabled], select[disabled], textarea[disabled], .inactive,
.mock-input.disabled, .mock-textarea.disabled {
  opacity: .45;
}

.inactive {
  pointer-events: none;

  input[disabled], select[disabled], textarea[disabled] {
    opacity: 1;
  }
}

.mock-input.mock-input--enable, .mock-textarea.mock-textarea--enable {
  opacity: .45;
  cursor: text;
  pointer-events: auto;
  text-align: left;
}

.input-success-text {
  color: $medium-green;
  display: block;
  margin-bottom: 12px;
}

.input-error-text, .input-error-link {
  color: $valencia;
  display: block;
  margin-bottom: 12px;
}

.input-error-link {
  text-decoration: underline;
}

.input-success--with-icon {
  @include icon(before, 'alert-success');
}

.input-error--with-icon {
  @include icon(before, 'alert-error');
}

.input-error--with-icon, .input-success--with-icon {
  padding-left: 10px + $icon-font-size;

  &::before {
    margin-left: -10px - $icon-font-size;
    padding-right: 10px;
    font-size: $icon-font-size;
    vertical-align: middle;
  }
}

.input-search {
  display: inline-block;
  position: relative;
  @include icon(before, search);

  &::before {
    position: absolute;
    left: 12px;
    top: calc(50% - 8px);
    color: $silver;
  }

  &.input-mini::before {
    left: 8px;
  }

  input {
    // override default margin-bottom for inputs
    margin: 0 !important;
    padding-left: 36px;

    &.input-mini {
      border-color: $smugglers-cove;
      border-radius: unset;
      border-width: 0 0 1px;
      color: $smugglers-cove;
      height: 24px;
      margin: -5px 8px 0 !important;
      padding: 0 0 0 22px;
      width: 100%;

      &:placeholder-shown {
        border-color: $silver;
        color: $silver-chalice;
      }

      &:-ms-input-placeholder {
        border-color: $silver;
        color: $silver-chalice;
      }

      &:hover {
        border-color: $gun-powder;
      }

      &:focus {
        border-color: $smugglers-cove;
        color: $mine-shaft;
        @include box-shadow(none);
      }
    }
  }

  &.input-full-width {
    width: 100%;
  }

  .input-mini-width {
    display: inline-block;
    left: -1000px;
    position: absolute;
    visibility: hidden;
    white-space: pre;
  }
}

.input-icon-prefix {
  position: relative;

  i {
    position: absolute;
    left: 8px;
    top: 10px;
    color: $silver-chalice !important;
    font-size: $default-font-size;
    width: 20px;
    text-align: center;
  }

  input[type="text"] {
    padding-left: 30px;
  }
}

.input-icon-suffix {
  position: relative;
  display: inline;

  i {
    position: absolute;
    right: 8px;
    top: -3px;
    color: $silver-chalice !important;
    font-size: $default-font-size;
    width: 20px;
    text-align: center;
  }

  input[type="text"] {
    padding-right: 30px;
  }
}

.input-with-prepend {
  @include inline-flex;
  max-width: $input-default-max-width;
  width: 100%;

  .input-prepend {
    border-radius: $default-border-radius-small 0 0 $default-border-radius-small;
    @include flex;
    background: $dark-solitude;
    border-right: 0;
    color: $gun-powder;
    line-height: 32px;
    padding: 0px 12px 0 10px;
    width: auto;
  }

  input {
    border-radius: 0 $default-border-radius-small $default-border-radius-small 0;
    min-width: 0;
    @include flex;
  }
}

.input-number-as-text {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}

i.remove-input-field {
  vertical-align: baseline;
  margin-left: 5px;
  color: $silver;
  cursor: pointer;
  line-height: 28px;

  &:hover {
    color: $attribluetion;
  }
}

.label-box-list {
  max-width: $input-default-max-width;

  .label-box-item {
    input[type="radio"], input[type="checkbox"] {
      float: left;
      margin: 13px;
    }

    label {
      border: solid 1px $dark-solitude;
      border-radius: $default-border-radius-small;
      background-color: $white;
      padding: 5px 11px;
      margin-bottom: 8px;
      display: block;
    }

    input:checked + label {
      border-color: $attribluetion;
    }
  }
}

.radio, .checkbox {
  font-weight: $default-normal-weight;
  display: block;

  + input, + textarea, + .input-with-prepend {
    margin-left: 24px;
    margin-top: 6px;
  }
}

.custom-radio-container {
  height: 28px;
  line-height: 28px;
}

.custom-radio {
  vertical-align: middle;
  display: inline-block;
  position: relative;
  width: 26px;
  height: 26px;
  margin-right: 10px;
  background-color: $dark-solitude;
  border: 1px solid $silver-chalice;
  border-radius: 50%;

  &.custom-radio-on {
    background-color: $attribluetion;
    border-color: $attribluetion;
  }

  &.custom-radio-on::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-left: 8px;
    margin-top: 8px;
    background-color: $white;
  }
}

.checkbox-list {
  li {
    border-radius: $default-border-radius-small;
    list-style: none;
    border: 1px solid $dark-solitude;
    font-size: 13px;
    padding: 9px 11px;
    margin-bottom: 6px;
    line-height: 12px;

    input {
      margin-right: 0;
      float: right;
    }
  }
}

input[type="radio"], input[type="checkbox"] {
  vertical-align: middle;
  position: relative;
  padding: 0;
  height: 13px;
  width: 13px;
  margin: 0 8px 0 0;
  top: -1px;
}

// This is done to prevent a webkit bug
// https://bugs.webkit.org/show_bug.cgi?id=155577
input[type="radio"] {
  margin-left: 1px;
}

input.input--rounded {
  border-radius: 100px;
}

.controls-inline {
  .dropdown, .control-label, .multi-select-dropdown {
    display: inline-block;
    max-height: 24px;
  }

  .dropdown {
    vertical-align: baseline;
    margin: 0 8px;
    line-height: 24px;

    .dropdown-toggle {
      text-decoration: none;
    }
  }

  .toggle {
    float: right;
  }

  .control-label {
    float: left;
  }

  select, textarea, input[type="text"], input[type="password"], input[type="datetime"],
  input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"], input[type="url"],
  input[type="search"], input[type="tel"], input[type="color"], .mock-input, .mock-textarea {
    margin: 0 5px;

    & + .tooltip {
      vertical-align: inherit;
    }
  }

  .btn, .btn.btn--tertiary {
    margin-left: 5px;
  }
}

.controls-mini {
  select, textarea, input[type="text"], input[type="password"], input[type="datetime"],
  input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"], input[type="url"],
  input[type="search"], input[type="tel"], input[type="color"], .mock-input, .mock-textarea {
    height: 30px;
    line-height: 30px;
  }

  .btn, .btn.btn--tertiary, .dropdown {
    line-height: 28px;

    &.dropdown-toggle i {
      line-height: 28px;
    }
  }
}

.eye {
  background: transparent;
  border: none !important;
  width: 0;
  padding: 0;
  outline: none;
}

.eye::after {
  position: relative;
  left: -36px;
  top: 0px;
  font-size: 22px;
  padding: 6px;
}

.eye:focus::after {
  border-radius: 3px;
  outline: 0;
  border: 1px solid $attribluetion;
  @include box-shadow(0 0 5px $attribluetion);
}

.eye-hidden::after {
  font-family: 'callrail';
  content: map-get($icons, 'hidden');
}

.eye-view::after {
  font-family: 'callrail';
  content: map-get($icons, 'view');
}

label.control-label-inline, label.control-label-inline-sm, label.control-label-inline-md {
  float: left;
  line-height: 36px;
  margin-right: 10px;
  margin-bottom: 0;

  & + .controls {
    margin-bottom: 18px;
    line-height: 36px;

    select, textarea, input[type="text"], input[type="password"], input[type="datetime"],
    input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"], input[type="url"],
    input[type="search"], input[type="tel"], input[type="color"], .dropdown, .input-prepend, .mock-input,
    .mock-textarea {
      margin-bottom: 6px;
    }

    .dropdown-toggle.inline-dropdown, .dropdown-toggle.btn--tertiary, .checkbox, .radio {
      margin: 0;
    }

    .toggle {
      top: 6px;
    }

    .dropdown + .remove-input-field {
      top: 8px;
    }
  }

  &.control-label-normal-line-height {
    line-height: 24px;
  }
}

label.control-label-inline {
  width: 190px;

  & + .controls {
    margin-left: 200px;
  }
}

label.control-label-inline-sm {
  width: 90px;

  & + .controls {
    margin-left: 100px;
  }
}

label.control-label-inline-md {
  width: 140px;

  & + .controls {
    margin-left: 150px;
  }
}

.toggle-custom-labeled {
  box-sizing: border-box;
  position: relative;
  width: 131px;
  height: 32px;
  background-color: $white;
  border: solid 1px $silver-chalice;
  font-size: 14px;
  font-weight: 700;
  border-radius: 100px;
  cursor: pointer;
  @include transition(0.4s);
  line-height: 34px;
  display: inline-block;
  margin: 0 5px -10px 0;

  &:focus-within {
    @include transition(0s);
    @include box-shadow(0 0 3px 1px $giant-input-shadow);
  }

  .toggle-slider {
    background: $gun-powder;
    width: 22px;
    height: 22px;
    left: -1px;
    top: -1px;
    @include transition(transform $default-transition-ease);
    border: 1px solid $silver-chalice;
    position: absolute;
    border-radius: 100px;
    @include box-shadow(0 1px 4px 0 rgba($black--persistent, 0.3));

    &::after {
      font-size: 22px;
      top: 1px;
      @include transform(scale(0));
      @include transition(transform 0.2s);
    }

    &.toggle-slider-btn {
      outline: none;
      // The button version isn't quite the same size.
      // Needs to be 2px larger.
      width: 66px;
      height: 32px;

      &::-moz-focus-inner { // Hide Firefox's dotted focus box
        border: 0;
      }
    }
  }

  .toggle-left-label {
    position: absolute;
    top: -2px;
    left: 15px;
    @include transition(transform $default-transition-ease);
  }

  .toggle-right-label {
    position: absolute;
    top: -2px;
    right: 15px;
    @include transition(transform $default-transition-ease);
    @include transition(color $default-transition-ease);
  }

  &.toggle-right {
    .toggle-slider {
      left: -4px;
      @include transform(translateX(65px));
      @include transition(transform $default-transition-ease);

      &::after {
        @include transform(scale(1));
        @include transition(transform $default-transition-ease);
      }
    }

    .toggle-slider-btn {
      left: -1px;
      @include transform(translateX(65px));
    }

    &.skip-animation {
      .toggle-slider {
        @include transition(0s);

        &::after {
          @include transition(0s);
        }
      }
    }

    .toggle-right-label {
      color: $white;
    }
  }

  &:not(.toggle-right) {
    .toggle-left-label {
      @include transition(color $default-transition-ease);
      color: $white;
    }
  }

  &.toggle-inactive {
    background: $white;
    border-color: $silver-chalice;
    pointer-events: none;

    .toggle-slider {
      border-color: $silver-chalice;

      &::after {
        color: $silver-chalice;
      }
    }
  }
}

.toggle {
  box-sizing: border-box;
  position: relative;
  width: 46px;
  height: 24px;
  background-color: $silver-chalice;
  border: solid 1px $silver-chalice;
  font-size: 14px;
  border-radius: 100px;
  cursor: pointer;
  @include transition(.4s);
  line-height: 24px;

  &:focus-within {
    @include transition(0s);
    @include box-shadow(0 0 3px 1px $giant-input-shadow);
  }

  .toggle-slider {
    background: $white;
    width: 22px;
    height: 22px;
    left: -1px;
    top: -1px;
    @include transition(transform 0.4s ease-in-out 0.1s);
    border: 1px solid $silver-chalice;
    position: absolute;
    border-radius: 100px;
    @include box-shadow(0 1px 4px 0 rgba($black--persistent, 0.3));
    @include icon(after, check);

    &::after {
      font-size: 22px;
      top: 1px;
      @include transform(scale(0));
      @include transition(transform 0.2s);
    }

    &.toggle-slider-btn {
      outline: none;
      // The button version isn't quite the same size.
      // Needs to be 2px larger.
      width: 24px;
      height: 24px;

      // Need to move the checkmark over a bit on the button
      &::after {
        left: -6px;
        top: -2px;
      }

      &::-moz-focus-inner { // Hide Firefox's dotted focus box
        border: 0;
      }
    }
  }

  .toggle-label {
    position: absolute;
    top: -1px;
    right: 8px;
    color: $white;
    @include transition(transform 0.4s ease-in-out 0.1s);
  }

  &.toggle-on {
    background-color: $medium-green;
    border-color: $medium-green;

    .toggle-slider {
      border-color: $medium-green;
      left: -4px;
      @include transform(translateX(25px));
      @include transition(transform 0.4s ease-in-out 0s);

      &::after {
        color: $medium-green;
        @include transform(scale(1));
        @include transition(transform 0.1s ease-out 0.3s);
      }
    }

    .toggle-slider-btn {
      left: -1px;
      @include transform(translateX(22px));
    }

    &.skip-animation {
      .toggle-slider {
        @include transition(0s);

        &::after {
          @include transition(0s);
        }
      }
    }

    .toggle-label {
      @include transform(translateX(-22px));
    }
  }

  &.toggle-inactive {
    background: $white;
    border-color: $silver-chalice;
    pointer-events: none;

    .toggle-slider {
      border-color: $silver-chalice;

      &::after {
        color: $silver-chalice;
      }
    }

    .toggle-label {
      color: $silver-chalice;
    }
  }

  &.toggle--has-label {
    width: 62px;

    &.toggle-on {
      .toggle-slider {
        @include transform(translateX(38px));
      }
    }
  }

  &.skip-animation {
    @include transition(0s);
  }
}

.additional-options {
  padding-left: 22px;
  margin-top: -12px;
  margin-left: 5px;
  border-left: $default-border;

  &.additional-options--short {
    line-height: 5px;
    margin-top: 32px;
    margin-bottom: 48px;
  }
}

.pill-checkbox {
  margin: 2px 8px 2px 0;
  white-space: nowrap;
  // allow us to set the height rather than the contents setting the height
  display: inline-flex;

  .pill-checkbox--content {
    @include user-select-none;
    color: $smugglers-cove;
    height: 24px;
    line-height: 22px;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid $light-steel-blue;
    background-color: $solitude;
    padding: 0 12px;
    cursor: pointer;

    &:hover {
      background-color: $light-steel-blue;
      color: $smugglers-cove;
    }
  }

  input[type=checkbox]:checked + .pill-checkbox--content {
    background-color: $smugglers-cove;
    border-color: $smugglers-cove;
    color: $white;

    &:hover {
      background-color: $smugglers-cove;
      border-color: $smugglers-cove;
    }
  }

  input[type="checkbox"] {
    // visibility: hidden prevents browser focus, so we do this hack instead
    opacity: 0;
    pointer-events: none;
    width: 0px;
    padding: 0px;
    margin: 0px;
  }

  &:focus-within {
    @include default-outline;
    outline-offset: $default-outline-offset;
  }
}

// Inlined and (partially) URL escaped from app/assets/images/checkbox-empty.svg
$checkbox-empty: 'data:image/svg+xml;utf8,\
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\
    <rect x="0.5" y="0.5" width="23" height="23" rx="2" fill="#{url-friendly-color($dark-solitude--persistent)}" stroke="#{url-friendly-color($silver--persistent)}"/>\
  </svg>';

// Inlined and (partially) URL escaped from app/assets/images/checkbox-filled.svg
$checkbox-filled: 'data:image/svg+xml;utf8,\
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\
    <rect width="24" height="24" rx="2" fill="#{url-friendly-color($attribluetion--persistent)}"/>\
    <path d="M6.53033 11.4697C6.23744 11.1768 5.76256 11.1768 5.46967 11.4697C5.17678 11.7626 5.17678 12.2374 5.46967\
      12.5303L6.53033 11.4697ZM10 16L9.46967 16.5303C9.76256 16.8232 10.2374 16.8232 10.5303 16.5303L10 16ZM18.5303\
      8.53033C18.8232 8.23744 18.8232 7.76256 18.5303 7.46967C18.2374 7.17678 17.7626 7.17678 17.4697 7.46967L18.5303\
      8.53033ZM5.46967 12.5303L9.46967 16.5303L10.5303 15.4697L6.53033 11.4697L5.46967 12.5303ZM10.5303 16.5303L18.5303\
      8.53033L17.4697 7.46967L9.46967 15.4697L10.5303 16.5303Z" fill="white"/>\
  </svg>';
// sass-lint:enable one-declaration-per-line

// Oversized form elements
.giant-input {
  // Keep the new checkbox/radio from jumping down the page
  // when space is tight.
  white-space: nowrap;

  // Hide default radio button. Opacity used for IE compatibility.
  input[type="checkbox"], input[type="radio"] {
    opacity: 0;
  }

  label {
    white-space: normal;
    position: relative;
    display: inline-block;
    cursor: pointer;
    // Account for the hidden radio button taking up space to the left
    margin-left: -20px;
  }

  // Empty circle
  label::before {
    content: "";
    display: inline-block;
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    vertical-align: middle;
    background-size: 24px;
    background-repeat: no-repeat;
  }

  // meant to be used when you want the checkbox to be the rightmost object
  // usually means you don't have a label (with text) to the right of the checkbox
  &.align-right {
    label::before {
      margin-right: 0px;
    }
  }

  @include radio-empty-background($silver--persistent, $dark-solitude--persistent);
  @include radio-filled-background($attribluetion--persistent);
  // Add focus to make this selectable via keyboard
  @include radio-focus-shadow($giant-input-shadow);

  input[type="checkbox"]:not(:checked) + label::before {
    background-image: url($checkbox-empty);
  }

  input[type="checkbox"]:checked + label::before {
    background-image: url($checkbox-filled);
  }

  input[type="checkbox"]:focus + label::before {
    outline: none;
    border-radius: $default-border-radius-small;
    @include box-shadow(0 0 3px 1px $giant-input-shadow);
  }

  &.giant-color-input {
    position: absolute;
    top: 12px;
    right: 20px;
  }

  &.giant-amazonite-input {
    @include radio-empty-background($amazonite, $white--persistent);
    @include radio-filled-background($amazonite);
    @include radio-focus-shadow($giant-input-shadow-amazonite);
  }

  &.giant-vin-rouge-input {
    @include radio-empty-background($vin-rouge, $white--persistent);
    @include radio-filled-background($vin-rouge);
    @include radio-focus-shadow($giant-input-shadow-vin-rouge);
  }

  &.giant-smugglers-cove-input {
    @include radio-empty-background($smugglers-cove--persistent, $white--persistent);
    @include radio-filled-background($smugglers-cove--persistent);
    @include radio-focus-shadow($giant-input-shadow-smugglers-cove);
  }

  &.giant-inactive-input {
    input[type="checkbox"]:checked + label::before {
      cursor: default;
      background-image: cr-image-url('checkbox-filled-inactive.svg');
    }

    input[type="checkbox"]:not(:checked) + label::before {
      cursor: default;
      background-image: cr-image-url('checkbox-empty-inactive.svg');
    }
  }

  &.giant-disabled-input {
    label {
      cursor: not-allowed;
    }

    // Replace the checkmark/radio dot with a lock icon
    input[type="radio"]:not(:checked) + label::before,
    input[type="checkbox"]:not(:checked) + label::before,
    input[type="checkbox"]:checked + label::before,
    input[type="radio"]:checked + label::before {
      background: none;
      top: 0;
      color: $gun-powder;
      font-family: 'callrail';
      font-size: 18px;
      content: "#{map-get($icons, 'lock')}";
    }
  }
}

.checkbox-grid {
  max-width: 700px;
  display: flex;
  flex-flow: row wrap;
  margin-left: -8px;
  margin-top: 16px;

  .giant-input {
    flex: 0 1 225px;

    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 204px;
      padding: 8px;
    }

    &.giant-inactive-input {
      label {
        cursor: default;
      }
    }
  }

  &.checkbox-grid--full-width {
    max-width: none;

    .giant-input {
      flex: 0 1 340px;

      label {
        max-width: 315px;
      }
    }
  }
}

.form-validation--pass {
  color: $medium-green;
}

.form-validation--fail {
  color: $valencia;
}

// IE11 has issues displaying inline SVGs.
_:-ms-fullscreen, :root .giant-input {
  input[type="radio"]:not(:checked) + label::before {
    background-image: cr-image-url('radio-empty.svg');
  }

  input[type="checkbox"]:not(:checked) + label::before {
    background-image: cr-image-url('checkbox-empty.svg');
  }

  input[type="radio"]:checked + label::before {
    background-image: cr-image-url('radio-filled.svg');
  }

  input[type="checkbox"]:checked + label::before {
    background-image: cr-image-url('checkbox-filled.svg');
  }
}

.form--compact {
  .controls {
    margin-bottom: calc(#{$default-margin-bottom} * 0.5);
  }

  select, textarea, input[type="text"], input[type="password"], input[type="datetime"],
  input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"],
  input[type="week"], input[type="number"], input[type="email"], input[type="url"],
  input[type="search"], input[type="tel"], input[type="color"], .input-prepend, .mock-input, .mock-textarea {
    margin-bottom: 0;
  }

  input + input { margin-top: 12px }
}

.form--read-only {
  .controls {
    font-weight: $default-semibold-weight;

    p {
      font-weight: $default-normal-weight;
    }
  }

  .control-label {
    font-weight: $default-normal-weight;
    color: $silver-chalice;
  }
}

/*
  White Label
*/

.white-label {
  legend {
    &.advanced-settings {
      color: $mine-shaft;
    }
  }

  .checkbox-list {
    li {
      border-color: $mine-shaft;
    }
  }
}
