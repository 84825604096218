.color-wrapper {
  display: flex;
  height: 182px;
}

.input-color {
  position: relative;
}

.input-color input {
  padding-left: 35px;
}

.input-color .color-box {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: 5px;
  top: 5px;
}

.color-palette {
  border: solid 1px $generic-ccc;
}

.color-palette:hover {
  cursor: pointer;
}

:host {
  width: 150px;
  height: 150px;
  display: block;
  border: solid 1px $generic-ccc;
}

.color-slider {
  cursor: crosshair;
  border: solid 1px $generic-ccc;
}
