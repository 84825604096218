.multi-select-pill-ui {
  margin-bottom: 6px;
  height: auto;
  min-height: $default-line-height * 2 + 12;
  line-height: $default-line-height;
  padding: 5px 8px;
  display: block; // block prevents large cursor bug on content editable
  width: 100%;
  vertical-align: middle;
  background: $white;
  border: solid 1px $silver;
  font-family: "Source Sans Pro", sans-serif;
  font-size: $default-font-size;
  border-radius: 0px;
  @include box-shadow(none);
  @include transition(border linear .2s, box-shadow linear .2s);
  @include box-sizing(border-box);

  &.multi-select-pill-ui-selecting {
    color: transparent;
  }

  &:focus, &.multi-select-pill-ui-focused {
    border-color: $attribluetion;
    outline: 0;
    @include box-shadow(inset 0 1px 1px rgba($black--persistent, .075), 0 0 8px rgba(82, 168, 236, .3));
  }

  .pills-pill-gray1 {
    color: $black;
  }

  .pills-pill-gray2 {
    color: $white;
  }
}
