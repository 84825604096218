// looking for the the table version of this class (.key-value-table)?
// try `/styles/tables/_key-value-table.scss`
.key-value-data, .key-value-pair {
  $key-minimum-width: 100px;
  $collapsing-key-breakpoint-width: 160px;
  $value-minimum-width: 160px;
  $gutter-minimum-width: 50px;

  display: flex;
  flex-flow: row wrap;
  @include rhythm(14px, 18px);
  margin-top: 0;
  margin-bottom: $default-margin-bottom;

  dt, .key-value-data--key {
    padding: unset;
    min-width: $collapsing-key-breakpoint-width;
    max-width: unset;
    flex: 0 0 $collapsing-key-breakpoint-width;
    color: $gun-powder;
  }

  dd, .key-value-data--value {
    padding: 0 0 12px 0;
    margin-left: 0;
    min-width: $value-minimum-width;
    max-width: unset;
    flex: 1 1 calc(100% - #{$collapsing-key-breakpoint-width} - #{$gutter-minimum-width});
    font-weight: $default-semibold-weight;
    word-break: break-word;
  }

  .key-value-pair {
    margin-bottom: 0;
    width: 100%;
  }

  &.key-value-data--no-collapse {
    display: grid;
    @include rhythm(14px, 18px);
    grid-template-columns: minmax($key-minimum-width, max-content) minmax($value-minimum-width, max-content);

    dt, .key-value-data--key {
      grid-column: 1;
      padding: 6px 0;
      max-width: 200px;
      min-width: unset;
      margin-right: $gutter-minimum-width;
    }

    dd, .key-value-data--value {
      grid-column: 2;
      padding: 6px 0;
      margin-left: 0;
    }
  }

  &.key-value-data--weight-reversed {
    dt, .key-value-data--key {
      font-weight: $default-semibold-weight;
      color: $mine-shaft;
    }

    dd, .key-value-data--value {
      font-weight: $default-normal-weight;
      color: $gun-powder;
    }
  }

  &.key-value-data--condensed {
    dd, .key-value-data--value {
      padding: 0 0 4px 0;
    }

    &.key-value-data--no-collapse {
      dt, .key-value-data--key {
        padding: 2px 0 0 0;
        line-height: 1em;
      }
    }
  }

  &.key-value-data--highlighted-keys {
    border: 1px solid $dark-solitude;
    border-radius: 5px;
    overflow: hidden;

    .key-value-header {
      background-color: $dark-solitude;
      margin: 0;
      padding: 12px;
      width: 100%;
      display: block;
      font-weight: $default-normal-weight;
      @include rhythm(18px, 24px);
    }

    dt, .key-value-data--key {
      padding: 6px 12px;
      background-color: $steel-blue;
      color: $white;
    }

    dd, .key-value-data--value {
      padding: 6px 12px;
    }

    .key-value-pair {
      background-color: $steel-blue;

      dd, .key-value-data--value {
        background-color: $white;
      }

      &:hover {
        background-color: $attribluetion;

        dt, .key-value-data--key {
          background-color: $attribluetion;
        }

        dd, .key-value-data--value {
          background-color: $solitude;
        }
      }
    }
  }

  &.key-value-data--wide-keys {
    dt, .key-value-data--key {
      min-width: 235px;
      flex: 0 0 235px;
    }

    dd, .key-value-data--value {
      flex: 1 1 calc(100% - 235px - #{$gutter-minimum-width});
    }
  }
}
