$table-cell-padding: 12px;
$table-sort-arrow-left-margin: 5px;

table, .table {
  border-spacing: 0;
  font-size: 14px;

  &.scroll {
    display: block;
    max-height: 370px;
    overflow-y: auto;
  }
}

.table-empty, .table-container.table-empty table {
  text-align: center;
  height: 384px;
  padding-top: 144px;
  color: $smugglers-cove;

  &.table-empty--short {
    height: initial;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  img {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto 20px;

    &.reports-table {
      display: block;
      width: 400px;
      height: 200px;
      margin: 0 auto 0;
    }
  }
}

td.table-empty, .table-container td.table-empty {
  text-align: center;
  height: 250px;
  padding-top: 144px !important;
  color: $smugglers-cove;
  font-size: $default-font-size;
}

.table, .table-container table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: $default-margin-bottom;
  line-height: 18px;

  td,
  th {
    padding: $table-cell-padding;
  }

  td {
    vertical-align: top;
  }

  td .dropdown {
    .dropdown-toggle.btn.btn--tertiary {
      width: 100%;
      padding: 0;
      font-size: 14px;
      line-height: 16px;

      .icon-arrow-down {
        font-size: 14px;
        line-height: 16px;
      }
    }

    &.open {
      .dropdown-toggle.btn.btn--tertiary {
        color: $attribluetion;
        background-color: transparent;
      }
    }
  }

  &.table-cells-have-vert-centered-text {
    td {
      vertical-align: middle;
    }
  }

  &.table-has-left-icons, &.table-has-left-legend {
    tbody tr td:first-child {
      width: 1px;
      white-space: nowrap;

      &.sort {
        i.table-row-up, i.table-row-down {
          font-size: 24px;
          transform: rotate(-90deg);
        }
      }

      // spacing on elements depends on the element having
      // the class `tooltip`, being an `i` elment, an `a` element
      // or `span`
      & > .tooltip, & > i, & > a, & > span {
        margin-left: 5px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &.table-has-left-legend {
    tbody tr td:first-child {
      vertical-align: middle;
    }
  }

  &.table-has-right-icons, &.table-has-right-legend {
    tbody tr {
      // keep icon buttons from messing with row height
      & ui-icon-button .btn {
        line-height: inherit;
      }
    }

    tbody tr td:last-child {
      width: 1px;
      white-space: nowrap;

      // spacing on elements depends on the element having
      // the class `tooltip`, being an `i` elment, an `a` element
      // or `span`
      & > .tooltip, & > i, & > a, & > span, & > ui-tooltip, & > ui-icon-button {
        margin-left: 5px;

        &:first-child {
          margin-left: 0;
        }
      }

      // ensure icon tooltips have enough width
      .cr-tooltip-content {
        width: fit-content;
      }
    }
  }

  &.table--layout-small {
    thead {
      display: none;
    }

    tbody tr {
      &::after {
        content: '';
        display: block;
        height: 2px;
        width: 100%;
        background: $dark-solitude;
      }

      &:last-of-type::after {
        display: none;
      }

      td {
        border-bottom: $default-border;
        display: block;
        text-align: right;

        .controls {
          display: inline-block;
        }

        .table-mobile-header {
          float: left;
          font-weight: $default-semibold-weight;
        }

        &:last-child {
          border-bottom: 0;
          width: initial;

          .btn.btn-icon {
            padding: 0
          }
        }
      }
    }
  }

  &.table-no-head tr:first-child {
    border-top: none;
  }

  .table-numeric-cell {
    text-align: right;

    small {
      margin-left: 5px;
      color: $silver;
    }
  }

  .table-legend {
    display: block;
    width: 28px;
    height: 12px;
  }

  thead {
    border-bottom: $default-border;

    tr.summary-header {
      border-top: $default-border;

      th {
        color: $black;
        font-weight: bold;
      }
    }

    &.sticky-header th {
      position: sticky;
      position: -webkit-sticky;
      top: 0px;
      background: $white;
      background-color: $white;
      z-index: 1;
      will-change: transform;
      border-bottom: solid 1px $white;

      &::after {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        border-bottom: $default-border;
      }
    }

    th {
      text-align: left;
      white-space: nowrap;
      color: $gun-powder;

      &.table-header--center {
        text-align: center;
      }

      a {
        color: $gun-powder;
        font-weight: $default-semibold-weight;
        display: block;
        width: 100%;
      }

      a i {
        line-height: 18px; // match default font line-height to prevent vertical offset
        margin-left: $table-sort-arrow-left-margin;
        margin-right: -$table-sort-arrow-left-margin - $icon-font-size;
        position: relative;
        top: -1px;
      }
    }
  }

  .combine-first-two-rows {
    tr:nth-of-type(1) td {
      padding-bottom: 0;
    }

    tr:nth-of-type(2) {
      border-top: 0;

      td {
        padding-top: 0;
      }
    }

    &:hover tr td, &:hover tr + td, &.active {
      border-color: $light-steel-blue;
      background-color: $solitude;
    }
  }

  tbody tr {
    border-top: 1px solid $dark-solitude;

    &.table-danger-row td, &.table-danger-row:hover td {
      background-color: background('danger');
      border-color: foreground('danger');
    }

    &.table-total-row {
      &:hover td {
        border-color: $white;
        background-color: $white;
      }

      td {
        border-color: $white;
      }
    }

    &:hover td, &.active {
      border-color: $light-steel-blue;
      background-color: $solitude;
    }

    &.tr-no-wrap {
      td {
        white-space: nowrap;
      }
    }

    td {
      position: relative;

      &.td-no-wrap {
        white-space: nowrap;
      }

      &.cell-with-actions {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        align-items: center;
      }

      &.max-width-col {
        max-width: 200px;
        word-break: break-all;
        text-align: left;
      }

      select {
        margin-bottom: 0;
        height: 26px;
      }

      .cell-input {
        margin-bottom: 0;
        font-size: 15px;
      }
    }
  }
}

.table--fixed, .table-container.table--fixed table {
  table-layout: fixed;
}

/*
  Edit Row
*/

.table tbody tr.table-edit-row {
  border: none;
  @include rhythm;

  td {
    background-color: $white;
    padding: 12px 0px;
  }

  .table-edit-row-card { margin: 0; }
}

.table tbody tr.table-row-in-edit-mode td {
  color: $silver;

  i {
    color: $silver;
  }
}

.table tbody tr.table-edit-row .icon-close-big {
  margin-right: 0;
  color: $silver;
  cursor: pointer;
}

/*
  Column with subscript
*/

.table tbody tr td.subscript-column {
  padding: 8px 12px;
  line-height: 9px;

  small {
    @extend .u-muted;
    display: block;
    margin-top: -2px;
    line-height: 18px;
  }
}

/*
  White Label
*/

.white-label {
  .table-empty, .table-container.table-empty table {
    color: $default-font-color;
  }

  .combine-first-two-rows {
    &:hover tr td, &:hover tr + td, &.active {
      border-color: $gun-powder;
      background-color: $solitude;
    }
  }

  .table, .table-container table {
    thead tr th {
      border-color: $gun-powder;
      color: $default-font-color;

      a {
        color: $default-font-color;
      }
    }

    tbody tr {
      &:hover, &.active {
        td {
          border-color: $gun-powder;
          background-color: $solitude;
        }
      }

      &.table-danger-row td, &.table-danger-row:hover td {
        background-color: background('danger');
        border-color: foreground('danger');
      }
    }
  }
}
