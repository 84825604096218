.main-wrapper {
  @include box-sizing(border-box);
  @include transition(padding-bottom 1s);
  min-width: 975px;
  padding: 0 24px;
  width: 100%;
  margin: $default-margin-bottom auto;
}

.centered-layout {
  max-width: 1200px;
  margin: 0 auto;
}

.section {
  margin-bottom: $default-margin-bottom * 2;
}

.sub-section {
  margin-bottom: $default-margin-bottom;
}

.well {
  padding: 24px;
  margin-bottom: $default-margin-bottom;
  background: $solitude;
  border-radius: $default-border-radius;

  h6 {
    font-size: 14px;
  }
}

.quoted-well {
  padding: 15px;
  margin-bottom: $default-margin-bottom;
  background: $solitude;
  border-radius: $default-border-radius;

  &::before {
    content: '';
    background: cr-image-url('quotemark.svg');
    height: 18px;
    width: 21px;
    position: absolute;
    margin-top: -20px;
    margin-left: -24px;
  }
}

.report-chart-header {
  display: inline-block;
  width: 100%;
  @include clearfix;
}

.header-with-content {
  margin-bottom: $default-margin-bottom;

  &.reports-header {
    margin-top: 48px;
  }

  h1 {
    margin-bottom: 0px;
    word-break: break-word;
  }

  h2, h3 {
    display: inline-block;
    line-height: 48px;
    margin-bottom: 0;
  }

  &::before, &::after {
    display: table;
    content: '';
    line-height: 0;
  }

  &::after {
    clear: both;
  }

  .header-left-content {
    display: inline-block;
  }

  .header-right-content {
    float: right;

    > .btn:not(.dropdown-toggle), .cr-select, .dropdown, .btn-group, .progress-btn {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 10px;
      vertical-align: middle;
    }

    .dropdown {
      line-height: $default-line-height;
    }

    input[type="text"] {
      margin: 6px 0;
    }

    .dropdown-menu {
      line-height: $default-margin-bottom;
    }
  }
}

.tall-loader {
  display: block;
  width: 32px;
  height: 32px;
  margin: 84px auto;
}

.two-columns {
  -moz-columns: 2;
  -webkit-columns: 2;
  columns: 2;
}

.three-columns {
  -moz-columns: 3;
  -webkit-columns: 3;
  columns: 3;
}

.three-columns, .two-columns {
  span {
    display: inline-block;
  }
}

.blank-chart {
  margin: $default-margin-bottom auto ($default-margin-bottom * 2);
  width: 460px;
  padding: 40px;
  background: $white;
  font-size: 18px;

  h4 {
    font-size: 24px;
    color: $mine-shaft;
  }

  p {
    color: $gun-powder;
  }
}

.responsive-body-width {
  .blank-chart {
    width: 100%;
  }
}

.max-height-container {
  overflow: auto;
}

.white-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: $white;
  cursor: default;
  z-index: 9999;

  &.transparent-overlay {
    opacity: 0.1;
    background-color: transparent;
  }
}

.vertical-align-content {
  height: 100%;
  @include flex;
  align-items: center;
}

@media (min-width: $screen-size-medium) {
  .flex-item {
    margin-left: 2.127%;

    &:first-child {
      margin-left: 0;
    }
  }
}

// Flex card layout
.flex-cards {
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;

  @media screen and (max-width: 1300px) {
    .col-3 {
      width: 50%;
    }
  }

  .flex-card {
    flex-basis: 31%;
    flex-grow: 0;
    padding: 10px;

    &.report-index-card {
      padding: 10px 0px;
      flex-basis: 49%;
    }
  }

  .card {
    min-width: 125px;
    margin-bottom: 0px;
  }

  .col-3 {
    float: left;
    width: 33%;
  }
  @include flex;
}

// Row flex card layout
.row-flex {
  flex-flow: row;
  margin-bottom: 30px;

  @media screen and (max-width: 1300px) {
    .col-3 {
      width: 50%;
    }
  }

  @media screen and (max-width: $screen-size-medium) {
    .col-2 {
      width: 100%;
      margin-left: 0;

      &:not(:first-child) {
        margin-top: 24px;
      }

      &.card {
        padding: 18px;
      }
    }
  }

  &.row-flex-stretch {
    align-items: stretch;
    align-content: flex-start;
  }

  &.space-between {
    justify-content: space-between;
  }

  .flex-item {
    flex-grow: 0;
    margin-bottom: 0px;
  }

  .col-3 {
    float: left;
    width: 33%;
  }

  .col-2 {
    width: 50%;

    &.card {
      padding: 32px;
    }
  }
  @include flex;
}

@media screen and (max-width: 768px) {
  .row-flex {
    flex-wrap: wrap;
  }
}

.col-flex {
  @include flex;
  flex-direction: column;

  &.space-between {
    justify-content: space-between;
  }
}

/**
 * Put on a parent div to center its children horizontally
 */
.center-horizontal {
  @include flex;
  justify-content: center;
}

.placeholder {
  width: 100%;
  box-sizing: border-box;

  .placeholder-text {
    display: inline-block;
    border-radius: 100px;
    min-width: 100px;
    height: $default-font-size;
    background-color: $dark-solitude;
    margin-bottom: calc(#{$default-margin-bottom} * 0.5);

    animation: shimmer 1.5s infinite;
  }

  h1.placeholder-text {
    height: 30px;
    min-width: 250px;
    margin-bottom: auto;
  }

  .placeholder-fullwidth {
    width: 100%;
    min-width: auto;
  }

  .placeholder-btn,
  .placeholder-btn:hover,
  .placeholder-btn:active,
  .placeholder-btn:focus {
    height: 34px;
    width: 150px;
    background-color: $dark-solitude;
    border-color: $dark-solitude;
    cursor: pointer;
    pointer-events: none;
  }
}

// Cover the full viewport background
// helpful when you need the background to stay fixed as you scroll
// and using height: 100% doesn't cover the scrolling space
// NOTE: you may run into issues with this background covering elements
// and in that case, you can use position: relative on your content to un-cover it
.background--view-fixed {
  position: fixed;
  height: 100vh;
  width: 100vw;
}

// Full coverage backgrounds
.background--brand-abstract {
  // moved image to pseudoelement so we can reduce the opacity
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: cr-image-url('brand-abstract1.png');
    background-size: contain;
    opacity: 0.7;
  }
}

.background--brand-abstract2 {
  background-image: cr-image-url('brand-abstract2.png');
  background-size: cover;
}

.background--brand-abstract3 {
  background-image: cr-image-url('brand-abstract3.svg');
  background-size: cover;
}

.background--brand-abstract4 {
  background-image: cr-image-url('brand-abstract4.svg');
  background-size: cover;
}

.background--brand-abstract5 {
  background-image: cr-image-url('brand-abstract5.svg');
  background-size: cover;
}

.background--brand-abstract5-solitude {
  background-image: cr-image-url('brand-abstract5-solitude.svg');
  background-size: cover;
}

.background--brand-abstract5-light-gray {
  background-image: cr-image-url('brand-abstract/squiggly-road-light-gray.svg');
  background-size: cover;
}

.background--brand-abstract6 {
  background-image: cr-image-url('brand-abstract6.svg');
  background-size: cover;
}

.background--brand-abstract7 {
  background-image: cr-image-url('billing/upsell-card/background.svg');
  background-size: cover;
  // this particular image looks best when its right side doesn't move around
  background-position: 100% 0;
}

.background--brand-abstract8 {
  background-image: cr-image-url('brand-abstract8.png');
  background-size: cover;
  // centered
  background-position: 50% 50%;
}

.background--brand-abstract9 {
  background-image: cr-image-url('blank-state/transcript-search-bubbles.svg');
  background-size: contain;
}

.background--brand-abstract10 {
  background-image: cr-image-url('checkout/forms.svg');
  background-size: cover;
}

.background--brand-abstract11 {
  background-image: cr-image-url('checkout/ci.svg');
  background-size: cover;
  background-position: 50% 50%;
}

.background--brand-abstract12 {
  background-image: cr-image-url('checkout/lc.png');
  background-size: cover;
  background-position: 50% 50%;
}

.background--brand-abstract13 {
  background-image: cr-image-url('checkout/calls.svg');
  background-size: cover;
}

.background--brand-abstract14 {
  background-image: cr-image-url('brand-abstract14.png');
  background-size: cover;
  // centered
  background-position: 50% 50%;
}

.background--brand-abstract15 {
  background-image: cr-image-url('brand-abstract15.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.background--brand-abstract16 {
  background-image: cr-image-url('brand-abstract/compass.svg');
  background-size: cover;
  background-position: center;
}

.background--brand-abstract17 {
  background-image: cr-image-url('brand-abstract/chess-king.svg');
  background-size: cover;
  background-position: center;
}

.background--brand-abstract18 {
  background-image: cr-image-url('brand-abstract/alternate-communication.svg');
  background-size: cover;
  background-position: center;
}

.background-brand-abstract19 {
  background-image: cr-image-url('brand-abstract/inspection.svg');
  background-size: cover;
  background-position: center;
}

.background--brand-abstract20 {
  background-image: cr-image-url('checkout/ca.svg');
  background-size: cover;
  background-position: 50% 50%;
}

.background-waveform {
  background-image: cr-image-url('card-backgrounds/waveform_bg.svg');
  background-position: right top;
  background-size: contain;
  background-repeat: no-repeat;
}

@include keyframes(popOn) {
  0% { opacity: 0; }
  99% { opacity: 0; }
  100% { opacity: 1; }
}

.upsell-pci-modal {
  background-image: cr-image-url('upsell/pci_upsell_modal.png');
  background-repeat: no-repeat;
  z-index: 0;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.alphabet-soup-background {
  background-image: cr-image-url('alphabet-soup.svg');
  background-size: cover;
  // even though the background image has color, this turns the background blue quicker
  background-color: $attribluetion;
}

.blankstate--no-action {
  display: block;
  min-height: 280px;
  height: 100%;
  @include flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.blankstate--copy {
  color: $silver-chalice;
  font-size: 18px;
  line-height: 30px;
  max-width: 500px;
  text-align: center;
  margin-bottom: 30px;
}

.blankstate--subheaders {
  color: $smugglers-cove;
  font-weight: bold;
  margin-bottom: 0px;
}

@keyframes shimmer {
  0% { opacity: 1; }

  50% { opacity: .8; }

  100% { opacity: 1; }
}
