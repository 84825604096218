.limited-text-area-container {
  height: 100%;
  min-height: 60px;
  position: relative;

  .characters-remaining {
    background: $white;
    bottom: 10px;
    line-height: 1;
    position: absolute;
    right: 15px;
  }
}

.limited-text-area {
  @include box-shadow(none);
  height: 100%;
  resize: none;
  max-width: 100%;
  min-height: 0;
  padding: 10px 15px;
  width: 100%;
  margin-bottom: 0px;
}
