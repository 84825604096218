// These styles can be removed once the legacy LC1 SMS conversation component has been removed.
.sms-conversation-outer {
  height: 100%;
  position: relative;
}

.sms-conversation-roles-container {
  background: $solitude;
  color: $silver;
  padding: 6px 12px;

  .sms-conversation-role-agent {
    color: $attribluetion;
  }
}

.sms-conversation {
  height: 100%;

  &.roles-header-visible {
    @include calc(height, "100% - 36px");
  }

  .sms-conversation-inner {
    padding: 5px 10px 18px;
  }

  .sms-conversation-date {
    text-align: center;
    font-size: 13px;
  }

  .sms-conversation-item-container {
    a {
      text-decoration: underline; // should look clickable
      color: $mine-shaft; // should match `.sms-conversation-item` color below
    }

    &.from-agent {
      a {
        color: $white;
      }
    }

    &:hover > {
      .sms-conversation-item {
        opacity: .9;
      }

      .sms-conversation-details {
        visibility: visible;
      }
    }
  }

  .sms-conversation-item {
    background: $dark-solitude;
    border-radius: $default-border-radius;
    color: $mine-shaft;
    display: inline-block;
    line-height: 20px;
    min-width: 50%;
    max-width: calc(100% - 33px);
    margin: 0 24px 9px 0;
    padding: 6px 16px;
    position: relative;
    word-break: break-word;

    &.media-item {
      padding: 3px;
      min-width: 0px;
      line-height: 0px;

      .media-fallback-text {
        line-height: 20px;
        padding: 6px 16px;
      }

      .media-download {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        padding: 6px;
        background: $white;
        border-radius: $default-border-radius;
        transition: opacity $default-transition-ease;
        color: $smugglers-cove;
      }

      &:hover .media-download {
        opacity: 1;
        visibility: visible;
      }

      img, video {
        width: auto;
        height: auto;
        max-height: 375px;
        border-radius: $default-border-radius;
      }

      audio {
        border-radius: $default-border-radius;
      }

      &::after { display: none; }
    }

    &::after {
      border-bottom: 12px solid transparent;
      border-left: 12px solid $dark-solitude;
      content: '';
      height: 0;
      margin-top: -3px;
      left: 0;
      position: absolute;
      top: 100%;
      width: 0;
    }

    &.from-agent {
      background-color: $attribluetion;
      margin: 0 0 9px 24px;

      &::after {
        border-left-color: transparent;
        border-top: 12px solid $attribluetion;
        left: auto;
        right: 0;
      }
    }
  }

  .sms-conversation-details {
    font-size: 12px;
    line-height: 20px;
    visibility: hidden;
  }

  .sms-conversation-item-error {
    font-size: 12px;
    line-height: 18px;
    color: $cosmos;
    clear: both;

    i {
      font-size: $default-font-size;
      line-height: 14px;
    }
  }
}

.from-agent .conversation-item a {
  color: $white;
  text-decoration: underline;
}

/*
  White Label
*/

.white-label {
  .sms-conversation {
    .sms-conversation-item.from-agent {
      background-color: $gun-powder;

      &::after {
        border-top-color: $gun-powder;
      }
    }

    .sms-conversation-roles-container {
      background: $dark-solitude;

      .sms-conversation-role-agent {
        color: $gun-powder;
      }
    }
  }
}
