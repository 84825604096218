@include keyframes(infinityPulse) {
  0% {
    opacity: 0.3;
  }

  26.66% { // 40% / 1.5s = 26.66%;
    opacity: 0.5;
    box-shadow: 0 0 0 2px rgba($black--persistent, .12), 0 0 5px 5px $white, 0 0 0 5px rgba($black--persistent, .5);
  }

  60% { // (60% / 1.5s) + 20 = 60%
    box-shadow: 0 0 0 2px rgba($black--persistent, .12), 0 0 5px 5px $white, 0 0 0 5px rgba($black--persistent, .5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

// shared
.player, .bar-player, .player-full, .player-line, .annotated-player {
  .player-disabled-opacity {
    opacity: 0.5;
  }

  &.player-talk-time .player-talk-circle {
    width: 8px;
    height: 8px;
    margin-left: 2px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
  }
}

play-full-player {
  .tall-loader {
    margin: 66px auto;
  }

  .rating-parent {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    .rating {
      display: inline-flex;

      .rating-label {
        font-size: 12px;
        color: $mine-shaft;
        padding: 5px 15px;
      }
    }
  }

  .panel-tabs {
    z-index: 1;
    position: inherit;

    .panel-tab {
      border-bottom: 2px solid transparent;
      padding-bottom: 10px;
      margin-right: 40px;

      &.active {
        border-bottom: 3px solid $attribluetion;
        color: $black;

        &.alpha-tab {
          border-color: $plum;
        }
      }

      &.alpha-tab img {
        width: 20px;
        vertical-align: text-top;
      }
    }
  }

  play-ci-alphas-tab {
    max-height: 400px;
    overflow-y: auto;
  }
}

.transcript-language {
  padding: 0 12px 5px 12px;
}

// shared
$player-controls-btn-height: 42px;

.player-full, .bar-player, .annotated-player {
  .player-time-container {
    color: $steel-blue;
  }

  .expanded-details-bar {
    .player-time-container {
      color: $silver-chalice;
    }
  }

  .player-controls {
    margin: 3px auto 0px auto;
    width: 136px;
  }

  .btn:where(:not(.btn--upsell)) {
    color: $steel-blue;
    border: 1px solid $steel-blue;
    width: $player-controls-btn-height;
    height: $player-controls-btn-height;
    line-height: $player-controls-btn-height;
    padding: 0;
    margin: 0;
    border-radius: $player-controls-btn-height;

    &.player-pause-play-btn {
      margin: 0px 7px;
    }

    &.player-playback, &.player-volume {
      width: 36px;
      height: 24px;
      line-height: 22px;
      vertical-align: text-bottom;
      font-size: 12px;
    }

    &:hover, &:focus, &:active {
      color: $white;
      background-color: $smugglers-cove;
      border: 1px solid $smugglers-cove;
    }

    i {
      margin: 0;
      top: -1px;
      left: .5px;
    }
  }

  .btn.btn-annotation {
    width: auto;
    border-radius: 0;
    border: none;

    &:focus, &:active, &:hover {
      background-color: $white;
    }
  }

  .btn.spam-call-btn {
    line-height: 15px;
    width: auto;
    height: auto;
    border-radius: unset;
    border: none;
    padding: 0 8px;
    color: $smugglers-cove;

    &:hover, &:focus {
      background-color: unset;
      color: $smugglers-cove;
    }
  }

  .scrub-btn {
    border: none !important;
    width: unset;
    padding: 0 5px;

    &:hover, &:focus {
      background-color: unset;
      color: $smugglers-cove;
      border: none;
    }
  }

  .timestamp-btn {
    border-radius: unset;
    width: unset;
    height: unset;
    padding: 0 16px;
    line-height: 34px;
  }

  .player-volume {
    i {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

// bar player
.bar-player {
  .player-controls {
    position: relative;
    z-index: 1;
  }

  .bar-player-details {
    position: absolute;
    bottom: 14px;
    right: 0;
    left: 0;
  }

  .bar-player-bar {
    margin-top: 36px;
    margin-bottom: 12px;
    width: 100%;
    height: 6px;
    cursor: pointer;
  }

  .bar-player-bar-bg {
    width: 100%;
    background: $light-steel-blue;
  }

  .bar-player-bar-bg, .bar-player-bar-progress {
    height: 100%;
    border-radius: $default-border-radius;
  }

  .bar-player-bar-progress {
    width: 0%;
    top: 0;
    position: absolute;
    z-index: 1;
    background: $steel-blue;
  }
}

// player line
.player-line {
  table, .player-line-timeline-cell {
    width: 100%;
  }

  .player-line-timeline-cell, .player-controls {
    padding-right: 10px;
  }

  .player-controls {
    color: $steel-blue;
  }

  .player-time-container {
    color: $steel-blue;
  }

  .progress-line-container-padding {
    padding: 20px 0px;
  }
}

// nodes
.player-talk-time .player-talk-circle {
  margin-left: 0;
}

.node-tooltip-content-left {
  padding-right: 30px;
  @include box-sizing(border-box);
}

.player-node-hitbox, .player-node-active-circle, .player-node-active-circle::after, .node-tooltip-content-right, .player-keyword-node {
  position: absolute;
}

.node-tooltip-content-right {
  right: 10px;
  top: 50%;
  @include transform(translateY(-50%));
}

.node-tooltip-label {
  font-size: 10px;
  line-height: 12px;
}

.node-tooltip-keywords span {
  font-weight: $default-semibold-weight;
}

.caller-nodes .icon-active-speaker {
  @include transform(rotateY(-180deg));
}

.player-node-hitbox {
  z-index: 2;
}

.player-node-active-circle {
  background: $smugglers-cove;
  display: none;
  z-index: 1;
  @include box-shadow(0 0 0 3px rgba(11, 72, 107, .15)); // $smugglers-cove

  &::after { // pulse rings
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    @include box-shadow(0 0 0 0px rgba(11, 72, 107, 1)); // $smugglers-cove
    -webkit-animation: infinityPulse 1.5s infinite ease-out 75ms;
    -moz-animation: infinityPulse 1.5s infinite ease-out 75ms;
    animation: infinityPulse 1.5s infinite ease-out 75ms;
  }
}

.player-keyword-node {
  &.active {
    .player-node-active-circle {
      display: block;
    }
  }
}

.agent-nodes .player-keyword-node {
  background: $attribluetion;
}

.caller-nodes .player-keyword-node {
  background: $nepal;
}

.player-keyword-node, .player-node-active-circle {
  border-radius: 50%;
}

.annotation-lines {
  position: absolute;
  top: 29px;
  left: 0;
  right: 0;
  height: 104px;
  z-index: 10;

  .annotation-mark {
    background: $pumpkin-spice-latte;
    border-radius: 3px;
    opacity: 0.7;
    height: 85%;
    width: 3px;
    margin: 0 auto;
  }
}

// player full
.player-full, .player-line, .annotated-player {
  position: relative;

  .agent-samples, .caller-samples {
    cursor: pointer;
  }

  .agent-samples {
    @include transform(rotateX(-180deg));
  }

  .agent-samples:not(.mono-channel) a {
    background: $attribluetion content-box;
  }

  .caller-samples a, .agent-samples.mono-channel a {
    background: $nepal content-box;
  }

  $progress-point-offset: -6px;
  $progress-animate-time: 250ms;

  .progress-line-container {
    height: 2px;
    width: 100%;
    font-size: 0px; // removes inline-block space
    background: $dark-solitude;
    margin: 2px 0;
    cursor: pointer;

    .progress-hover-back-line, .progress-hover-forward-line, .progress-hover-forward-line::after {
      background: rgba($pumpkin-spice-latte, .5);
    }

    .progress-line, .progress-hover-back-line::after {
      background: $pumpkin-spice-latte;
    }

    .progress-line, .progress-hover-back-line, .progress-hover-forward-line {
      height: 100%;
    }

    .progress-hover-back-line {
      &::after {
        left: $progress-point-offset;
      }
    }

    .progress-line-animate-out {
      &.progress-hover-back-line, &.progress-hover-forward-line {
        opacity: 0;
        @include transition(opacity 0ms linear $progress-animate-time, width $progress-animate-time ease-out);
      }
    }

    .progress-hover-back-line, .progress-hover-forward-line {
      opacity: 0;
    }

    .progress-line::before, .progress-hover-back-line::before {
      right: 0;
    }

    .progress-hover-forward-line::after {
      right: $progress-point-offset;
    }

    .progress-line, .progress-hover-back-line {
      &::before {
        content: "";
        width: 2px;
        height: 100%;
        background: $white;
        position: absolute;
      }
    }

    .progress-hover-back-line, .progress-hover-forward-line {
      &::after {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 7px;
        border: 2px solid $white;
        top: -5px;
        position: absolute;
        z-index: 1;
      }
    }
  }

  .alert {
    position: absolute;
    left: 50%;
    display: table;
    z-index: 5;
    @include transform(translate(-50%, 50%));
  }

  .full-player-details {
    margin-top: -#{$player-controls-btn-height};

    line-height: #{calc(#{$player-controls-btn-height} * 0.25)};

    .player-time-container, .player-agent-talk-time td {
      padding-bottom: #{calc(#{$player-controls-btn-height} * 0.5)};
    }
  }

  &.player-talk-time {
    .player-agent-talk-time, .player-customer-talk-time {
      font-size: 15px;
    }

    .player-agent-talk-time {
      color: $smugglers-cove;

      .player-talk-circle {
        background-color: $smugglers-cove;
      }

      .player-talk-time-amount, .player-talk-time-name {
        color: $smugglers-cove;
      }
    }

    .player-customer-talk-time {
      color: $steel-blue;

      .player-talk-circle {
        background-color: $steel-blue;
      }

      .player-talk-time-amount, .player-talk-time-name {
        color: $steel-blue;
      }
    }
  }
} /* end of .player-full, .player-line */

.player {
  user-select: none;
  white-space: normal;

  @media screen and (max-width: 40.5em) {
    &.player-responsive {
      line-height: initial;
      height: initial;
      border: none;
      width: 100%;

      &.mobile-user-agent {
        width: 100%;
      }

      .player-desktop-content {
        display: none;
      }

      .player-transcript-content {
        display: none;
      }

      .player-mobile-content {
        display: block;
      }
    }
  }

  .player-mobile-img {
    width: 245px;
    cursor: pointer;
  }

  .player-desktop-content {
    position: relative;
  }

  .player-mobile-content {
    display: none;
  }

  .player-transcript-content {
    margin-top: 20px;
  }

  &:not(.player-icon-format) {
    text-align: center;
    margin-bottom: 24px;
    width: 388px;

    &.mobile-user-agent {
      width: 348px;
    }

    &:not(.player-waveform-format) {
      .player-desktop-content {
        height: 46px;
        line-height: 46px;
        border: solid 1px $light-steel-blue;
        background: $white;
        border-radius: 2px;
      }
    }
  }

  &.player-mobile-width {
    width: auto;
  }

  &.player-icon-format {
    i {
      cursor: pointer;
    }
  }

  &.player-with-caption {
    margin-bottom: 40px;
  }

  .player-wait-text {
    vertical-align: top;
  }

  .player-mobile-wait {
    position: relative;

    .player-mobile-wait-text {
      position: absolute;
      top: 50%;
      left: 0;
      @include transform(translateY(-50%));
    }
  }

  .btn {
    padding: 0;
    margin: 0;

    i {
      margin: 0;
      top: 0;
    }
  }

  &:not(.player-waveform-format) {
    .btn:not(.btn--round) {
      line-height: 46px;
      height: 46px;
      width: 39px;

      border: none;
      border-right: solid 1px $light-steel-blue;
      border-radius: 0;

      &:hover, &:focus {
        border: none;
        border-right: solid 1px $light-steel-blue;
      }
    }

    .btn.player-playback-btn {
      width: 40px;
      border-left: solid 1px $light-steel-blue;

      &:hover, &:focus {
        border: none;
        border-left: solid 1px $light-steel-blue;
        border-right: solid 1px $light-steel-blue;
      }
    }

    .player-progress-container {
      height: 100%;
      width: 160px;
    }

    .player-duration, .player-progress {
      color: $smugglers-cove;
      font-size: 14px;
    }

    .player-progress {
      margin: 0 5px 0 15px;
    }

    .player-duration {
      margin: 0 12px;
    }

    .player-link-container {
      float: right;
      line-height: 24px;
    }

    .player-progress-bar {
      &:not(.wave-form) {
        height: 10px;

        .player-progress-bg-bar {
          background: $light-steel-blue;
        }

        .player-progress-bar-progress {
          background: $smugglers-cove;
        }
      }

      &.wave-form {
        vertical-align: top;

        .player-progress-bg-bar, .player-progress-bar-progress {
          .player-progress-bg-bar-img {
            background-size: 147px 37px;
            background-repeat: no-repeat;
            height: 46px;
            background-position: 0 5px;
          }
        }
      }
    }

    .btn.player-volume-btn {
      border: none;

      &:hover, &:focus {
        border: none;
      }
    }
  }

  &.player-waveform-format {
    .alert {
      width: 100%;
      box-sizing: border-box;
      padding-right: 20px;
      position: absolute;
      top: 24px;
      z-index: 1;

      &.alert-warning {
        background-color: rgba($sunflower, 0.8);
      }

      &.alert-danger {
        background-color: rgba($valencia, 0.8);
      }
    }

    .btn:not(.btn--round) {
      margin-right: 2px;
      color: $steel-blue;
      border: 1px solid $steel-blue;
      width: 50px;
      height: 35px;
      line-height: 35px;
      border-radius: 43px;

      &:hover, &:focus, &:active {
        color: $white;
        background-color: $smugglers-cove;
        border: 1px solid $smugglers-cove;
      }
    }

    .btn.player-playback-btn, .btn.player-volume-btn {
      width: 34px;
      height: 18px;
      line-height: 16px;
      vertical-align: text-bottom;
      font-size: 12px;
    }

    .player-volume-btn {
      i {
        font-size: 20px;
        line-height: 16px;
      }
    }

    .player-progress-container {
      width: 388px;
      position: relative;
    }

    &:not(.player-talk-time) {
      .player-progress-container {
        margin-bottom: 5px;
      }

      .player-progress-container {
        height: 50px;
      }

      .player-time-container {
        position: absolute;
        top: 62px;
      }

      .player-link-container {
        top: 62px;
      }

      .player-kw-spotting-suggest-animation {
        position: absolute;
        top: -14px;
      }
    }

    &.player-waveform-format.player-talk-time {
      .player-kw-spotting-suggest-animation {
        position: absolute;
        top: 20px;
      }

      .player-progress-container {
        margin-bottom: 15px;
      }

      .player-progress-container {
        height: 74px;
      }

      .player-link-container, .player-customer-talk-time {
        top: 76px;
      }

      .player-talk-time-name {
        color: $nepal;
        display: inline-block;
        margin-left: 5px;
        vertical-align: middle;
      }

      .player-talk-time-amount {
        color: $silver-chalice;
        text-align: left;
        vertical-align: middle;
        display: inline-block;
        width: 32px;
      }

      .player-agent-talk-time {
        float: left;

        .player-talk-circle {
          background-color: $attribluetion;
        }
      }

      .player-customer-talk-time .player-talk-circle {
        background-color: $steel-blue;
      }
    }

    .player-link-container, .player-time-container, .player-customer-talk-time, .player-agent-talk-time {
      font-size: 12px;
    }

    .player-link-container, .player-customer-talk-time {
      position: absolute;
    }

    .player-link-container {
      right: 0;
    }

    .player-time-container {
      left: 0;
      color: $steel-blue;
    }

    .player-progress-bar {
      vertical-align: top;

      .player-progress-bg-bar, .player-progress-bar-progress {
        .player-progress-bg-bar-img {
          background-size: 388px 50px;
          height: 50px;
          background-repeat: no-repeat;
          background-position: 0px 0px;
        }
      }
    }

    .player-kw-spotting-suggest-animation {
      position: absolute;
    }

    .player-kw-spotting-suggest {
      text-align: left;
      margin-top: 20px;
      padding: 16px;

      &.animation-running {
        visibility: hidden;
      }
    }
  }

  &.mobile-user-agent {
    &:not(.player-waveform-format) {
      .player-playback-btn {
        border-right: none;
        padding-left: 5px;
      }
    }
  }

  .player-progress-bar-progress, .player-progress-bg-bar {
    height: 100%;
  }

  .player-progress-bar, .player-progress-bg-bar {
    width: 100%;
  }

  .player-progress-bg-bar {
    z-index: 0;
  }

  .player-progress-bar-progress {
    width: 0%;
    z-index: 1;
  }

  .player-progress-bar {
    cursor: pointer;
    position: relative;
  }

  .player-progress-bg-bar, .player-progress-bg-bar-img {
    width: 100%;
  }

  .player-progress-bar-progress, .player-progress-bg-bar, .player-progress-bg-bar-img {
    left: 0;
    position: absolute;
  }

  .player-caption {
    font-size: 12px;
    line-height: 24px;
    width: 100%;
    text-align: left;
  }
}

/*
  White Label
*/

.white-label {
  .player-full, .bar-player, .player-line, .annotated-player {
    .player-time-container {
      color: $default-font-color;
    }
  }

  .bar-player {
    .bar-player-bar-bg {
      background: $dark-solitude;
    }

    .bar-player-bar-progress {
      background: $silver;
    }
  }

  .player-line .player-controls {
    color: $gun-powder;
  }

  .player-full, .bar-player, .player-line, .annotated-player {
    .player-node-active-circle {
      background: $mine-shaft;
      @include box-shadow(0 0 0 3px rgba(68, 68, 68, .15)); // $mine-shaft

      &::after { // pulse rings
        @include box-shadow(0 0 0 0px rgba(68, 68, 68, 1)); // $mine-shaft
      }
    }

    .agent-samples:not(.mono-channel) a, .agent-nodes .player-keyword-node {
      background: $gun-powder content-box;
    }

    .caller-samples a, .caller-nodes .player-keyword-node, .agent-samples.mono-channel a {
      background: $silver content-box;
    }

    .btn {
      border: solid 1px $mine-shaft;
      color: $gun-powder;

      &.player-playback {
        color: $mine-shaft;
      }

      &:hover, &:focus, &:active {
        color: $white;
        background-color: $mine-shaft;
        border: solid 1px $mine-shaft;

        &.player-playback {
          color: $white;
        }
      }
    }

    .scrub-btn {
      border: none !important;

      &:hover, &:focus {
        background-color: unset;
        color: $mine-shaft;
        border: none;
      }
    }

    .btn--tertiary.btn-icon {
      border: none !important;

    }

    .progress-line-container {
      .progress-hover-back-line, .progress-hover-forward-line, .progress-hover-forward-line::after {
        background: $gun-powder;
      }

      .progress-line, .progress-hover-back-line::after {
        background: $mine-shaft;
      }
    }

    &.player-talk-time {
      .player-agent-talk-time {
        .player-talk-time-name {
          color: $gun-powder;
        }

        .player-talk-circle {
          background-color: $gun-powder;
        }

        .player-talk-time-amount {
          color: $gun-powder;
        }
      }

      .player-customer-talk-time {
        .player-talk-time-name {
          color: $silver;
        }

        .player-talk-circle {
          background-color: $silver;
        }

        .player-talk-time-amount {
          color: $silver;
        }
      }
    }

    .btn.btn-annotation {
      border: none;

      &:focus, &:active, &:hover {
        background-color: $white;
        border: none;
        color: $mine-shaft;
      }
    }
  } /* end of .player-full, .bar-player, .player-line */

  .player {

    @media screen and (max-width: 40.5em) {
      &.player-responsive {
        border: none;
      }
    }

    &:not(.player-icon-format) {
      &:not(.player-waveform-format) {
        .player-desktop-content {
          border: solid 1px $silver;
        }
      }
    }

    .btn {
      color: $gun-powder;

      i {
        color: $mine-shaft;
      }

      &:hover, &:focus {
        background-color: $mine-shaft;
        color: $white;

        i {
          color: $white;
        }
      }
    }

    &:not(.player-waveform-format) {
      .btn {
        &.player-playback-btn, &.player-play-btn {
          border-right: solid 1px $silver;

          &:hover, &:focus {
            border-right: solid 1px $silver;
            color: $white;

            i {
              color: $white;
            }
          }
        }
      }

      .player-playback-btn {
        border-left: solid 1px $silver;

        &:hover, &:focus {
          border-left: solid 1px $silver;
          border-right: solid 1px $silver;
        }
      }

      .player-progress-bar {
        &:not(.wave-form) {
          .player-progress-bg-bar {
            background: $dark-solitude;
          }

          .player-progress-bar-progress {
            background: $silver;
          }
        }
      }
    }

    &.player-waveform-format {
      .btn:not(.btn--round) {
        color: $gun-powder;
        border: solid 1px $mine-shaft;

        &:hover, &:focus, &:active {
          color: $white;
          background-color: $mine-shaft;
          border-color: $mine-shaft;

          i {
            color: $white;
          }
        }
      }

      &.player-waveform-format.player-talk-time {
        .player-talk-time-name {
          color: $gun-powder;
        }

        .player-agent-talk-time .player-talk-circle {
          background-color: $gun-powder;
        }

        .player-customer-talk-time .player-talk-circle {
          background-color: $silver;
        }
      }
    }

    .player-volume-btn {
      border: none;

      &:hover, &:focus {
        border: none;
      }
    }

    &.mobile-user-agent {
      .player-playback-btn {
        border-right: none;
      }
    }

    .player-duration, .player-progress {
      color: $default-font-color;
    }
  }
}
