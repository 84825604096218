/* stylelint-disable selector-max-id */
svg {
  g#Role---Manager {
    g#Group {
      path {
        &.grey-to-blue,
        &.grey-to-white,
        &.grey-to-dark {
          stroke: $silver-chalice;
        }

        &.white {
          stroke: $white;
        }
      }
    }
  }

  g#Role---P-Admin {
    g#Group {
      path {
        &.grey-to-blue,
        &.grey-to-dark {
          stroke: $silver-chalice;
        }
      }
    }
  }

  g#Role---Admin {
    path {
      &.grey-to-blue,
      &.grey-to-dark {
        stroke: $silver-chalice;
      }

      &.fill-grey-to-blue {
        fill: $silver-chalice;
      }
    }

    polygon {
      &.fill-grey-to-blue {
        fill: $silver-chalice;
      }
    }
  }

  g#Role---Reporting {
    path {
      &.grey-to-blue,
      &.grey-to-dark {
        stroke: $silver-chalice;
      }

      &.fill-grey-to-blue,
      &.fill-grey-to-dark {
        fill: $silver-chalice;
      }

      &.fill-white {
        fill: $white;
      }
    }

    polyline {
      &.grey-to-blue {
        stroke: $silver-chalice;
      }

      &.fill-grey-to-blue {
        fill: $silver-chalice;
      }
    }
  }

  g#Role---Notification {
    path {
      &.grey-to-dark,
      &.grey-to-blue {
        stroke: $silver-chalice;
      }

      &.white {
        stroke: $white;
      }

      &.fill-white {
        fill: $white;
      }

      &.fill-grey-to-blue {
        fill: $silver-chalice;
      }
    }
  }
}

.svg-hover {
  svg {
    g#Role---Manager {
      g#Group {
        path {
          &.grey-to-blue {
            stroke: $attribluetion;
          }

          &.grey-to-white {
            stroke: $white;
          }

          &.grey-to-dark {
            stroke: $gun-powder;
          }
        }
      }
    }

    g#Role---P-Admin {
      g#Group {
        path {
          &.grey-to-blue {
            stroke: $attribluetion;
          }

          &.grey-to-dark {
            stroke: $gun-powder;
          }
        }
      }
    }

    g#Role---Admin {
      path {
        &.grey-to-blue {
          stroke: $attribluetion;
        }

        &.grey-to-dark {
          stroke: $gun-powder;
        }

        &.fill-grey-to-blue {
          fill: $attribluetion;
        }
      }

      polygon {
        &.fill-grey-to-blue {
          fill: $attribluetion;
        }
      }
    }

    g#Role---Reporting {
      path {
        &.grey-to-blue {
          stroke: $attribluetion;
        }

        &.grey-to-dark {
          stroke: $gun-powder;
        }

        &.fill-grey-to-blue {
          fill: $attribluetion;
        }

        &.fill-grey-to-dark {
          fill: $gun-powder;
        }
      }

      polyline {
        &.grey-to-blue {
          stroke: $attribluetion;
        }

        &.fill-grey-to-blue {
          fill: $attribluetion;
        }
      }
    }

    g#Role---Notification {
      path {
        &.grey-to-dark {
          stroke: $gun-powder;
        }

        &.grey-to-blue {
          stroke: $attribluetion;
        }

        &.fill-grey-to-blue {
          fill: $attribluetion;
        }
      }
    }
  }
}
