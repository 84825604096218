$body-width: 1200px;

/*
  Grid
*/

$grid-gutter: 18px;
$grid-col-width: 80px;

/*
    Layout
*/

$default-border-radius: 4px;

/*
  Typography
*/

/* stylelint-disable color-no-hex */
$default-font-stack: "Source Sans Pro", sans-serif;
$default-serif-stack: "Source Serif Pro", serif;
$default-font-size: 16px;
$default-small-font-size: 12px;
$default-font-color: $mine-shaft;
$default-line-height: 24px;
$default-margin-bottom: 24px;
$default-semibold-weight: 600;
$default-normal-weight: 400;
$default-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba($black--persistent, 0.07);
$shadow-color: #c2d2dd;
$default-transition-ease: 0.15s ease-in-out;
/* stylelint-enable color-no-hex */

/*
    Buttons
*/

$default-border-radius-small: 2px;
$accessibility-button-border-radius: 4px;

/*
  Forms
*/

$input-default-max-width: 320px;

/*
  Icons
*/

$icon-font-size: 20px;

/*
  Screen sizes
*/

$screen-size-small: 576px;
$screen-size-medium: 768px;
$screen-size-large: 1190px;

/*
  Cards
*/
$default-border: solid 1px $dark-solitude;
$default-hover-shadow: 0px 8px 18px rgba(50, 50, 93, 0.1), 0px 5px 8px rgba(0, 0, 0, 0.007);
$default-hover-shadow-small: 0px 2px 4px rgba(50, 50, 93, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.007);

/*
  Global Nav
*/
$global-nav-collapsed-width: 68px;
$global-nav-expanded-width: 364px; // just the expanded panel, not width of both
$global-nav-icon-size: 32px;

/*
  Focus outline
*/
$default-outline-offset: 3px;
$default-outline-color: $attribluetion--persistent;
$default-outline-color-whitelabel: $mine-shaft;
$default-outline-width: 2px;
