:root {
  // These color variables match the color they describe:
  --color-white: #{$white--persistent};
  --color-black: #{$black--persistent};
  --color-dark-solitude: #{$dark-solitude--persistent};
  --color-silver: #{$silver--persistent};
  --color-silver-chalice: #{$silver-chalice--persistent};
  --color-gun-powder: #{$gun-powder--persistent};
  --color-mine-shaft: #{$mine-shaft--persistent};
  --color-solitude: #{$solitude--persistent};
  --color-light-steel-blue: #{$light-steel-blue--persistent};
  --color-nepal: #{$nepal--persistent};
  --color-steel-blue: #{$steel-blue--persistent};

  // These colors are set to grays, despite their name!
  // This is a fallback set of colors, in case we're not setting these in JS correctly.
  // Thus, we should default to a generic color palette for customers not aware of the CallRail brand.
  --color-san-marino: #{$gun-powder--persistent};
  --color-dark-san-marino: #{$mine-shaft--persistent};
  --color-attribluetion: #{$gun-powder--persistent};
  --color-smugglers-cove: #{$mine-shaft--persistent};
}
