$button-size: 20px;
$button-height: 24px;
$button-width: 46px;
$button-position: 25px;

.controls-inline .dropdown.actionable-dropdown {
  margin: 0;
  line-height: 0;

  @media (hover: none) {
    margin-right: 30px;
  }
}

.actionable-wrapper {
  margin: 0 8px;
}

.dropdown.actionable-dropdown {
  height: 24px;
  position: relative;

  // Show the actionable filter on touchscreens and hide it on devices with mice.
  @media (hover: hover) {
    &.open .actionable-filter,
    &.open:hover .actionable-filter,
    .actionable-filter {
      @include visually-hidden;
      opacity: 0;
      @include transition(opacity 0.3s);
    }

    &:hover .actionable-filter,
    .actionable-filter:hover,
    .actionable-filter:focus,
    &.actionable-dropdown-focus .actionable-filter {
      @include undo-visually-hidden(60px, $button-height);
      position: absolute;
      display: block;
      opacity: 100;
    }

    .actionable-filter:focus {
      @include alternate-outline {
        &::after {
          content: '';
          width: 22px;
          height: 22px;
          border-radius: $default-border-radius;
          border: $default-outline-width solid $valencia;
          position: relative;
          top: -26px;
          right: -30px;
          display: block;
        }
      }
    }
  }

  .dropdown-toggle {
    position: relative;
    z-index: 2;
  }
}

.actionable-filter {
  position: absolute;
  z-index: 1;
  right: -30px;
  width: 60px;
  text-align: right;
  line-height: 22px;
  height: $button-height;
  top: 0;

  &.hidden {
    display: none !important;
  }

  & > div, &.btn, & > button {
    border-width: 1px;
    width: $button-width;
    height: $button-height;
    padding: 0;
    line-height: $button-size;
    border-radius: $button-height;
    background-color: $light-steel-blue;
    border-color: $light-steel-blue;

    &:hover {
      i {
        color: $white;
      }
    }

    &.btn--primary {
      border-color: $light-steel-blue;
      background-color: $light-steel-blue;

      &:hover, &:focus {
        border-color: $cosmos;
        background-color: $cosmos;

        i {
          color: $brick-red;
        }
      }

      i {
        margin-left: 0;
        color: $smugglers-cove;
      }
    }
  }

  i {
    color: $smugglers-cove;
    font-size: 18px;
    line-height: 23px;
    margin: 0;
    width: 100%;
    height: 24px;
    top: 0;
    right: 7px;
    text-align: right;
  }
}

/**
Styles for the whole dropdown when user hovers over the actionable button
Default: remove-action, edit the actionableClass Input on ActionableFilter

The styling is BACKWARDS. When the mouse is hovering over the dropdown, the
class is added. So on hovering a remove-action dropdown, we want the hover
state to reflect the original buttons hover styling. Because when you hover
over the actionable button, you're no longer hovering over the dropdown, so we
want the entire dropdown and the actionable button to be styled the way the
actionable button should be styled.

TLDR: style :hover as the original button's hover state. Style the
.dropdown-toggle as the style you want the actionable-button hover state to
look like.

Don't forget whitelabel
**/
.actionable-dropdown {
  &.remove-action {
    .dropdown-toggle {
      color: $brick-red;
      background: $cosmos;
      border-color: $cosmos;

      &:hover {
        background: $smugglers-cove;
        border-color: $smugglers-cove;
      }
    }
  }

  &.add-action {
    .dropdown-toggle {
      color: $white !important;
      background: $smugglers-cove !important;
      border-color: $smugglers-cove !important;

      &:hover {
        color: $smugglers-cove;
        background: $solitude;
        border-color: $light-steel-blue;
      }
    }
  }
}

.disabled-filter {
  .actionable-dropdown {
    background-color: $white !important;
    border-color: $dark-solitude !important;
    color: $gun-powder !important;

    .icon-arrow-down {
      display: none;
    }
  }
}

.white-label {
  .dropdown.actionable-dropdown {
    .actionable-filter {
      & > div, &.btn {
        background-color: $dark-solitude;
        border-color: $dark-solitude;

        i {
          color: $gun-powder;
        }

        &:hover {
          background-color: $mine-shaft;
          border-color: $mine-shaft;

          i {
            color: $white;
          }
        }

        &.btn--primary {
          background-color: $dark-solitude !important;
          border-color: $dark-solitude !important;

          &:hover {
            border-color: $cosmos !important;
            background-color: $cosmos !important;

            i {
              color: $brick-red !important;
            }
          }

          i {
            margin-left: 0;
            color: $gun-powder !important;
          }
        }
      }
    }
  }

  .dropdown.actionable-dropdown {
    &.remove-action {
      .dropdown-toggle {
        color: $brick-red !important;
        background: $cosmos !important;
        border-color: $cosmos !important;

        &:hover {
          color: $white !important;
          background: $mine-shaft !important;
          border-color: $mine-shaft !important;
        }
      }
    }

    &.add-action {
      .dropdown-toggle {
        color: $white !important;
        background: $mine-shaft !important;
        border-color: $mine-shaft !important;

        &:hover {
          color: $mine-shaft;
          background: $white;
          border-color: $silver-chalice;
        }
      }
    }
  }
}
