.cr-option-list li, .cr-option {
  display: block;
  line-height: 36px;
  padding: 0 15px;
  color: $attribluetion;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: $solitude;
  }

  &:focus {
    background-color: $solitude;
    outline: none;
  }
}

.hasMaxWidth {
  .cr-option-item {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.cr-option--selected {
  background-color: $light-steel-blue;
}

.cr-option--active {
  background-color: $solitude;
}

.cr-option--disabled {
  opacity: 0.7;
  color: $silver-chalice;

  i {
    color: $silver-chalice !important;
  }

  &:hover, &:focus {
    background-color: $white;
  }
}

.cr-option--multiple {
  i {
    margin-right: 12px;
    color: var(--color-smugglers-cove);
    font-weight: normal;
  }
}

/*
  White Label
*/

.white-label {
  .cr-option {
    color: $gun-powder;
  }

  .cr-option--selected {
    color: $mine-shaft;
    background-color: $dark-solitude;

    &:hover {
      background-color: $solitude;
    }
  }
}
