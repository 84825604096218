.ng-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  cursor: default;

  &.ng-overlay-relative {
    position: absolute;
  }
}

[ng-transclude]:empty {
  display: none !important;
}
