.browser-preview {
  border: 1px solid $gun-powder;
  border-radius: 10px;
  overflow: hidden;
}

.browser-preview-title-bar {
  background-color: $dark-solitude;
  padding: 9px 15px;

  .title-bar-button {
    background-color: $generic-d8d8;
    border: 1px solid $gun-powder;
    border-radius: 50%;
    height: 13px;
    position: absolute;
    top: 4.5px;
    width: 13px;
  }
}
