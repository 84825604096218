$standout-icon-size: 52px;

.alert {
  background-color: background('warning');
  border-radius: $default-border-radius;
  color: foreground('warning');
  font-size: 15px;
  line-height: 20px;
  padding: 12px 40px 12px 40px;
  margin-bottom: calc(#{$default-margin-bottom}  * 0.5);
  position: relative;

  .alert-close {
    border-bottom: none;
    color: foreground('warning');
    position: absolute;
    right: 12px;
    top: 15px;
    @include icon(before, close-big);
  }

  a {
    text-decoration: none;
  }

  ul {
    margin: 0px;

    &.list-no-style li {
      margin-bottom: 0;
    }
  }

  h4 {
    color: foreground('warning');
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
    overflow: hidden;
    line-height: 20px;
  }

  .icon-alert-info,
  .icon-alert-success,
  .icon-alert-error,
  .icon-alert-begincall,
  .icon-alert-warning,
  .icon-alert-danger,
  .icon-alert-update {
    position: absolute;
    left: 12px;
    top: 13px;

    &.icon-alert-error {
      top: 11px;
    }
  }

  &.alert-standout {
    white-space: nowrap;
    margin-bottom: 30px;
    padding: 22px;
    border: 1px solid $dark-solitude;
    border-top: 4px solid $attribluetion;
    min-height: $standout-icon-size;
    border-radius: $default-border-radius;

    .alert-close {
      right: 24px;
      top: 24px;
      color: $silver-chalice;
    }

    .alert-standout-icon, .alert-standout-copy {
      display: inline-block;
      vertical-align: top;
    }

    .alert-standout-icon {
      margin-right: 18px;
      width: $standout-icon-size;
      height: $standout-icon-size;

      svg {
        width: $standout-icon-size;
        height: $standout-icon-size;
      }
    }

    .alert-standout-copy {
      white-space: normal;
      padding-right: 60px;
      @include box-sizing(border-box);
      max-width: 900px;
      line-height: $default-line-height;
      color: $mine-shaft;

      a:focus {
        outline-width: 1px;
        outline-offset: -1px;
      }
    }
  }

  &.alert-standout-flex {
    @include flex;
    align-items: center;
    justify-content: space-between;

    .alert-standout-copy {
      margin-right: auto;
    }
  }

  &.paragraph-width {
    max-width: 700px;
    box-sizing: border-box;
  }
}

.alert-top-wrapper {
  .alert {
    border-radius: 0;
    margin: 0;

    .alert-close {
      top: 17px;
      right: 12px;
    }

    .u-pull-right {
      margin-top: -3px;
    }
  }
}

.alert-flash-wrapper {
  width: 500px;
  position: fixed;
  top: 25px;
  left: calc(50% - 250px);
  z-index: 1060;

  .alert {
    margin-bottom: calc(#{$default-margin-bottom} * 0.25);
  }
}

.alert {
  @each $alert-type in $color-set-alerts {
    &.alert-#{$alert-type} {
      background-color: background($alert-type);
      color: foreground($alert-type);

      h4 {
        color: foreground($alert-type);
      }

      a:not(.btn), button:not(.btn).link {
        color: foreground($alert-type);
        border-bottom: solid 1px foreground($alert-type);

        &:hover, &:focus {
          color: foreground($alert-type);
          text-decoration: none;
        }
      }

      .alert-close {
        border-bottom: none !important;
        color: foreground($alert-type);
      }
    }
  }

  &.alert-standout a:not(.btn) {
    border-bottom: none;
    box-sizing: border-box;
    height: $default-line-height;

    &:hover {
      border-bottom: solid 1px $smugglers-cove;
    }
  }
}

@media screen and (max-width: 40.5em) {
  .alert-flash-wrapper {
    left: 10%;
    width: calc(100% - 100px);
  }
}

body.has-global-nav {
  .alert-flash-wrapper {
    // instead of the normal `left` positioning above, need to move flashes
    // to the left by half the width of global nav (68px):
    left: calc(50% - 250px + (#{$global-nav-collapsed-width} / 2));
  }
}

.white-label {
  .alert.alert-standout {
    border-top: 4px solid $gun-powder;
  }
}
