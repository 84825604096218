/*
  Tags List
*/

.tag-color-preview {
  border-radius: $default-border-radius-small;
  background-color: background('gray1');
  color: foreground('gray1');
  height: 20px;
  line-height: 0;
  margin: 0;
  padding: 0;
  width: 20px;

  @each $color-name in $color-set-tags {
    &.pills-pill-#{color-title($color-name)} {
      background-color: background($color-name);
      color: foreground($color-name);
      border-color: background($color-name);
    }
  }

  .icon-arrow-down {
    visibility: hidden;
  }

  &:hover, &:focus {
    .icon-arrow-down {
      visibility: visible;
    }
  }
}

.tag-color-picker {
  display: inline-block;
  padding: 8px 0 8px 8px;
  min-width: 136px;
  top: 32px;
  left: -63px;

  &::before, &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    left: calc(50% - 8px);
  }

  &::before {
    border-bottom-color: $nepal;
    top: -16px;
  }

  &::after {
    border-bottom-color: $white;
    top: -15px;
  }

  .icon-alert-success {
    font-size: 8px;
    vertical-align: middle;
  }

  .pills-pill {
    cursor: pointer;

    &.current-pill-color {
      cursor: default;
    }
  }
}

.tag-display {
  .add-tag-menu {
    float: left;

    &.verbose-add-tag {
      padding-left: 0;
      margin-left: -3px;
    }
  }

  &.open {
    .dropdown-menu {
      display: table;
      min-width: 200px;
    }
  }
}

@mixin transition-delay($delay...) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  transition-delay: $delay;
}

.tag-creator {
  .card { cursor: default !important; }

  .animate {
    @include transition(
      opacity $default-transition-ease,
      height $default-transition-ease 0.15s
    );
  }

  .animate--fade {
    opacity: 0;
    visibility: hidden;
  }

  .animate--slide { height: 0px; }

  &.open {
    .animate { @include transition-delay(0.15s, 0s); }

    .animate--fade {
      opacity: 1;
      visibility: visible;
    }

    .animate--slide { height: 167px; }
  }

  .pills {
    flex-wrap: wrap;

    .pills-pill {
      flex-grow: 1;
      flex-basis: 10%;
      height: 25px;
      text-align: center;
      @include interactive;

      .icon-check { visibility: hidden }

      &:nth-child(6n+0) { margin-right: 0; }
    }
  }

  .dropdown-view .pills-pill {
    flex-basis: 8%;
  }

  .highlight {
    margin-left: 0 !important;
    border-radius: 4px;
    padding: 2px 5px !important;
  }

  input.inputText {
    position: relative;
    background: transparent;
    color: transparent;
    caret-color: rgb(0, 0, 0);
    z-index: 2;
  }

  div.mock-input {
    position: absolute;
    display: block;
    width: 89.9%;
    top: 46px !important;
    margin: 0;
    padding: 0 8px 0 3px;
    line-height: 35px;
    white-space: pre;
    z-index: 1;

    &.input-success {
      padding-right: 36px;
    }

    .highlighter {
      overflow-x: hidden;
      padding-right: 5px;
      width: 100%;
    }
  }
}
