$blank-state-break-width: 1308px;

@media only screen and (max-width: $blank-state-break-width) {
  .blankstate {
    margin: 48px 60px;
    height: 528px;
    flex-direction: column-reverse;

    .copy {
      margin: 0px;
      padding-top: 30px;
    }

    .image {
      min-width: 528px;

      svg {
        margin: 0 auto;
      }

      img {
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (min-width: $blank-state-break-width) {
  .blankstate {
    margin: 60px 24px 92px;
    height: 288px;
    flex-direction: row;

    p {
      max-width: 100%;
    }

    .copy {
      flex: 0 0;
      padding-top: 48px;
      margin: 0px 24px 0px 0px;
    }

    .image {
      flex: 1 0 360px;

      svg-icon {
        width: 100%;
        height: 100%;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.white-label {
  .blankstate {
    svg {
      filter: grayscale(100%);
      filter: $generic-8080;
    }
  }
}

.blankstate {
  display: flex;
  display: -webkit-flex;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  justify-content: center;
  align-items: center;

  h4 {
    font-size: 32px;
    font-weight: $default-semibold-weight;
    line-height: 48px;
    margin-bottom: 6px;
    color: $mine-shaft;
  }

  .copy {
    width: 528px;
    min-width: 528px;

    div > p {
      font-size: 15px;
      line-height: 24px;
    }
  }

  .image {
    margin: 0px;
    max-width: 528px;

    svg {
      display: block;
    }

    img {
      display: block;
    }
  }

  p {
    font-size: 20px;
    line-height: 30px;
    color: $mine-shaft;
    margin-bottom: 6px;
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    width: intrinsic;
    width: -moz-max-content;
    width: -webkit-max-content;
    margin-top: 18px;

    button, nnm-new-number-button, a {
      margin: 0 auto;
    }

    &.button-wrapper-equal-action {
      flex-direction: row;

      button, nnm-new-number-button, a {
        margin-right: 15px;
      }
    }
  }

  .btn--primary {
    margin-bottom: 12px;
    margin-top: 50px;
  }
}

.upsell {
  margin-top: 42px;

  h4 {
    margin-top: 60px;
    font-size: 24px;
    margin-bottom: $default-margin-bottom;
    color: $mine-shaft;
  }

  p {
    max-width: 500px;
    line-height: $default-line-height + 6;
    margin-bottom: $default-margin-bottom;
  }

  .modal-full-screen p {
    max-width: 700px;
    line-height: $default-line-height; //Rythmic unit set at 24px
    margin-bottom: calc(#{$default-margin-bottom} * 0.5);
    margin-top: 0;
  }
}
