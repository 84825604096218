@import 'asset-store';

@function cr-asset-path($path) {
  // if fingerprinted asset exists (staging|production), update $path to be that
  $fingerprinted-path: map-get($asset-store, $path);
  @if ($fingerprinted-path) {
    $path: $fingerprinted-path;
  }

  @if function-exists(font-url) {
    @return 'assets/' + $path; // works with rails asset pipeline
  } @else {
    $base-href: '/' !default; // set to / if not already defined
    @return '#{$base-href}assets/' + $path;
  }
}

@function cr-font-url($font) {
  @if function-exists(font-url) {
    @return font-url($font); // for rails. uses asset pipeline paths
  } @else {
    @return url($font);
  }
}

@function cr-image-url($image-path) {
  @if function-exists(image-url) { // for rails. use asset pipleline path
    @return image-url(cr-asset-path($image-path));
  } @else {
    @return url(cr-asset-path($image-path));
  }
}

@function url-friendly-color($color) {
  @return '%23' + str-slice('#{$color}', 2, -1);
}

/* combine multiple scss lists into a single big one.
   only flattens 1 level, so you will need to nest this function to do a deep flattening */
@function multi($args...) {
  $output: ();
  @each $item in $args {
    $output: join($output, $item);
  }
  @return $output;
}

// takes a CR color scheme name as input, and gives you a good text/icon color to use with it
@function foreground($color-name) {
  $color: map-get($color-schemes, $color-name);
  @return map-get($color, foreground);
}

/* most (but not all) color schemes have the color istelf stored as the "background" property,
   despite seeming redundant, using background() and foreground() together in a loop can be powerful */
@function background($color-name) {
  $color: map-get($color-schemes, $color-name);
  @return map-get($color, background);
}

@function hover($color-name) {
  $color: map-get($color-schemes, $color-name);
  @return map-get($color, hover);
}

/* some colors have a different name that what we want to compile in CSS.
Specifically, persistent colors (blues that may change with branding) */
@function color-title($color-name) {
  $color: map-get($color-schemes, $color-name);
  $title: map-get($color, title);
  @if $title {
    @return $title;
  } @else {
    @return $color-name;
  }
}

// sets the color for an unchecked giant-input radio button.
@function radio-empty($radio-outline-color, $radio-fill-color) {
  @return 'data:image/svg+xml;utf8,\
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\
    <g stroke="#{url-friendly-color($radio-outline-color)}" stroke-width="1" fill="none" fill-rule="evenodd">\
        <circle fill="#{url-friendly-color($radio-fill-color)}" cx="12" cy="12" r="11" />\
      </g>\
  </svg>';
}

// sets the color for a checked giant-input radio button.
@function radio-filled($radio-fill-color) {
  @return 'data:image/svg+xml;utf8,\
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\
    <g stroke="#{url-friendly-color($radio-fill-color)}" stroke-width="1" fill="none" fill-rule="evenodd">\
      <circle fill="#{url-friendly-color($radio-fill-color)}" cx="12" cy="12" r="11" />\
    </g>\
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\
      <circle fill="white" cx="12" cy="12" r="4" />\
    </g>\
  </svg>';
}
