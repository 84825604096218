// SCSS mixins, variables, colors, themes:
@import 'foundation';
@import 'color-base-theme';

// Actual CSS definitions:
@import 'icons';
@import 'headers';
@import 'layout';
@import 'main';
@import 'forms';
@import 'ng';
@import 'grid';
@import 'ds-grid';
@import 'button';
@import 'lists';
@import 'tabs';
@import 'dropdowns/index';
@import 'modals/index';
@import 'tooltips/index';
@import 'tags';
@import 'player';
@import 'volume-meter';
@import 'recorder';
@import 'alerts';
@import 'tables/index';
@import 'feature-list';
@import 'lead-status';
@import 'util';
@import 'cards/index';
@import 'conversation';
@import 'sms-conversation';
@import 'limited-text-area';
@import 'multi-select-pill-ui';
@import 'progress-bar';
@import 'upsells';
@import 'superadmin-wrapper';
@import 'cdk-overlay';
@import 'color-picker';
@import 'user-types-svg';
@import 'bulk-add-users';
@import 'browser-preview';
@import 'select';
@import 'option';
@import 'tryptic-layout';
@import 'avatar';
@import 'a11y';
@import 'key-value-data';
@import 'typography';
@import 'pendo/index';

// Tailwind styles
@tailwind base;
@tailwind components;
@tailwind utilities;
