@import 'functions';

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin box-sizing($model) {
  -webkit-box-sizing: $model;
  -moz-box-sizing: $model;
  box-sizing: $model;
}

@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}

@mixin border-right-radius($radius) {
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
  background-clip: padding-box;
}

@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
  background-clip: padding-box;
}

@mixin border-left-radius($radius) {
  border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}

@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  -o-transform: $transforms;
  transform: $transforms;
}

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin text-shadow($shadow...) {
  -webkit-text-shadow: $shadow;
  -moz-text-shadow: $shadow;
  text-shadow: $shadow;
}

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin transform($transform...) {
  -webkit-transform: $transform;
  -ms-transform: $transform;
  transform: $transform;
}

@mixin user-select-none {
  -webkit-user-select: none;
  user-select: none;
}

@mixin flex {
  display: flex !important;
  display: -moz-flex !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
}

@mixin inline-flex {
  display: inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
}

@mixin flex-shorthand($values...) {
  // webkit-box and moz-box only support a single flex grow value
  // luckily, all our supported browsers should support flex as a whole now
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin appearance($appearance...) {
  appearance: $appearance;
  -moz-appearance: $appearance;
  -webkit-appearance: $appearance;
}

@mixin clearfix {
  &::before, &::after {
    display: table;
    content: '';
    line-height: 0;
  }

  &::after {
    clear: both;
  }
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin z-depth($level: 2) {
  $shadow: '';
  @if $level == 2 {
    $shadow: 0 3px 9px rgba($black--persistent, 0.4), 0 -1px 4px rgba($black--persistent, 0.2);
  } @else if $level == 3 {
    $shadow: 0 7px 20px rgba($black--persistent, 0.3), 0 -2px 6px rgba($black--persistent, 0.23);
  } @else {
    $shadow: 0 1px 3px rgba($black--persistent, 0.12), 0 1px 2px rgba($black--persistent, 0.15);
  }

  /* stylelint-disable order/order */
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
  /* stylelint-enable order/order */
}

@mixin panel-with-depth {
  border-radius: $default-border-radius $default-border-radius 0 0;
  @include box-shadow(0 5px 20px 0 rgba($black--persistent, .15));
  @include box-sizing(border-box);
  background: $white;
  border: 1px solid $dark-solitude;
}

@mixin rhythm($size: $default-font-size, $height: $default-line-height) {
  font-size: $size;
  line-height: $height;
}

@mixin icon($position: before, $icon: false, $styles: true) {
  @if $position == both {
    $position: 'before, &::after';
  }

  &::#{$position} {
    @if $icon {
      content: '#{map-get($icons, $icon)}';
    }

    @if $styles {
      position: relative;
      display: inline-block;
      font-family: 'callrail';
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    @content;
  }
}

@mixin background-cover {
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
}

@mixin interactive {
  cursor: pointer;
  transition: transform $default-transition-ease, box-shadow $default-transition-ease;

  &:hover {
    transform: scale(1.03);
    text-decoration: none;
    box-shadow: $default-hover-shadow;
  }
}

@mixin interactive-disabled {
  background-color: $dark-solitude;
  border-color: $dark-solitude;
  color: $gun-powder;
  cursor: not-allowed;
}

// This is a convenience mixin to disable 'interactive' cards' visual effects
// when 'interactive-disabled' (above) is not currently usable (w/out refactoring)
// (see .card-list classes)
@mixin prevent_visual_interaction($color) {
  color: $color;
  cursor: not-allowed;

  &:hover {
    color: $color;
    box-shadow: initial;
    text-decoration: none;
    transform: initial;
  }
}

// This is useful way to temporarily hide content that you want the user to be
// able to tab to. The companion undo-visually-hidden mixin may be used to help
// restore visibility.
@mixin visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin undo-visually-hidden($width, $height) {
  clip: auto;
  clip-path: none;
  overflow: initial;
  position: unset;
  white-space: initial;
  width: $width;
  height: $height;
}

@mixin animate--drop-appear($visibility: 'visible', $direction: 'down') {
  display: block;
  $slide-distance: 0px;
  @if $direction == 'noop' {
    @include transition(all 1ms);
  } @else {
    @include transition(all 300ms);
    @if $direction == 'up' {
      $slide-distance: 10px;
    }
    @if $direction == 'down' {
      $slide-distance: -10px;
    }
  }

  @if $visibility == 'visible' {
    @include transform(translate3d(0, 0, 0) scale(1));
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  } @else {
    @include transform(translate3d(0, $slide-distance, 0) scale(0.97));
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

@mixin callrail-font-face() {
  font-weight: normal;
  font-style: normal;
  font-family: 'callrail';
  src: cr-font-url(cr-asset-path('fonts/callrail.woff2')) format('woff2'),
    cr-font-url(cr-asset-path('fonts/callrail.woff')) format('woff'),
    cr-font-url(cr-asset-path('fonts/callrail.ttf')) format('truetype'),
    cr-font-url(cr-asset-path('fonts/callrail.svg#callrail')) format('svg');
}

@mixin default-outline {
  outline: $default-outline-width solid $default-outline-color;
  transition: none;

  &::-moz-focus-inner { // Hide Firefox's dotted focus box
    border: 0;
  }
}

// Hide the default outline and provide your own. If the SCSS you're passing in
// doesn't provide an alternate outline, you're probably misusing this function
// and your styling will break in the future.
// Good arguments might include `box-shadow: ...` or `&::after { border: ... }`
//
// Example usage:
//
//    &:hover {
//      @include alternate-outline {
//        box-shadow: 0 0 0 3px $white, 0 0 6px 5px $attribluetion;
//      }
//    }
@mixin alternate-outline {
  outline: none;
  transition: none;

  &::-moz-focus-inner { // Hide Firefox's dotted focus box
    border: 0;
  }

  @content;
}

@mixin radio-empty-background($radio-outline-color, $radio-fill-color) {
  input[type="radio"]:not(:checked) + label::before {
    #{background-image}: url(radio-empty($radio-outline-color, $radio-fill-color));
  }
}

@mixin radio-filled-background($radio-fill-color) {
  input[type="radio"]:checked + label::before {
    #{background-image}: url(radio-filled($radio-fill-color));
  }
}

@mixin radio-focus-shadow($shadow-color) {
  input[type="radio"]:focus + label::before {
    outline: none;
    border-radius: 50%;
    @include box-shadow(0 0 3px 1px $shadow-color);
  }
}

@mixin link-bold($color) {
  box-shadow: 0 3px 0 0 $color;
  cursor: pointer;
}

@mixin link-double($color) {
  border-bottom: double $color;
  cursor: pointer;
}

@mixin icon-circle-solid($color) {
  // this can be overriden at component level css if needed, but this is the standard font size for this dot.
  font-size: 20px;
  // again, this is a default (looks best next to an h3 tag), but can be adjusted at the component level
  top: 0.12em; // centers the circle a little bit better (lowers it a touch)
  position: relative;

  &::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 1em;
    background-color: $color;
  }
}

@mixin line-clamp($lines) {
  -webkit-line-clamp: $lines;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@mixin u-center-horizontally {
  margin: 0 auto;
  display: block !important;
}

@mixin pill-button {
  border: 1px solid $light-steel-blue;
  background-color: $solitude;
  color: $attribluetion;
  border-radius: $default-line-height;
  font-size: 14px;
  vertical-align: middle;
  max-height: $default-line-height;
  box-sizing: border-box;
  @include transition($default-transition-ease);
  max-width: 100%;
}
