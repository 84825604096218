.tryptic-layout {
  @include flex;
  height: 100%;
  flex-direction: column;

  .tryptic-wrapper {
    @include flex;
    flex: 1;
    padding: 0 24px; // 24px is the overall side padding

    .tryptic-mid {
      flex: 1;
      padding: 0 24px; // 24px is the gutter
      @include box-sizing(border-box);
    }

    .tryptic-left, .tryptic-right {
      min-width: 0; // https://stackoverflow.com/a/38224283/3123370
      flex: 0 0 312px; // 312px is the size of the fixed width columns
    }

    .tryptic-flow--column {
      @include flex;
      flex-flow: column;
    }
  }

  &.tryptic-layout--wide-sides .tryptic-wrapper {
    padding: 0 60px; // larger overall side padding
  }
}
