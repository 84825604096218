// looking for the the responsive, non-table version of this class (.key-value-data)?
// try `/styles/_key-value-data.scss`

.key-value-table {
  margin-bottom: $default-margin-bottom;

  tr {
    vertical-align: top;
  }

  tr td {
    padding: 6px 0;
    line-height: 18px;

    &:first-child {
      width: 235px;
      color: $gun-powder;
      font-weight: $default-normal-weight;
    }

    &:last-child {
      max-width: 265px;
      word-break: break-word;
      color: $mine-shaft;
      font-weight: $default-semibold-weight;
    }
  }

  &.timeline-update tr td {
    &:first-child {
      width: 200px;
    }
  }

  &.key-value-table--small {
    tr td {
      &:first-child {
        width: 220px;
      }
    }
  }

  &.weight-reversed {
    tr td {
      &:first-child {
        color: $mine-shaft;
        font-weight: $default-semibold-weight;
      }

      &:last-child {
        color: $gun-powder;
        font-weight: $default-normal-weight;
      }
    }
  }

  &.no-max-width {
    tr td:last-child {
      max-width: none;
    }
  }

  &.condensed {
    margin-bottom: 0px;

    tr td {
      padding: 0px 10px 0 0;

      &:first-child {
        width: 90px;
      }
    }

    &.spacing-10 tr td {
      padding-bottom: 10px;
    }
  }

  &.u-background-color-white {
    td, tr {
      border-color: $white !important;
      background-color: $white !important;
    }
  }
}

.key-value-table.highlighted-keys {
  // Border radius
  tr:last-child td {
    &:first-child {
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-bottom-right-radius: 4px;
      border-bottom: 1px solid $dark-solitude;
    }
  }

  tr:first-child th {
    &:first-child {
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
    }
  }

  // Header row
  thead tr th {
    background-color: $dark-solitude;
    padding: 12px;
    text-align: left;

    h4 {
      color: $mine-shaft;
      margin-bottom: 0;
    }
  }

  // Body rows
  tbody tr {
    td {
      padding: 6px 12px;

      &:first-child {
        width: 126px;
        min-width: 126px;
        background-color: $attribluetion;
        color: $white;
        opacity: 0.75;
      }

      &:last-child {
        max-width: 326px;
        border-right: 1px solid $dark-solitude;
      }
    }

    &:hover td {
      &:first-child {
        opacity: 1;
      }

      &:last-child {
        background-color: $solitude;
      }
    }
  }

  &.no-max-width {
    width: 100%;

    tr td:last-child {
      max-width: none;
      width: 100%;
    }
  }
}

/*
  White Label
*/

.white-label {
  .key-value-table.highlighted-keys tbody tr td:first-child {
    background-color: $silver-chalice;
  }
}
