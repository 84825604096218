.volume-meter {
  .volume-peg {
    width: 6px;
    height: 12px;
    border-radius: 7px;
    margin: 0px 5px;
    background: $legacy-white-blue;

    &.volume-peg-active {
      background: $legacy-dark-blue;
    }
  }
}
