.lead-status-wrapper.icon {
  width: 100%;
}

.lead-status {
  position: relative;
  display: inline-block;
  // line-height is part of .tooltip. Without this specified, the height shifts around slightly
  // when the buttons are clicked and the tooltip goes on and off.
  line-height: normal;

  .lead-status-icon-mode {
    display: inline-block;
  }

  &.event-is-lead {
    i.lead-icon, span.lead-status-copy-label {
      color: $medium-green;
    }
  }

  &.event-is-not-lead {
    i.lead-icon, span.lead-status-copy-label {
      color: $gun-powder;
    }
  }

  &.anonymous, &.not-scored {
    i.lead-icon:not(.icon-plus-sign)::before {
      color: $gun-powder;
      opacity: 0.5;
    }

    i.lead-icon.icon-plus-sign::before {
      color: $silver-chalice;
    }
  }

  &.not-scored .lead-status-label-mode i.lead-icon, &.anonymous .lead-status-label-mode i.lead-icon {
    display: none;
  }

  &.person-is-lead i.lead-icon, &.person-is-lead span.lead-status-copy-label {
    color: $medium-green;
  }

  &.person-is-lead i.lead-icon {
    color: rgba($medium-green, 0.5);
  }

  &.not-scored {
    .lead-status-icon-mode {
      width: 100%;
      display: inline-block;
    }
  }

  // Icon settings
  i.lead-button {
    color: $silver;
    width: 20px;
    cursor: pointer;
    position: relative;
  }

  i.lead-button-left {
    &:hover::before, &.is-active::before {
      content: "#{map-get($icons, good-lead)}";
      color: $medium-green;
      left: -1px;
    }
  }

  i.lead-button-right {
    &:hover::before, &.is-active::before {
      content: "#{map-get($icons, not-good-lead)}";
      color: $gun-powder;
      left: -1px;
    }
  }

  // Text settings
  .lead-status-copy-label {
    margin-left: 8px;
  }

  .lead-button-right {
    margin-left: 10px;
  }

  .auto-scored-label {
    font-weight: normal;
    margin-left: 8px;
  }

  .qualified-lead-flex {
    display: flex;

    .auto-scored-label {
      margin-left: 0;
    }
  }

  .lead-status-tooltip-content {
    line-height: normal;
    min-width: 100px;
    margin-left: -10px;
    position: absolute;
    text-align: center;
    top: 9px;

    &::after {
      right: -5px;
      @include calc(top, '50% - 5px');
    }
  }
}
