html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-size: $default-font-size;
  font-family: $default-font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: $default-line-height;
  color: $default-font-color;
}

a, .link {
  color: $attribluetion--persistent;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;

  &.muted-link {
    color: $silver;
  }

  &:focus {
    @include default-outline;
    outline-offset: $default-outline-offset;
  }

  &:active, &:hover, &.muted-link:active, &.muted-link:hover {
    outline: none;
    color: $smugglers-cove--persistent;
    text-decoration: underline;
  }

  [class^='icon-'], [class*=' icon-'], &.btn, &.dropdown-toggle {
    &:active, &:hover, &.muted-link:active, &.muted-link:hover {
      text-decoration: none;
    }
  }
}

p {
  max-width: 700px;
  line-height: $default-line-height; //Rythmic unit set at 24px
  margin-bottom: calc(#{$default-margin-bottom} * 0.5);
  margin-top: 0;
}

b, strong {
  font-weight: $default-semibold-weight;
}

small {
  font-size: $default-small-font-size;
}

pre, xmp, plaintext, listing, ul, menu, dir {
  margin: 0;
}

xmp, pre, .code-snippet {
  white-space: pre-wrap;
  tab-size: 2;
  display: block;
  border-radius: $default-border-radius;
  @include box-sizing(border-box);
  padding: 12px;
  margin-bottom: calc(#{$default-margin-bottom} * 0.5);
  border: solid 1px $dark-solitude !important;
  background-color: $solitude !important;
  font-size: 15px;
  word-break: break-all;
  text-indent: initial;
}

hr {
  margin: 11px 0 12px 0;
  border: none;
  color: $dark-solitude;
  background-color: $dark-solitude;
  height: 1px;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

// Scrollbars
.scroll::-webkit-scrollbar-track {
  background: none;
}

.scroll::-webkit-scrollbar-thumb {
  background: rgba($generic-ccc, 0.8);
  border-radius: 0;
}

.scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-left: 0;
  background: rgba($generic-ccc, 0.2);
}
