.loading {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar {
  animation-name: waveformLoader_grow;
  transform-origin: center;
  animation-duration: 2000ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.bar_1 {
  animation-delay: 200ms;
}

.bar_2 {
  animation-delay: 400ms;
}

.bar_3 {
  animation-delay: 600ms;
}

.bar_4 {
  animation-delay: 800ms;
}

.bar_5 {
  animation-delay: 1000ms;
}

@keyframes waveformLoader_grow {
  0% {
    y: 9px;
    height: 2px;
  }

  15% {
    y: 0px;
    height: 20px;
  }

  30% {
    y: 9px;
    height: 2px;
  }

  100% {
    y: 9px;
    height: 2px;
  }
}
