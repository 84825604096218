.cr-range-dropdown {
  .custom-range {
    background-color: $light-steel-blue;
    color: $smugglers-cove;
    padding: 5px 15px 10px;

    .start, .end {
      text-align: center;
      padding: 4px 6px;
      margin-bottom: 0;
      width: 97px;
      z-index: 500;

      &.ng-invalid, &.ng-invalid.ng-pristine {
        background: $white;
      }

      &.start {
        margin-right: 5px;
      }

      &.end {
        margin-left: 5px;
      }
    }

    input {
      margin-left: 0;
      margin-right: 0;
      min-width: 0;
    }

    .btn {
      margin-left: 0;
      margin-right: 0;
    }

    input.ng-invalid {
      background: $white;
      padding: 0 8px;
    }
  }

  .duration-input-seperator {
    width: 48px;
  }
}

.custom-range-calendar {
  position: absolute;
  background-color: $white;
  z-index: 1000;
  left: 0;
  border: 1px solid $nepal;
  padding: 12px;
  border-radius: $default-border-radius;
  @include box-sizing(border-box);
  @include z-depth(1);

  .calendar-input-range {
    width: 262px;

    .input-seperator {
      width: 30px;
      text-align: center;
      display: inline-block;
      line-height: 36px;
      margin-right: -3px;
    }
  }

  .calendar-input {
    margin: 0 auto;
    text-align: center;

    input[type="text"] {
      text-align: center;
      width: 115px;
      margin: 0 0 6px 0;

      &.ng-invalid, &.ng-invalid.ng-pristine {
        background: $white;
        padding-right: 0;
      }
    }
  }

  .calendar-day {
    text-align: center;
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    line-height: 1.8rem;
    color: $mine-shaft;

    &.range, &:hover, &.focused {
      background-color: $smugglers-cove;
      color: $white;
      font-weight: $default-semibold-weight;
      outline: none;
    }

    &.faded:not(.focused) {
      background-color: $light-steel-blue;
      color: $mine-shaft;
      font-weight: $default-normal-weight;
    }
  }

  // ngb classes are used by 3rd party calendar
  .ngb-dp-weekday {
    width: 1.8rem !important;
    height: 1.8rem !important;
  }

  .ngb-dp-day {
    width: 1.8rem !important;
    height: 1.8rem !important;
    outline: none;

    &.disabled .calendar-day {
      color: $silver ;
      background: none;

      &:hover {
        color: $silver;
        background: none;
      }
    }
  }

  .ngb-dp-weekday {
    color: $silver-chalice !important;
    font-weight: $default-semibold-weight !important;
    font-style: normal !important;
  }

  .ngb-dp-arrow {
    height: auto !important;
  }

  .ngb-dp-month-name {
    font-size: 16px !important;
    line-height: 24px !important;
    height: 24px !important;
    font-weight: $default-semibold-weight !important;
    color: $mine-shaft !important;
  }

  .ngb-dp-arrow-btn {
    line-height: 8px !important;
    height: 22px !important;
    font-size: 12px !important;
  }

  ngb-datepicker {
    border: none;

    &:focus, &:active {
      outline: 0;
    }
  }
}

.custom-range-calendar.cdk-date-range-calendar {
  position: relative;
  width: min-content;
}

/*
  White Label
*/

.white-label {
  .cr-range-dropdown {
    .custom-range {
      background-color: $dark-solitude;
      color: $default-font-color;
    }
  }

  .custom-range-calendar {
    border-color: $silver-chalice;

    .calendar-day {
      &.range, &:hover, &.focused {
        background-color: $mine-shaft;
      }

      &.faded:not(.focused) {
        background-color: $dark-solitude;
      }
    }
  }
}
