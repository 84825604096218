@import 'settings';

$expand-toggle-shadow: 6px 0px 0px 0px $white, 0px 0px 6px rgba(222, 222, 222, 0.44);
$display-hover-shadow: 0px 0 6px 0 $dark-solitude;
$cu-hover-shadow: 0 5px 20px 0 rgba($black--persistent, 0.15);
$card-header-line-height: 30px;
$card-title-line-height: 18px;
$addon-disabled: rgba(216, 216, 216, 0.5);

.card {
  margin-bottom: 30px;
  padding: $card-padding;
  background-color: $white;
  border: $default-border;
  border-radius: $default-border-radius;
  position: relative;

  hr.filter-bar-divider {
    margin: 24px -25px 12px -25px;
  }

  &.report-index {
    max-height: 55vh;
    display: flex;

    .row {
      display: flex;

      .col-8 {
        flex: 1 1 auto;

        .flex-cards {
          max-height: 50vh;
          overflow-y: auto;
          padding: 10px 20px 10px 20px;
          justify-content: space-between;
        }
      }

      .col-4 {
        flex: 1 1 auto;

        .sub-section {
          padding-top: 10px;
        }

        .btn--primary {
          align-self: flex-end;
        }
      }
    }
  }

  .card-title {
    margin: (-$card-padding) (-$card-padding) ($card-padding);
    padding: calc(#{$card-padding} * 0.5) $card-padding;
  }

  &.selected-card {
    border: solid 1px $steel-blue;
    background-color: $solitude;

    &::after {
      content: '';
      background-size: 44px 44px;
      width: 44px;
      height: 44px;
      position: absolute;
      top: 0;
      right: 0;
      background-image: cr-image-url('selected-earmark@2x.png');
    }
  }

  &.superadmin {
    &::after {
      background-image: cr-image-url('sa-selected-earmark@2x.png');
    }
  }

  .upsell {
    margin-top: auto;

    h4 {
      margin-top: 0px;
    }
  }

  &.card-standout {
    white-space: nowrap;
    padding: 22px;
    border-top: 5px solid $nail-salon;
    text-align: center;
    border-radius: $default-border-radius;

    .card-standout-header {
      color: $nail-salon;
      margin-bottom: 6px;
    }

    .card-standout-details {
      font-size: 15px;
      color: $gun-powder;
    }

    .icons {
      padding-top: 10px;

      .tooltip.tooltip-small {
        z-index: 999;
      }

      .tooltip.tooltip-dark.tooltip-bottom-center::before {
        margin-bottom: 5px;
      }

      .tooltip.tooltip-small.tooltip-bottom-center::after {
        left: calc(50% - 50px);
        width: 55px;
      }

      .btn.btn-mini {
        padding: 0px;
      }
    }

    .card-standout-details {
      font-size: 15px;
      color: $gun-powder;
    }
  }
}

/***********
Card Title
***********/

.card-title {
  border-bottom: $default-border;
  font-size: 14px;
  line-height: $card-title-line-height;
}

/***********
Contact card
***********/

.card.card-contact {
  .card-info, .card-img, .card-icon {
    display: inline-block;
  }

  .card-info {
    width: calc(75% - 25px);
    margin-left: 20px;
    vertical-align: middle;

    i {
      font-size: 18px;
      margin-left: 5px;
    }

    a {
      word-break: break-word;
    }
  }

  .card-img {
    width: 25%;
    max-width: 85px;
  }

  .card-icon {
    vertical-align: middle;
    text-align: center;
    border-radius: 50%;
    line-height: 44px;
    width: 44px;
  }

  .card-close {
    float: right;
    font-size: 24px;
    color: $silver-chalice;
    text-decoration: none;
    @include icon(before, alert-close);
  }
}

/***********
Display Card
***********/

.card.display-card {
  margin: 0;

  .display-card-container {
    @include flex;
    align-items: center;
    height: 100%;
  }

  .display-card-icon,
  .display-card-img {
    padding-left: 5px;
  }

  .display-card-img img {
    width: 52px;
    max-width: initial;
  }

  .display-card-icon {
    color: $attribluetion;

    i {
      font-size: 46px;
    }
  }

  .display-card-body {
    padding-left: 24px;

    .display-card-title {
      margin: 0;
      line-height: $default-line-height;
    }

    .display-card-info {
      margin-top: 5px;
    }
  }
}

// tailwind's sm:tw-col-span breakpoint is 767px
@media (max-width: 767px) {
  .card.display-card {
    margin-bottom: $default-margin-bottom;
  }
}

/***********
Checkmark Card
***********/

.card-checkmark {
  font-size: 14px;
  color: $steel-blue;
  cursor: pointer;

  .card-img {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: 3px;
  }

  &:hover {
    background-color: $solitude;
    @include box-shadow($display-hover-shadow);
    @include transform(150ms ease-in-out 0);
    transform: scale(1.04);
  }
}

.cu-card-display {
  border: none;
  font-size: 14px;
  font-weight: bold;
  color: $attribluetion;
  cursor: pointer;
  width: 180px;
  height: 120px;

  &:hover,
  &.cu-card-display-hover {
    color: $smugglers-cove;
    border-radius: $default-border-radius;
    @include box-shadow($cu-hover-shadow);
  }

  p {
    margin: 15px 15px;
  }
}

/***********
Container Card
***********/
.card-container {
  background-color: $white;
  border: $default-border;
  margin-bottom: 30px;
  position: relative;
  border-radius: $default-border-radius;

  .card-header {
    padding: calc(#{$card-padding} * 0.5) $card-padding;
    border-bottom: $default-border;
    line-height: $card-header-line-height;

    &.card-header-without-border {
      border-bottom: none;
    }

    &.card-header--with-top-border {
      border-top: $default-border;
    }

    &.card-header--collapsed {
      @include icon(before, arrow-right, true);
    }

    &.card-header--expanded {
      @include icon(before, arrow-down, true);
    }

    &.card-header--collapsed, &.card-header--expanded {
      cursor: pointer;

      &::before {
        top: 5px;
        margin-right: 10px;
        font-size: 20px;
        color: $smugglers-cove;
      }
    }

    &::before,
    &::after {
      display: table;
      content: "";
      line-height: 0;
    }

    &::after {
      clear: both;
    }

    &.card-header--colored {
      background-color: $attribluetion;
      border-color: $attribluetion;
      color: $white;

      h1, h2, h3, h4, h6 {
        // meant to be a contrast to attribluetion. White label styles may or may not play well here
        color: $white;
      }
    }

    h1, h2, h3, h4, h6 {
      line-height: inherit;
      margin-bottom: 0;
    }

    .btn:not(.btn-mini) {
      line-height: calc(#{$card-header-line-height} - 2px);  // 2px for border top/bottom
      margin-bottom: 0px;
    }

    &.card-header--small-text {
      letter-spacing: 0.08em;
      text-transform: uppercase;
      font-size: $default-small-font-size;

      .btn {
        letter-spacing: initial;
        text-transform: initial;
        font-size: initial;
      }
    }
  }

  .card-title {
    margin: 0;
    padding: calc(#{$card-padding} * 0.5) $card-padding;
  }

  .card-tabs {
    font-size: 12px;
    letter-spacing: .08em;
    line-height: 18px;
    text-transform: uppercase;
    margin: 0;
    padding: calc(#{$card-padding} * 0.5) $card-padding 0 $card-padding;

    .tabs {
      margin: 0;
    }
  }

  .lowercase-card-tabs {
    margin: 0;
    padding: calc(#{$card-padding} * 0.5) $card-padding 0 $card-padding;

    .tabs {
      margin: 0;
    }
  }

  .card-body {
    padding: $card-padding;

    &.card-body-tight {
      padding: calc(#{$card-padding} * 0.5) $card-padding;
    }

    &.card-body--flex {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .card-flex-item {
        padding: $card-padding;

        &.card-flex-item--fixed {
          width: 280px;
        }

        &:not(.card-flex-item--fixed) {
          flex: 1;
        }

        &:not(:last-child) {
          border-right: solid 1px $dark-solitude;
        }
      }
    }

    &.card-body--empty {
      padding: $card-padding;
      text-align: center;

      p {
        line-height: 20px;
      }
    }

    &.card-body--with-well {
      padding-right: 0px;
      padding-bottom: 0px;
      padding-top: 0px;

      .well {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }

  .card-body-with-actions {
    .card-body {
      padding-bottom: $card-padding * 4;
    }
  }

  .with-card-padding { // Useful for absolutely positioned elements in cards
    padding: $card-padding;
  }

  .card-actions {
    position: absolute;
    padding: $card-padding;
    bottom: 0;
    left: 0;
  }

  .well {
    background-color: $solitude;
    border-left: $default-border;
    padding: $card-padding;
    margin-bottom: 0;
  }

  &.card-container--full {
    height: 100%;
    width: 100%;

    .card-body {
      @include box-sizing(border-box);
      height: 100%;
      width: 100%;
    }

    .card-body--empty {
      @include transform(translateY(-50%));
      position: relative;
      top: 50%;
      height: initial;
      width: initial;

      p {
        max-width: initial;
      }
    }

    .card-header + .card-body {
      &:not(.card-body--empty) {
        height: calc(100% - #{$card-padding} - #{$card-header-line-height} - 2px); // 2px for border top/bottom
      }
    }

    .card-title + .card-body, .card-tabs + .card-body {
      &:not(.card-body--empty) {
        height: calc(100% - #{$card-padding} - #{$card-title-line-height} - 2px); // 2px for border top/bottom
      }
    }

    .card-header + .card-body--empty {
      height: calc(50% - (1/2 * (#{$card-padding} + #{$card-header-line-height} + 2px))); // 50% - (header height / 2)
    }

    .card-title + .card-body--empty, .card-tabs + .card-body--empty {
      height: calc(50% - (1/2 * (#{$card-padding} + #{$card-title-line-height} + 2px))); // 50% - (title height / 2)
    }
  }

  &.card-container--skinny {
    margin: 0 auto;
    max-width: 390px;
    min-width: 100px;
  }

  &.card-container--flex {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    min-width: 0;

    .card-header, header {
      position: relative;
      flex: 0 0 auto;
    }

    .card-body, article {
      flex: 1 0 1px;
      overflow: hidden;
      overflow-y: auto;
      position: relative;
    }

    .card-footer, footer {
      position: relative;
      flex: 0 0 auto;
      padding: 24px;

      .btn:not(.btn--round) {
        line-height: 36px;
      }
    }

    &.auto-height {
      .card-body, article {
        flex-basis: auto;
      }
    }
  }

  &.card-container--raised {
    box-shadow: $default-hover-shadow;
  }

  &.card-container--rounded {
    border-radius: 12px;
    // default for rounded cards because it's better to not worry about applying the rounded children class
    overflow: hidden;
  }

  &.card-container--rounded-children {
    // overflow hidden being used to enforce rounded corners on children
    // with a known limitation that this means cards will not have scrollable content, it just gets cut off
    // if you need a scrollable card, implement border radius on your children manually
    overflow: hidden;
  }
}

.card-footer {
  padding: calc(#{$card-padding} * 0.5) $card-padding;
  border-top: $default-border;
  line-height: $card-header-line-height;
  display: block;
  @include clearfix;

  .card-footer--right {
    float: right;
  }

  &.card-footer-without-border {
    border-top: none;
  }

  &.card-footer-small {
    padding: calc(#{$card-padding} * 0.5) 0 0 0;
    margin: 0 0 -12px 0;
  }

  &::before,
  &::after {
    display: table;
    content: "";
    line-height: 0;
  }

  &::after {
    clear: both;
  }

  .btn:not(.btn--round) {
    line-height: calc(#{$card-header-line-height} - 2px);  // 2px for border top/bottom
    margin-bottom: 0px;
  }
}

.card-container {
  .card-footer {
    &.card-footer-small {
      padding: 0 $card-padding calc(#{$card-padding} * 0.5) 0;
      margin: -12px 0 0 0;
    }
  }
}

/*
  Card List
*/

.card-list {
  list-style: none;

  .card-flex-container {
    display: flex;
    flex-flow: row wrap;

    >.btn {
      opacity: 0;
      pointer-events: none;
      margin-bottom: calc(#{$card-padding} * 0.5);
      transition: $default-transition-ease;
    }

    .card {
      flex: 1 0 1px;
      margin-right: 0;
      transition: $default-transition-ease;

      &:hover {
        margin-right: calc(#{$card-padding} * 0.5);
      }
    }

    &:hover, &:focus-within {
      >.btn {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .card {
    display: block;
    padding: calc(#{$card-padding} * 0.5) 16px;
    margin-bottom: calc(#{$card-padding} * 0.5);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-radius: $default-border-radius;
    @include interactive;

    &.card--interactive-disabled:hover {
      cursor: default;
      box-shadow: initial;
      text-decoration: none;
      transform: initial;
    }

    &:not([class*="card--alert-"]), &.list-card--active {
      background: $solitude;
      border-color: $dark-solitude;
    }

    &.list-card--inactive {
      background: $white;
      border-color: $dark-solitude;
    }

    &.list-card--disabled, &.list-card--locked {
      cursor: not-allowed;
    }

    &.list-card--checked {
      @include icon(after, check, true);
    }

    &.list-card--locked {
      padding-right: calc(calc(#{$card-padding} * 0.5) + #{$icon-font-size} + 8px); // leaving room for lock icon, text shouldn't overlap
      @include prevent_visual_interaction($gun-powder);
      @include icon(after, lock, true);
    }

    &.list-card--disabled {
      @include prevent_visual_interaction($silver-chalice);
    }

    &.list-card--locked, &.list-card--checked {
      &::after {
        font-size: $icon-font-size;
        position: absolute;
        top: calc(#{$card-padding} * 0.5);
        right: calc(#{$card-padding} * 0.5);
      }
    }

    &.list-card--checked {
      &::after {
        font-size: 25px;
      }
    }
  }

  a.card { // overwrites the default switch to blue links for `a` tags:
    color: $mine-shaft;

    &:hover {
      color: $mine-shaft;
    }
  }
}

/*
  Raised card
*/

.card.card--raised {
  box-shadow: $default-hover-shadow;

  &:not(.card--interactive-disabled) {
    background-color: $white;
  }
}

/*
  Interactions for cards
*/

.card--interactive:not(.card--interactive-disabled) {
  @include interactive;
}

.card--interactive.card--interactive-disabled {
  @include interactive-disabled;
  @include icon(before, lock);

  &::before {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &.card--interactive-upsell {
    @include icon(before, crown);

    &::before {
      color: $report-08;
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
}

.card--with-arrow:not(.card--interactive-disabled) {
  @include icon(after, arrow-right-02, true);

  &::before {
    position: absolute;
    content: ' ';
    display: block;
    bottom: 0;
    right: 0;
    border-bottom-right-radius: $default-border-radius;
    width: 0;
    height: 0;
    border: 16px solid transparent;
    border-bottom-color: $dark-solitude;
    border-right-color: $dark-solitude;
    box-sizing: border-box;
    transition: border-color $default-transition-ease;
  }

  &::after {
    font-size: 20px;
    font-weight: $default-normal-weight;
    display: block;
    position: absolute;
    color: $silver-chalice;
    bottom: 0;
    right: 0;
    transition: color $default-transition-ease;
  }

  &:hover::before {
    border-bottom-color: $smugglers-cove;
    border-right-color: $smugglers-cove;
  }

  &:hover::after {
    color: $white;
  }
}

/*
  Alert Cards
*/

@each $color-name in $color-set-alerts {
  .card--alert-#{$color-name} {
    background-color: background($color-name);
    border-color: rgba(foreground($color-name), 0.25);

    &.card--with-arrow::after {
      color: foreground($color-name);
    }

    &.card--with-arrow::before {
      border-bottom-color: rgba(foreground($color-name), 0.25);
      border-right-color: rgba(foreground($color-name), 0.25);
    }

    &:hover {
      &.card--with-arrow::before {
        border-bottom-color: foreground($color-name);
        border-right-color: foreground($color-name);
      }
    }
  }
}

/***********
Add-ons Page
***********/

.disabled-pricing-copy {
  padding-top: 15px;
  font-size: 14px;
}

.card-disabled {
  background-color: $addon-disabled;
  border-color: $addon-disabled;
  color: $silver-chalice;

  h6 {
    color: $silver-chalice;
  }
}

.locked-icon {
  position: relative;

  i {
    color: $gun-powder;
    position: absolute;
    left: -7px;
    top: -7px;
  }
}

/*
  White Label
*/

.white-label {
  .card:focus {
    outline-color: $default-outline-color-whitelabel;
  }

  .card.selected-card {
    border: none;

    &::after {
      background-image: cr-image-url('wl-selected-earmark@2x.png');
    }
  }

  .card-checkmark {
    color: $mine-shaft;
  }

  .card--with-arrow {
    &:not([class*="card--alert-"]) {
      &:hover::before {
        border-bottom-color: $gun-powder;
        border-right-color: $gun-powder;
      }
    }
  }

  .card-container {
    .card-header {
      &.card-header--collapsed, &.card-header--expanded {
        &::before {
          color: $mine-shaft;
        }
      }
    }
  }
}
