$exp-modal-max-width: 1200px;
$exp-modal-header-margin-bottom: 30px;
$exp-modal-left-right-padding: 30px;
$exp-modal-footer-top-bottom-padding: 18px;
$exp-modal-footer-height: 73px; // 18px padding top + 18px bottom + 36px height + 1px border-top
// Add enough margin so nothing in the body gets stuck under the footer
$exp-modal-body-padding-bottom: $exp-modal-footer-height;

.modal.modal-experience {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  border-radius: 0;
  box-shadow: none;
  background-color: $solitude;
  overflow-y: auto !important;

  .modal {
    .modal-body {
      padding-bottom: $default-margin-bottom;
    }
  }

  .modal-body {
    max-width: $exp-modal-max-width;
    margin: 0 $exp-modal-left-right-padding;
    padding-bottom: $exp-modal-body-padding-bottom;
    // fake 30px margins on each side when below the max width
    width: calc(100% - #{$exp-modal-left-right-padding} - #{$exp-modal-left-right-padding});
  }

  .modal-header {
    max-width: $exp-modal-max-width;
    padding: $exp-modal-left-right-padding;
    padding-bottom: 0;
    margin-bottom: $exp-modal-header-margin-bottom;
    // fake 30px margins on each side when below the max width
    width: calc(100% - #{$exp-modal-left-right-padding} - #{$exp-modal-left-right-padding});

    h3 {
      position: relative;
      text-align: center;
    }
  }

  .basic-modal-in-experience .modal-header h3 {
    text-align: left;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: $exp-modal-footer-top-bottom-padding $exp-modal-left-right-padding;
    border-top: 1px solid $light-steel-blue;
    background-color: $white;
    position: fixed;
    left: 0;
    right: 0;
    // Hide to begin with; add .footer-showing class to show the footer
    bottom: -$exp-modal-footer-height;
    @include transition(all 300ms ease-in-out 0s);

    &.footer-showing {
      bottom: 0;
    }

    modal-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }

    modal-footer.footer-container,
    .footer-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // Set the max width here since we want the footer to always
      // be the entire length, but not the buttons
      max-width: $exp-modal-max-width;
      width: 100%;

      &.footer-container--right {
        justify-content: flex-end;
      }
    }
  }

  &.modal-experience--full-width {
    .modal-body, .modal-header {
      max-width: initial;
    }
  }
}

.progress-bar-experience-modal {
  display: block;
  margin: 24px auto;

  .cr-progress-bar.cr-progress-bar-with-label {
    height: 4px;
  }

  .cr-progress-bar-label {
    color: $gun-powder;
  }
}

.white-label {
  .modal.modal-experience {
    background-color: $solitude;
  }
}

@media (max-width: 400px) {
  .modal.modal-experience {
    .modal-body {
      margin: 0 10px;
      width: calc(100% - #{10px} - #{10px});
    }
  }
}
