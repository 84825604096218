@import 'settings';

/****************
  HTML / CSS ONLY TOOLTIPS - Does not support html markup in tooltip text
*****************/
a.tooltip-underline {
  text-decoration: none;
}

.tooltip {
  position: relative;
  cursor: pointer;
  line-height: normal;
  display: inline-block;

  &.tooltip-default-cursor, .tooltip-default-cursor {
    cursor: initial;
  }

  /****************
    General shared tooltip attributes
  *****************/
  &:hover {
    /* IE - http://sampsonblog.com/525/ie10-gotcha-animating-pseudo-elements-on-hover */
  }

  // Bubble
  &::after, .tooltip-html-after {
    position: absolute;
    text-align: left;
    line-height: $default-line-height;
    font-weight: $default-normal-weight;
    border-radius: $default-border-radius-small;
    @include box-shadow(0 2px 6px rgba($black--persistent, 0.1));
    z-index: 1000;
    display: none;
    font-size: $default-font-size;
  }

  &[data-tooltip]::after {
    content: attr(data-tooltip);
  }

  // Arrow
  &::before {
    display: none;
    position: absolute;
    z-index: 1000;
  }

  &:hover::before, &[data-tooltip]:hover::after, &:hover > .tooltip-html-after {
    display: inline-block;
  }

  &.tooltip-underline, .tooltip-underline {
    color: $attribluetion;
    border-bottom: dashed 1px $attribluetion;
    font-weight: $default-semibold-weight;
  }

  &.tooltip-underline--muted {
    border-bottom: dashed 1px $silver-chalice;
  }

  /****************
    Small
  *****************/
  &.tooltip-small {
    &::after, .tooltip-html-after {
      padding: 6px 20px;
      white-space: nowrap;
    }

    // Top
    &.tooltip-top {
      &::before {
        bottom: 89%;
        left: calc(50% - 7px);
      }

      &::after, .tooltip-html-after {
        bottom: 100%;
        margin-bottom: 12px;
        right: calc(50% - 14px);
      }
    }

    // Top Center & Top Right
    &.tooltip-top-center, &.tooltip-top-right {
      &::after {
        bottom: 100%;
        margin-bottom: 11px;
        text-align: center;
        left: calc(50% - 65px);
        width: 90px;
        white-space: normal;
      }

      &::before {
        left: calc(50% - 6px);
        bottom: 89%;
      }
    }

    // Bottom
    &.tooltip-bottom {
      &::before {
        bottom: -56%;
        left: calc(50% - 7px);
      }

      &::after, .tooltip-html-after {
        top: 100%;
        bottom: initial;
        margin-top: 10px;
        left: calc(50% - 14px);
      }
    }

    // Bottom Center & Bottom Right
    &.tooltip-bottom-center, &.tooltip-bottom-right {
      &::after {
        top: 100%;
        bottom: initial;
        margin-top: 10px;
        text-align: center;
        left: calc(50% - 65px);
        width: 90px;
        white-space: normal;
      }

      &::before {
        bottom: -56%;
        left: calc(50% - 7px);
      }
    }

    // Left
    &.tooltip-left {
      &::before {
        left: -11px;
        top: 2px;
      }

      &::after, .tooltip-html-after {
        right: 100%;
        bottom: initial;
        top: -8px;
        margin-right: 11px;
      }
    }

    // Left Center
    &.tooltip-left-center {
      &::before {
        left: -11px;
        top: 4px;
      }

      &::after, .tooltip-html-after {
        right: 100%;
        bottom: initial;
        top: -9px;
        margin-right: 11px;
      }
    }

    // Right
    &.tooltip-right {
      &::before {
        right: -10px;
        top: 2px;
      }

      &::after, .tooltip-html-after {
        left: 100%;
        top: -8px;
        bottom: initial;
        margin-left: 10px;
      }
    }

    &.tooltip-right-top {
      &::before {
        right: -20px;
      }

      &::after, .tooltip-html-after {
        margin-left: 20px;
      }
    }
  }

  /****************
    Large
  *****************/
  &.tooltip-large {
    &::after, .tooltip-html-after {
      padding: 13px $tooltip-large-padding;
      width: $tooltip-large-width;
      word-break: break-word;
      white-space: normal;
    }

    // Top && Top Center && Top Right && Top Left
    &.tooltip-top, &.tooltip-top-center, &.tooltip-top-right, &.tooltip-top-left {
      &::before {
        left: 49%;
        margin-left: -7px;
        bottom: 92%;
      }
    }

    // Top && Top Center && Top Right && Top Left
    &.tooltip-top, &.tooltip-top-center {
      &::after, .tooltip-html-after {
        bottom: 100%;
        margin-bottom: 12px;
        left: 50%;
        margin-left: calc(-#{$tooltip-large-offset});
      }
    }

    // Top Left
    &.tooltip-top-left {
      &::after, .tooltip-html-after {
        bottom: 100%;
        margin-bottom: 12px;
        left: -17px;
      }
    }

    // Top Right
    &.tooltip-top-right {
      &::after, .tooltip-html-after {
        bottom: 100%;
        margin-bottom: 12px;
        left: -$tooltip-large-width;
      }
    }

    // Bottom && Bottom Center && Bottom Right
    &.tooltip-bottom, &.tooltip-bottom-center {
      &::before {
        bottom: -10px;
        left: 49%;
        margin-left: -7px;
      }

      &::after, .tooltip-html-after {
        top: 100%;
        margin-top: 10px;
        left: 50%;
        margin-left: calc(-#{$tooltip-large-offset});
      }
    }

    // Bottom Left
    &.tooltip-bottom-left {
      &::after, .tooltip-html-after {
        top: 100%;
        margin-top: 10px;
        left: -17px;
      }
    }

    // Bottom Right
    &.tooltip-bottom-right {
      &::after, .tooltip-html-after {
        top: 100%;
        margin-top: 10px;
        left: -$tooltip-large-width;
      }
    }

    // Left
    &.tooltip-left {
      &::before {
        left: -11px;
        top: 0px;
      }

      &::after, .tooltip-html-after {
        left: -($tooltip-large-width + 45px);
        top: -50%;
      }
    }

    // Right
    &.tooltip-right {
      &::before {
        right: -11px;
        top: 0px;
      }

      &::after, .tooltip-html-after {
        right: -($tooltip-large-width + 45px);
        top: -50%;
      }
    }

    // Top Right && Bottom Right
    &.tooltip-top-right::after,
    &.tooltip-bottom-right::after {
      margin-left: -$tooltip-large-width;
    }

    // Top Center, Bottom Center
    &.tooltip-top-center::after, &.tooltip-bottom-center::after {
      text-align: center;
    }
  }

  /****************
    Dark
  *****************/
  &.tooltip-dark {
    &::after, .tooltip-html-after {
      background-color: $smugglers-cove;
      border: 1px solid $smugglers-cove;
      color: $white;
    }

    &::before {
      content: "";
      border-width: 7px;
      border-style: solid;
      border-color: rgba($black--persistent, 0);
    }

    &.tooltip-top::before, &.tooltip-top-center::before, &.tooltip-top-right::before {
      border-top-color: $smugglers-cove;
    }

    &.tooltip-bottom::before, &.tooltip-bottom-center::before, &.tooltip-bottom-right::before {
      border-bottom-color: $smugglers-cove;
    }

    &.tooltip-left::before {
      border-left-color: $smugglers-cove;
    }

    &.tooltip-right::before {
      border-right-color: $smugglers-cove;
    }
  }

  /****************
    Light
  *****************/
  &.tooltip-light {
    &::after, .tooltip-html-after {
      background-color: $white;
      border: 1px solid $light-steel-blue;
      color: $default-font-color;
    }

    &::before {
      color: $white;
      z-index: 1001;
      pointer-events: none;
      // IE 9 only - Support for arrow is tricky so we render the triangle
      // small enough to not be seen
      -ms-transform: scale(.1);
    }

    &.tooltip-top::before, &.tooltip-top-center::before, &.tooltip-top-right::before, &.tooltip-top-left::before {
      content: "▼";
      bottom: 100%;
      text-shadow: 0px 3px $light-steel-blue;
      transform: scale(1.25, .65);
    }

    &.tooltip-bottom::before, &.tooltip-bottom-center::before, &.tooltip-bottom-right::before, &.tooltip-bottom-left::before {
      content: "▲";
      bottom: -18px;
      text-shadow: 0px -2px $light-steel-blue;
      transform: scale(1.25, .65);
    }

    &.tooltip-left::before {
      content: "▶";
      text-shadow: 2px 0px $light-steel-blue;
      left: -16px;
      transform: scale(.65, 1.25);
    }

    &.tooltip-right::before {
      content: "◀";
      text-shadow: -2px 0px $light-steel-blue;
      right: -17px;
      transform: scale(.65, 1);
    }
  }

  /****************
    Hidden
  *****************/
  &.tooltip-hidden {
    > .tooltip-html-after {
      display: none;
    }

    &::before {
      display: none;
    }
  }
}

/***************
  V2 Agency Show
****************/

// Custom class for the v2 agency billing card info tool tip so it overflows the top of the card
.tooltip {
  &.tooltip-right {
    &.v2-billing {
      ul {
        list-style: none;
      }

      &::after, .tooltip-html-after {
        right: -280px;
        top: -80px;
      }
    }
  }
}

/*
  Tootip Component - Supports html markup in tooltip content
*/

.tooltip {
  .tooltip-html-after {
    cursor: initial;
  }

  .tooltip-html-after::before {
    display: inline-block;
    content: ' ';
    background: transparent;
    position: absolute;
    z-index: -1;
    cursor: default;
  }

  &.tooltip-top .tooltip-html-after::before {
    width: 100%;
    height: 12px;
    bottom: -12px;
    left: 0;
  }

  &.tooltip-right .tooltip-html-after::before {
    height: 100%;
    width: 12px;
    left: -12px;
    top: 0;
  }

  &.tooltip-bottom .tooltip-html-after::before {
    width: 100%;
    height: 12px;
    top: -12px;
    left: 0;
  }

  &.tooltip-left .tooltip-html-after::before {
    height: 100%;
    width: 12px;
    right: -12px;
    top: 0;
  }
}

/***************
  Tooltip Absolute:
  Positioned on page instead of relative to trigger content
****************/
.tooltip-overlay {
  text-align: left;
  line-height: $default-line-height;
  font-weight: $default-normal-weight;
  font-size: $default-font-size;
  white-space: nowrap;

  .tooltip-content {
    padding: 6px 20px;
    background-color: $smugglers-cove;
    border: 1px solid $smugglers-cove;
    color: $white;
    border-radius: $default-border-radius-small;
    @include box-shadow(0 2px 6px rgba($black--persistent, 0.1));
  }

  &.tooltip-large {
    word-break: break-word;
    white-space: normal;

    .tooltip-content {
      width: $tooltip-large-width;
      padding: 13px $tooltip-large-padding;
    }
  }

  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    display: block;
    width: 12px;
    height: 12px;
    background: $smugglers-cove;
    transform: rotate(45deg);
  }

  &.tooltip-top::after, &.tooltip-bottom::after {
    left: calc(50% - 6px);
  }

  &.tooltip-top::after, &.tooltip-top-right::after, &.tooltip-top-left::after {
    bottom: -6px;
  }

  &.tooltip-bottom::after, &.tooltip-bottom-left::after, &.tooltip-bottom-right::after {
    top: -6px;
    transform: rotate(225deg);
  }

  &.tooltip-left::after {
    right: -6px;
    top: calc(50% - 6px);
    transform: rotate(315deg);
  }

  &.tooltip-right::after {
    top: calc(50% - 6px);
    left: -6px;
    transform: rotate(135deg);
  }

  &.tooltip-top-left::after, &.tooltip-bottom-left::after {
    right: 12px;
  }

  &.tooltip-top-right::after, &.tooltip-bottom-right::after {
    left: 12px;
  }

  &.tooltip-light {
    .tooltip-content {
      background-color: $white;
      border: 1px solid $light-steel-blue;
      color: $default-font-color;
    }

    &::after {
      background: $white;
      border-bottom: 1px solid $light-steel-blue;
      border-right: 1px solid $light-steel-blue;
    }
  }
}

.tooltip-overlay-wrapper {
  cursor: pointer;
  display: inline-block;

  &.tooltip-underline {
    color: $attribluetion;
    border-bottom: dashed 1px $attribluetion;
    font-weight: $default-semibold-weight;
  }

  &.tooltip-default-cursor {
    cursor: initial;
  }
}

/*
  White Label
*/

.white-label {
  /****************
    HTML / CSS ONLY TOOLTIPS
  *****************/
  .tooltip {
    /****************
      Dark Whitelabel
    *****************/
    &.tooltip-dark {
      &::after, .tooltip-html-after {
        background-color: $mine-shaft;
        border: 1px solid $mine-shaft;
      }

      &.tooltip-top::before, &.tooltip-top-center::before {
        border-top-color: $mine-shaft;
      }

      &.tooltip-bottom::before, &.tooltip-bottom-center::before {
        border-bottom-color: $mine-shaft;
      }

      &.tooltip-bottom-center::before {
        border-bottom-color: $mine-shaft;
      }

      &.tooltip-left::before {
        border-left-color: $mine-shaft;
      }

      &.tooltip-right::before {
        border-right-color: $mine-shaft;
      }
    }

    /****************
      Light Whitelabel
    *****************/
    &.tooltip-light {
      &::after, .tooltip-html-after {
        border: 1px solid $gun-powder;
      }

      &.tooltip-top::before, &.tooltip-top-center::before {
        text-shadow: 0px 3px $gun-powder;
      }

      &.tooltip-bottom::before, &.tooltip-bottom-center::before {
        text-shadow: 0px -2px $gun-powder;
      }

      &.tooltip-left::before {
        text-shadow: 2px 0px $gun-powder;
      }

      &.tooltip-right::before {
        text-shadow: -2px 0px $gun-powder;
      }
    }
  }

  &.tooltip-underline, .tooltip-underline {
    border-color: $gun-powder;
    color: $gun-powder;
  }

  .tooltip-overlay {
    .tooltip-content {
      background: $mine-shaft;
      border-color: $mine-shaft;
    }

    &::after {
      background: $mine-shaft;
    }

    &.tooltip-light {
      .tooltip-content {
        background: $white;
        border-color: $light-steel-blue;
      }

      &::after {
        background: $white;
        border-top-color: transparent;
        border-left-color: transparent;
        border-bottom-color: $light-steel-blue;
        border-right-color: $light-steel-blue;
      }
    }
  }
}

.wordy-content {
  max-width: 350px;
  width: fit-content;
}
