@import './status-svg-variables';

$icon-arrow-width: 22px;
$truncate-shim: 6px;

.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990;
}

.dropdown {
  position: relative;
  display: inline-block;
  transform: translateZ(1);
  line-height: 0;
  max-width: 100%;

  &[disabled], &.disabled {
    cursor: not-allowed;
  }

  &::-moz-focus-inner { // Hide Firefox's dotted focus box
    border: 0;
  }

  &:focus {
    outline: none;

    & > .dropdown-toggle {
      &.btn {
        color: $white;
        border-color: $attribluetion;
      }

      &.btn--primary {
        color: $white;
        background-color: $attribluetion;
      }
    }
  }

  &.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 5px;
  }

  &.open > .dropdown-menu {
    display: block;
  }

  &.open > .transclusion-dropdown-menu > dropdown-menu > .dropdown-menu {
    display: block;
  }

  &.open {
    .dropdown-label-additional-items {
      color: inherit !important;
    }
  }

  &.open .dropdown-toggle {
    &.btn, &.btn--primary {
      color: $white;
      background-color: $smugglers-cove;
      border-color: $smugglers-cove;
      @include transition(none); // allow for undetectable changes in size
    }

    &.pill-dropdown {
      background-color: $smugglers-cove;
      border-color: $smugglers-cove;
      color: $white;

      .dropdown-label-additional-items {
        color: $white;
      }
    }

    .icon-arrow-down {
      @include transform(rotateX(180deg));
      transform: translateZ(0);
    }
  }

  &.full-width-dropdown {
    .dropdown-menu {
      width: calc(100% - 2px);
    }
  }

  &.dropdown-as-block {
    display: block;

    &::after {
      display: table;
      clear: both;
      content: '';
    }

    a.dropdown-toggle,
    button.dropdown-toggle {
      width: 100%;
    }

    &.open {
      .dropdown-menu {
        width: 100%;
        margin-right: 2px;
        @include box-sizing(border-box);

        .dropdown-menu-with-search-results {
          max-width: 100%;
        }
      }

      .input-search input {
        max-width: 100%;
      }
    }
  }

  &.dropdown-aligned-right, &.dropdown-aligned-left {
    .dropdown-menu {
      min-width: 160px;
      width: auto;
      max-width: 250px;
      border-top: 1px solid $nepal;
    }
  }

  &.dropdown-aligned-right .dropdown-menu {
    right: 0;
  }

  &.open.dropdown-aligned-right {
    &.open .dropdown-toggle.btn:not(.btn--tertiary)::after {
      left: 0;
    }
  }

  &.open.dropdown-aligned-left {
    .dropdown-menu {
      left: 0;

      &.dropdown-filters-list {
        min-width: 360px;
      }
    }

    &.open .dropdown-toggle.btn:not(.btn--tertiary)::after {
      // This creates a 'bottom border' when the dropdown menu is larger than the toggle
      right: 0;
    }
  }

  .status-available::before, .status-unavailable::before, .status-offline::before, .status-online::before {
    content: '';
    display: inline-block;
    position: relative;
    width: 11px;
    height: 10px;
    margin-right: 5px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .status-available::before, .status-online::before {
    background-image: url($icon-online);
  }

  .status-offline::before {
    background-image: url($icon-offline);
  }

  .status-unavailable::before {
    background-image: url($icon-busy);
  }

  button[disabled] {
    .status-online::before, .status-available::before, .status-unavailable::before, .status-offline::before {
      background-image: url($icon-disabled);
    }
  }
}

a.dropdown-toggle,
button.dropdown-toggle {
  background: none;
  border-width: 0;
  cursor: pointer;
  color: $attribluetion;
  font-size: $default-font-size;
  font-weight: $default-normal-weight;
  font-family: inherit;
  outline: 0;
  text-decoration: none;
  padding: 0;

  .contain-text {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
    display: inline-block;
    flex: 1 1 auto;
    white-space: nowrap;
  }
}

.dropdown-toggle {
  &.btn {
    text-align: left;
    border-width: 1px;
    font-size: $default-font-size;
    padding: 0 12px 0 16px;
    max-width: 100%;
    display: inline-flex;

    &.u-hide {
      display: none;
    }

    span {
      flex-grow: 1;
    }

    &.btn--tertiary:hover span {
      text-decoration: none;
    }

    .icon-arrow-down {
      float: right;
      line-height: 34px;
      @include transform(rotateX(0deg));
    }
  }

  &.btn-mini {
    padding: 0 10px;

    .icon-arrow-down {
      font-size: $default-font-size;
      line-height: 28px;
      margin-left: 5px;
    }
  }

  &.btn--tertiary, &.inline-dropdown {
    text-decoration: none;

    & + .dropdown-menu {
      border-top: 1px solid $nepal;
    }

    &:hover {
      text-decoration: none;

      span {
        text-decoration: underline;
      }
    }
  }

  &.inline-dropdown.dropdown-toggle, &.pill-dropdown.dropdown-toggle {
    float: none;

    &:focus {
      @include default-outline;
      outline-offset: 3px;
    }
  }

  &.pill-dropdown {
    @include pill-button;
    display: inline-flex;
    line-height: $default-line-height;
    padding: 0 8px 0 calc(#{$default-line-height} * 0.5);

    &.dropdown-title {
      max-height: 24px;
      display: inline-block;
      vertical-align: text-bottom;
      line-height: $default-line-height !important;
    }

    &.pill-dropdown-pull-right {
      float: right !important
    }

    .icon-arrow-down {
      line-height: $default-line-height;
      margin-left: 4px;
    }

    &:not([disabled]) {
      &:hover, &:focus, &:active {
        background-color: $smugglers-cove;
        border-color: $smugglers-cove;
        color: $white;
      }
    }

    &[disabled] {
      color: $silver-chalice;
      border-color: $dark-solitude;
      cursor: not-allowed;
    }

    &:focus {
      @include default-outline;
      outline-offset: $default-outline-offset;
    }

    &::-moz-focus-inner { // Hide Firefox's dotted focus box
      border: 0;
    }
  }

  .u-truncate {
    display: inline-block;
    max-width: calc(100% - 28px);
    float: left;
  }

  .icon-arrow-down {
    margin-left: 8px;
    margin-right: 0px;
    @include transform(rotateX(0deg));
  }

  &.hide-arrow-down {
    .icon-arrow-down {
      display: none;
    }
  }
}

// Regarding tertiary dropdown links with `btn--tertiary-aligned` inside a <td>:
//
// What to do with this tail case? We remove padding, which means we don't want
// to change the background-color, and text-underline can look weird when there's
// multiple selections or just a "--" as the content. For now we're *only*
// changing text-color on hover.
//
// (also, there's a similar definition for WL at the end of this file.)

td .dropdown-toggle.btn.btn--tertiary.btn--tertiary-aligned {
  &:active, &:hover, &:focus {
    background-color: transparent;
    color: $smugglers-cove;
    text-decoration: none;
  }
}

.dropdown-menu, .datepicker.dropdown-menu {
  display: none;
  position: absolute;
  z-index: 1000;
  min-width: 160px;
  padding: 6px 0;
  list-style-type: none;
  background-color: $white;
  border: 1px solid $nepal;
  @include box-shadow($default-shadow);
  border-radius: $default-border-radius;
  @include rhythm;
  margin-top: 5px;

  ul {
    list-style-type: none;
    padding: 0;
  }

  &.force-right-alignment {
    right: 0;
    left: auto;
  }

  &.separate-last-item {
    padding-bottom: 0;

    li:last-child {
      border-top: $default-border;
      margin-top: 5px;

      a {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

  &.account-trial-status {
    padding-top: 0px;
  }

  .dropdown-menu-items-wrapper {
    max-height: 188px;
    overflow: auto;
  }

  .dropdown-footer {
    padding: 8px 0px;
    border-top: $default-border;

    a:hover {
      color: $white;
    }
  }

  li {
    a.dropdown-menu-item {
      outline: none;
      display: block;
      padding: 5px 15px;
      white-space: nowrap;
      font-size: 15px;
      line-height: $default-line-height;
      color: $attribluetion;
      text-decoration: none;

      i {
        margin-right: 12px;
        color: $attribluetion;
        font-weight: normal;

        &.dropdown-i-placeholder {
          width: 21px;
          display: inline-block;
        }
      }

      &.has-secondary-content {
        height: 36px;
        position: relative;
        line-height: 18px;

        &.has-icons {
          > span {
            padding-left: 32px;
          }

          i {
            position: absolute;
            height: 20px;
            width: 20px;
            top: 12px;
            left: 15px;
          }
        }

        .secondary-content {
          font-size: 14px;
          color: $gun-powder;
        }
      }

      &:hover, &:focus, &.focused {
        background: $light-steel-blue !important;
      }

      &.selected, &.active {
        background: $light-steel-blue;
        filter: none;
        color: $attribluetion;
        text-decoration: none;

        i {
          color: $attribluetion;
        }
      }

      &.disabled, &.disabled i {
        color: $silver-chalice;
      }

      &.tooltip {
        border-bottom: none;
      }
    }

    .dropdown-menu-header-item {
      border-top: 1px solid $light-steel-blue;
      font-weight: $default-semibold-weight;
      color: $smugglers-cove;
      padding: 9px 15px 10px;
      white-space: nowrap;
      font-size: 13px;

      &.first-item {
        border-top: none;
        padding-top: 4px;
      }
    }

    .dropdown-menu-dash-item {
      padding: 0px 15px 16px;
      white-space: nowrap;

      &::after {
        content: '';
        display: block;
        background-color: $silver;
        width: 18px;
        height: 1px;
      }
    }
  }

  &.dropdown-menu-custom-content {
    padding: 15px;
  }

  &.dropdown-menu-with-controls {
    overflow: hidden;
    padding: 0;
    width: 223px;

    .dropdown-footer {
      padding: 8px;

      .dropdown-footer-btn {
        margin: 0;
        padding: 0;
        width: 100%;
      }

      .dropdown-footer-text-input {
        margin: 0;
        width: 100%;
      }
    }
  }

  .dropdown-menu-with-search-control {
    position: relative;
    border-bottom: 1px solid $light-steel-blue;
    padding: 8px;

    .input-search {
      // ensure input-search is full-width for dropdown search
      display: block;
    }
  }

  .dropdown-menu-with-search-results {
    position: relative;
    max-height: 370px;
    padding: 5px 0;
    margin: 0;
    max-width: 350px;
    overflow-y: scroll;
    overflow-x: hidden;

    li a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 20px);
    }
  }

  .dropdown-menu-with-search-no-results {
    padding: 10px;
    text-align: center;
    color: $silver;
  }
}

/*
  Multi-Select Dropdown
*/

.multi-select-dropdown {
  line-height: 0;

  .dropdown-menu {
    min-width: 160px;
    width: auto;
    display: block;

    .btn--tertiary {
      color: $attribluetion;
      font-size: $default-small-font-size;
      font-weight: $default-normal-weight;
      line-height: normal;
      margin: 8px 0 0;
      padding: 0;

      &:hover, &.selected, &:focus, &:active {
        color: $smugglers-cove;
      }
    }
  }

  .pills .pills-pill {
    margin-top: 7px;
  }

  .dropdown-toggle {
    .u-truncate {
      max-width: initial;
      float: none;

      &.dropdown-title {
        min-width: 13px;
        vertical-align: text-bottom;
      }
    }

    &.dropdown-toggle-with-label {
      display: inline-block;
      line-height: normal;
      padding-right: 44px; // toggle icon width + toggle right margin + btn right padding
      position: relative;
      padding-bottom: 2px;
      @include transition(none);

      .dropdown-title {
        line-height: $default-line-height;
      }

      .icon-arrow-down {
        position: absolute;
        top: calc(50% - 17px);
        right: 15px;
      }
    }

    &.dropdown-toggle-with-label.pill-dropdown {
      line-height: $default-line-height;
      padding-bottom: 0;
      display: inline-block;

      .dropdown-title.u-truncate {
        line-height: $default-line-height;
      }

      .icon-arrow-down {
        position: absolute;
        top: calc(50% - 12px);
      }

      .dropdown-label-additional-items {
        position: relative;
        line-height: $default-line-height;
        max-width: none;
        margin-left: 5px;
        vertical-align: text-bottom;
        display: inline-block;
        top: 1px;
      }
    }

    .dropdown-label-additional-items {
      bottom: 3px;
      display: block;
      line-height: 1;
      white-space: nowrap;
      color: $gun-powder;
    }

    &:hover, &.selected, &:focus, &.active {
      .dropdown-label-additional-items {
        color: inherit;
      }
    }

    &.btn--primary {
      .dropdown-label-additional-items {
        color: $dark-solitude;
      }
    }
  }
}

.disabled-filter {
  .dropdown-toggle.pill-dropdown {
    background-color: $white !important;
    border-color: $dark-solitude !important;
    color: $gun-powder !important;

    .icon-arrow-down {
      display: none;
    }
  }

  .dropdown-toggle.inline-dropdown {
    background-color: $white;
    border-color: $dark-solitude;
    color: $gun-powder;

    .icon-arrow-down {
      display: none;
    }
  }
}

.dropdown.loading-dropdown .dropdown-toggle,
.dropdown.loading-dropdown .dropdown-toggle:hover {
  color: $attribluetion;
  background-color: $solitude;
  border-color: $light-steel-blue;
  height: 24px;
  line-height: 24px;
  padding: 0;

  i {
    margin: 0 auto;
    font-size: 18px;
    line-height: 22px;
  }
}

.btn-group {
  .btn.btn-split-dropdown-toggle {
    border-radius: $default-border-radius-small;
    padding: 0 4px;
    border-left: 1px solid transparent;
    margin-left: -1px;
    vertical-align: middle;

    &:hover, &:focus, &.btn-active {
      border-left: 1px solid;
    }

    i {
      margin-left: 0;
      margin-right: 0;
    }

    // overwrite dropdown style specific for down arrows
    .icon-arrow-down {
      float: none;
    }

    &.btn--primary {
      border-left-color: $smugglers-cove;

      &[disabled], &.disabled, &.inactive {
        border-left-color: $silver;
      }
    }

    &.btn-danger {
      border-left-color: $brick-red;
    }
  }
}

/*
  Dropdown toggle styled as form select, instead of a button
*/
a.dropdown-toggle--form-select, button.dropdown-toggle--form-select {
  color: $gun-powder;
  border-color: $silver;

  &:hover, &:focus, &.btn-active {
    color: $gun-powder;
    border-color: $dark-solitude;
    background-color: $dark-solitude;
  }
}

/*
  Dropdown Animations
*/

.dropdown {
  &.animation--drop-appear {
    .dropdown-menu {
      @include animate--drop-appear('hidden', 'down');
    }

    &.open {
      .dropdown-menu {
        @include animate--drop-appear('visible', 'down');
      }
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .dropdown {
    &.animation--drop-appear {
      .dropdown-menu {
        @include animate--drop-appear('hidden', 'noop');
      }

      &.open {
        .dropdown-menu {
          @include animate--drop-appear('visible', 'noop');
        }
      }
    }
  }
}

/*
  White Label
*/

.white-label {
  .dropdown-toggle {
    &.inline-dropdown {
      // looks like a normal 'a' link, so is a rare case of using blue in WL:
      color: $attribluetion;
    }

    &.btn:hover, &.btn:focus, &.btn:active {
      color: $white;
      border-color: $mine-shaft;
      background-color: $mine-shaft;
    }

    &.btn--primary {
      color: $white;
    }
  }

  .dropdown {
    &.dropdown-aligned-right, &.dropdown-aligned-left {
      .dropdown-menu {
        border-top-color: $silver-chalice;
      }
    }

    &.open {
      .dropdown-toggle.btn--tertiary, .dropdown-toggle.inline-dropdown {
        & + .dropdown-menu {
          border-top-color: $silver-chalice;
        }
      }

      .dropdown-toggle.pill-dropdown {
        background-color: $mine-shaft;
        color: $white;
        border-color: $mine-shaft;
      }

      .dropdown-toggle.btn:not(.btn--tertiary)::after {
        border-bottom-color: $mine-shaft;
      }
    }

    &.open .dropdown-toggle {
      &.btn, &.btn--primary {
        color: $white;
        background-color: $mine-shaft;
      }
    }

    .dropdown-toggle.pill-dropdown,
    &.dropdown-toggle.pill-dropdown {
      color: $mine-shaft;
      background-color: $white;
      border-color: $silver-chalice;

      &:hover, &:focus, &:active {
        background-color: $mine-shaft;
        border-color: $mine-shaft;
        color: $white;

        i {
          color: $white;
        }
      }

      &:focus {
        outline-color: $default-outline-color-whitelabel;
      }
    }

    .dropdown-menu, .datepicker.dropdown-menu {
      border-color: $silver-chalice;
      @include box-shadow($default-shadow);

      li {
        .dropdown-menu-header-item {
          border-color: $dark-solitude;
          color: $mine-shaft;
        }

        a, a i {
          color: $gun-powder;

          &:hover, &:focus, &.focused {
            background: $solitude !important;
            color: $mine-shaft;

            i {
              color: $mine-shaft;
            }
          }

          &.selected, &.active {
            background-color: $dark-solitude;
            color: $default-font-color;

            i {
              color: $default-font-color;
            }
          }
        }
      }

      .dropdown-menu-with-search-control {
        border-color: $dark-solitude;

        .btn--tertiary {
          color: $gun-powder;

          &:hover, &.selected, &:focus, &.active {
            color: $mine-shaft;
          }
        }
      }
    }
  }

  .btn-group {
    &.btn--tertiary-group .btn {
      &:hover, &:focus, &.btn-group-active {
        color: $mine-shaft;
      }
    }

    .btn-group-active {
      color: $white;
      background-color: $gun-powder;
    }
  }

  .btn.dropdown-toggle {
    &.btn--primary {
      background-color: $gun-powder;
      border-color: $gun-powder;
      color: $white;

      &.btn-split-dropdown-toggle {
        border-left-color: $mine-shaft;
      }

      &[disabled], &.disabled, &.inactive {
        background-color: $silver-chalice;
        border-color: $silver-chalice;
        border-left-color: $silver;
        color: $white;
      }

      &:hover, &:active, &:focus {
        background-color: $mine-shaft;
        border-color: $mine-shaft;
      }
    }
  }

  // there's a similar definition (with note) up in non-WL:
  td .dropdown-toggle.btn.btn--tertiary.btn--tertiary-aligned {
    &:active, &:hover, &:focus {
      background-color: transparent;
      color: $mine-shaft;
    }
  }

  .dropdown.loading-dropdown .dropdown-toggle,
  .dropdown.loading-dropdown .dropdown-toggle:hover {
    color: $mine-shaft;
    background-color: $white;
    border-color: $silver-chalice;
  }
}
