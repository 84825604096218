/*
  Typography
*/

.u-fine-print {
  font-size: 14px;
  color: $gun-powder;
}

.u-fine-print-alert {
  font-size: 14px;
  color: $nail-salon;
}
