/*
  The elements that these selectors reference aren't found in any looky HTML. They are
  added to the page by Pendo when a Guide or Resource Center is triggered.

  These elements may have additional themes or guide-specific styling applied. Those
  styles can be edited by using the Pendo app interface.
*/

/* CSS VARIABLES */
:root {
  /* blues */
  --solitude: #{$solitude};
  --dark-solitude: #{$dark-solitude};
  --san-marino: #{$attribluetion};
  --dark-sanmarino: #{$attribluetion};
  --smugglers-cove: #{$smugglers-cove};

  /* grays */
  --gun-powder: #{$gun-powder};
  --mine-shaft: #{$mine-shaft};
  --silver-chalice: #{$silver-chalice};

  /* other */
  --medium-green: #{$medium-green};

  /* radii */
  --radius-default: 4px;

  /* shadows */
  --cardList-shadow: 0px 8px 18px rgba(50,50,93,0.1),0px 5px 8px rgba(0,0,0,0.007);
}

/* ------------------------------
 * -- Main Global Styling -------
 * ------------------------------ */

._pendo-guide_ {
  /* -- Overarching settings for all guides -- */
  font-family: 'Source Sans Pro', sans;
}

._pendo-guide-container_ {
  /* -- Guide border, shadow, background, etc. -- */
  border-radius: 8px;

  ._pendo-guide-content_ {
    /* -- Content area -- use for font attributes, padding, etc. */
    padding: 24px;
  }
}

/* ------------------------------
 * -- Lightbox Specific Styling -
 * ------------------------------ */

._pendo-guide-lb_ {
  /* -- Overarching settings for Lightbox -- */

  ._pendo-guide-container_ {
    /* -- Guide border, shadow, background, etc. -- */
    border: 1px solid $dark-solitude;

    ._pendo-guide-content_ {
      /* -- Content area -- use for font attributes, padding, etc. */
    }
  }
}

._pendo-backdrop_ {
  /* The backdrop displayed behind lightboxes */
  /* background: rgba(247,249,250,.8); */
  z-index: 1030 !important;
}

/* ------------------------------
 * -- Tooltip Specific Styling --
 * ------------------------------ */

._pendo-guide-tt_ {
  /* Overarching settings for tooltip */

  ._pendo-guide-container_ {
    /* -- Guide border, shadow, background, etc. -- */
    border: 1px solid $dark-solitude;

    ._pendo-guide-content_ {
      /* -- Content area -- use for font attributes, padding, etc. */
      padding: 18px;
    }
  }
}

._pendo-guide-arrow_ {
  /* -- Pointer on the edge of the tooltip -- */
}

._pendo-guide-arrow-top_ {
}

._pendo-guide-arrow-left_ {
}

._pendo-guide-arrow-right_ {
}

._pendo-guide-arrow-bottom_ {
}

._pendo-guide-arrow-border-top_ {
  border-bottom-color: $dark-solitude;
}

._pendo-guide-arrow-border-left_ {
  border-right-color: $dark-solitude;
}

._pendo-guide-arrow-border-right_ {
  border-left-color: $dark-solitude;
}

._pendo-guide-arrow-border-bottom_ {
  border-top-color: $dark-solitude;
}

/* ------------------------------
 * -- Mobile Lightbox Styling ---
 * ------------------------------ */

._pendo-guide-mobile-lb_ {
  /* Overarching settings for Mobile Lightbox */

  ._pendo-guide-container_ {
    /* -- Guide border, shadow, background, etc. -- */

    ._pendo-guide-content_ {
      /* -- Content area -- use for font attributes, padding, etc. */
    }
  }
}

/* ------------------------------
 * -- Mobile Tooltip Styling ---
 * ------------------------------ */

._pendo-guide-mobile-tt_ {
  /* Overarching settings for mobile tooltip */

  ._pendo-guide-container_ {
    /* -- Guide border, shadow, background, etc. -- */

    ._pendo-guide-content_ {
      /* -- Content area -- use for font attributes, padding, etc. */
    }
  }
}

/* ------------------------------
 * -- Launcher Styling ----------
 * ------------------------------ */

._pendo-launcher_ {
  /* Overarching settings for Launcher */

  ._pendo-guide-container_ {
    /* -- Launcher border, shadow, background, etc. -- */
    /* -- (uses tooltip styles by default) -- */
  }

  ._pendo-launcher-content_ {
    /* -- Content area -- use for font attributes, padding, etc. */
  }

  ._pendo-launcher-header_ {
    /* -- Launcher header area -- */

    img {
      /* -- Launcher header area image styles -- */
    }

    ._pendo-launcher-title_ {
      /* -- Launcher header area text styles -- */
    }

    ._pendo-launcher-search-box_ {
      /* -- Launcher search area (if enabled) -- */

      input[type='text'] {
        /* -- Launcher search area text box -- */
      }
    }
  }

  ._pendo-launcher-guide-listing_ {
    /* -- Launcher guide listing area -- */

    ._pendo-launcher-item_ {
      /* -- An individual item in the launcher guide listing -- */
    }
  }

  ._pendo-launcher-footer_ {
    /* -- Launcher footer area -- */
  }
}

._pendo-launcher-badge_ {
  /* -- Styles for the launcher badge -- */
}

/* -- Styles for launcher badge orientations -- */
._pendo-launcher-badge-bottom-right_ {
}

._pendo-launcher-badge-bottom-left_ {
}

._pendo-launcher-badge-top-right_ {
}

._pendo-launcher-badge-top-left_ {
}

/* -- Styles for launcher orientations -- */
._pendo-launcher-bottom-right_ {
}

._pendo-launcher-bottom-left_ {
}

._pendo-launcher-top-right_ {
}

._pendo-launcher-top-left_ {
}

/* ------------------------------
 * -- Poll Styling --------------
 * ------------------------------ */

._pendo-guide-container_ {
  ._pendo-poll_ {
    /* -- Poll container -- */
  }

  ._pendo-poll-question-prompt_ {
    /* -- Question text -- */
  }

  ._pendo-poll-question_ {
    /* -- Question and answer container (everything but the submit button) -- */

    label {
      /* -- Number Scale Poll positioning and styles for radio button text and inputs -- */
    }

    select {
      /* -- Dropdown styling -- */
    }

    ._pendo-poll-npsrating-choices_ {
      /* -- NPS Poll ratings container -- */

      label {
        /* -- NPS Poll individual rating - the number scale items -- */

        span {
          /* -- NPS Poll individual rating - accompanying text for number scale items -- */
        }
      }
    }
  }

  ._pendo-poll-npsreason_ {
    /* -- NPS Poll optional user-provided explanation container -- */

    ._pendo-poll-question-prompt_ {
      /* -- NPS Poll optional user-provided explanation text -- */
    }
  }
}

._pendo-guide-container_ ._pendo-poll-question_ button {
  /* -- Yes/No Poll buttons styles -- */
}

._pendo-guide-container_ ._pendo-poll-submit_ {
  /* -- Submit button styles -- */
}

._pendo-guide-container_ ._pendo-poll-message_ {
  /* -- "Success message" styles -- */
}

/* ------------------------------
 * -- Additional Global Styling -
 * ------------------------------ */

._pendo-close-guide {
  padding: 2px 2px 0 0 !important;
}

._pendo-close-guide_ {
  /* -- The dismiss guide button displayed in the corner of every guide -- */
  font-size: 32px;
  top: 8px;
  right: 16px;
}

._pendo-badge_ {
  /* -- Styles for badges -- */
  z-index: 1030;
}

#pendo-backdrop {
  z-index: 1030 !important;
  position: relative;
}

/* -----------------------------
 * -- Your Custom Classes/IDs --
 * ----------------------------- */

._pendo-step-container-styles {
  box-shadow: 0 15px 35px 0 rgba(0,0,0,.15) !important;
}

._pendo-guide-container_ button,
._pendo-step-container button {
  cursor: pointer;
  font-family: inherit;

  &._pendo-button-primaryButton, &._pendo_button_custom {
    background-color: $attribluetion !important;
    border: 1px solid $attribluetion !important;
  }
}

._pendo-step-container-styles textarea {
  max-width: none;
}

.pendo-type-label {
  font-size: 14px;
  margin-bottom: 12px;

  i {
    margin-right: 4px;
  }
}

.vertical-nav-item ._pendo-badge {
  position: relative;
  top: -4px;
}

button.pendo-block-controls {
  margin: 0;
}

button._pendo-button-primaryButton, button._pendo_button_custom {
  background-color: $attribluetion !important;
  border: 1px solid $attribluetion !important;
}

#pendo-guide-container {
  outline: none;

  p {
    max-width: none;
  }

  &:before {
    /* Modal Background when in use */
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $solitude;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    /* background-image: url('…'); – set per guide */
    /* content: ""; – set per guide to enable bg */
  }

  .steps {
    /* Tooltip Steps */
    float: right;
    color: $silver;
    font-weight: 400;
    font-size: 16px;
  }
}

.pendo-custom-edit-message {
  /* Custom Instructions for Editing a Guide */
  display: none !important;
}

._pendo-text-custom ._pendo-text-link:last-child:first-child:after {
  /* Add → to standalone links */
  content: "";
  padding-left: 4px;
  font-family: "callrail";
  font-size: 14px;
  font-weight: 600;
}

.pendo-block-controls {
  .pendo-mock-flexbox-row {
    .pendo-mock-flexbox-element {
      button {
        margin-top: 12px;
      }

      &:nth-last-of-type(3) {
        /* For buttons 1 of 3, align left */
        left: 0 !important;
      }
    }
  }
}

/* Upgrade */
.pendo-upgrade-icon {
  padding: 4px;
  background: $sunflower;
  font-size: 10px;
  top: -2px;
  margin-right: 4px;
  border-radius: 9px;
  color: $white;
}
