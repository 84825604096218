@import 'mixins';

.modal-backdrop {
  background-color: rgba($black--persistent, 0.7);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1040;

  &.modal-backdrop-level-2 {
    z-index: 1060;
  }
}

/*
  Standard Modal
*/

.modal {
  @include large-modal-header;

  background-color: $white;
  border: 1px solid $dark-solitude;
  border-radius: $default-border-radius;
  @include box-shadow(0 5px 20px 0 rgba($black--persistent, .15));
  left: 50%;
  margin-left: -280px;
  outline: none;
  position: fixed;
  top: 10%;
  width: 560px;
  z-index: 1050;

  &.modal-large {
    margin-left: -375px;
    width: 748px;
  }

  &.modal-small {
    margin-left: -240px;
    width: 480px;
  }

  &.modal-extra-small {
    width: 410px;

    .modal-title-bold {
      font-weight: 600;
      font-size: 23px;
      line-height: 28px;
      padding-bottom: 8px;
    }

    h3 {
      margin: 0px;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--color-dark-solitude);
    }

    .modal-content {
      flex: 1 1;
      overflow-y: auto;
      height: 290px;
    }

    .modal-list {
      list-style-type: none;
      margin-bottom: 24px;
      line-height: 28px;
    }
  }

  &.modal-level-2 {
    z-index: 1070;
  }

  &.modal--less-important {
    z-index: 1000;
  }

  .modal-header, .modal-detailed-header {
    border-radius: $default-border-radius $default-border-radius 0 0;
    position: relative;

    .icon-alert-close, .icon-arrow-down, .icon-close-big {
      cursor: pointer;
      position: absolute;
    }

    .icon-alert-close {
      color: $silver-chalice;
    }

    .icon-arrow-down {
      right: 32px;
    }

    // only used for truncating NNM modal company name
    .modal-title-truncated {
      line-height: 36px;
      margin: -6px auto;
      max-width: 640px;
    }
  }

  .modal-detailed-header {
    background-color: $white;
    border-bottom-color: $dark-solitude;
    margin: 0;
    padding: 7px 25px;

    .icon-alert-close {
      color: $mine-shaft;
      margin-top: -12px; // half of icon height + half of parent margin-top
      top: 50%;
      right: 25px;
    }
  }

  .modal-body {
    position: relative;
    margin: $default-margin-bottom;
  }

  .dropdown-menu.show {
    display: block;
    margin-top: 30px;
    margin-left: 35px;

    .ngb-dp-month-name {
      color: $attribluetion;
    }
  }
}

@media screen and (max-width: 626px) {
  .modal {
    width: calc(100vw - 24px);
    margin: 0;
    position: static;
  }

  .modal-container {
    display: flex;
    align-items: center; /* center children vertically */
    justify-content: center; /* center children horizontally */
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 1050;
  }
}

/*
  Setup Modal
*/

.modal-setup {
  border: 0;
  margin-left: -375px;
  top: 10px !important;
  width: 748px;

  .modal-setup-progress {
    height: 12px;
    margin: $default-margin-bottom;
  }

  .modal-setup-progress-bar-wrapper {
    background: $dark-solitude;
    border-radius: 7px;
    float: left;
    height: 10px;
    margin-top: 1px;
    overflow: hidden;
    width: 580px;
  }

  .modal-setup-progress-bar {
    background: $medium-green;
    height: 100%;
    position: relative;
    @include transition(width 0.5s);
    width: 0%;
  }

  .modal-setup-progress-copy {
    color: $gun-powder;
    float: right;
    font-size: 14px;
    line-height: 12px;
  }

  .modal-footer {
    bottom: 0;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
  }
}

/*
  Marketing Success Modal
*/

.modal-fancy {
  @include small-modal-header;

  width: 710px;
  margin-left: -355px;

  .modal-header {
    margin: 0;
    padding: calc(#{$default-margin-bottom} * 0.5) 25px 0 25px;
    background: $solitude;
    border-bottom: none;

    .icon-alert-close {
      top: 26px;
      right: 25px;
    }

    .modal-fancy-img {
      width: 660px;
      height: 196px;
      margin-top: calc(#{$default-margin-bottom} * 0.5);
    }
  }

  .modal-footer .checkbox {
    display: inline-block;
    width: auto;
    float: none;
    margin-left: 20px;
  }
}

/*
  Offset Modals
*/

.modal.offset-modal {
  @include small-modal-header;

  left: auto;
  margin: auto;
  top: auto;
  width: 320px;

  &.offset-bottom-right {
    bottom: 15px;
    right: 15px;
  }
}

/*
  Full Screen
*/
.modal-full-screen {
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 1400px;
  max-height: 100vh;
  min-height: 50vh;
  margin: 0 auto;
  top: 24px;
  left: 24px;
  right: 24px;
  bottom: 24px;
  padding: 12px 36px 36px 36px;

  .modal-header, .modal-detailed-header {
    padding: $default-margin-bottom 0;
  }

  .modal-header .icon-alert-close {
    font-size: 32px;
    right: -12px;
    top: 12px;
  }

  .modal-body {
    flex: 1;
    display: flex;
    justify-content: stretch;
    margin: 0;
    height: 100%;
    overflow-y: auto;

    & > *, .modal-flex-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }

  .modal-content {
    flex: 1 1;
    overflow-y: auto;
  }

  .modal-footer {
    flex: 0 0;
    padding-top: $default-margin-bottom;

    &::before, &::after {
      display: table;
      content: '';
      line-height: 0;
    }

    &::after {
      clear: both;
    }
  }
}

/*
  White Label
*/

.white-label {
  .modal-header {
    border-color: $gun-powder;

    .icon-alert-close {
      color: $default-font-color;
    }
  }

  .modal-header {
    .icon-alert-close {
      color: $silver;
    }
  }

  .modal-body {
    .btn--tertiary {
      &:hover, &:focus, &.btn-active {
        color: $mine-shaft;

        &[disabled], &.disabled {
          color: $silver-chalice;
          border-color: $silver-chalice;
          cursor: not-allowed;
        }
      }
    }
  }
}

/*
  Customer Port Modal
*/

.port-modal-scroller {
  .associated-port-numbers {
    height: 100px;
    overflow-y: scroll;
    width: 505px;

    &.overflow-y {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
        background-color: $solitude;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: $default-border-radius;
        background-color: $dark-solitude;
        -webkit-box-shadow: 0 0 1px rgba($white--persistent, .5);
      }
    }
  }
}
