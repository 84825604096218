@mixin small-modal-header {
  .modal-header, .modal-detailed-header {
    border-bottom: solid 1px $light-steel-blue;
    margin: calc(calc(#{$default-margin-bottom} * 0.5) - 1) 25px 0 25px;
    padding: 0;

    .icon-alert-close, .icon-arrow-down {
      color: $attribluetion;
      font-size: 20px;
      top: 16px;
    }

    .icon-alert-close {
      right: 0;
    }

    .icon-arrow-down {
      right: 24px;
    }

    h3 {
      color: $mine-shaft;
      font-size: 23px;
      line-height: 48px;
      font-weight: 600;
      margin-right: 20px;
    }
  }
}

@mixin large-modal-header {
  .modal-header, .modal-detailed-header {
    border-bottom: none;
    margin: 0;
    padding: $default-margin-bottom 25px 0;

    .icon-alert-close, .icon-arrow-down {
      color: $smugglers-cove;
    }

    .icon-close-big {
      color: $gun-powder;

      &:hover {
        color: $mine-shaft;
      }
    }

    .icon-alert-close, .icon-arrow-down, .icon-close-big {
      font-size: 24px;
      right: 30px;
      top: 25px;
    }

    h3 {
      color: $mine-shaft;
      font-size: 30px;
      line-height: 24px;
      font-weight: 400;
      margin: 0 24px 0 0;
    }
  }
}
