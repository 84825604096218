/*
  Horizontal Tabs
*/

.counter {
  position: relative;
  top: -1px;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  background: $dark-solitude;
  color: $mine-shaft;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
}

.tabs {
  position: relative;
  width: 100%;
  margin: $default-margin-bottom 0;
  padding: 0;
  list-style-type: none;
  border-bottom: $default-border;
}

.tabs-tab {
  position: relative;
  display: inline-block;
  margin: 0 50px -1px 0;
  padding-bottom: 9px;
  color: $mine-shaft;
  border-bottom: solid 3px transparent;
  width: initial;
  text-decoration: none;

  &:hover {
    color: $smugglers-cove;
    cursor: pointer;
  }

  &.tabs-tab-active {
    color: $default-font-color;
    border-bottom: solid 3px $attribluetion;
    cursor: default;

    a:focus {
      outline: 0;
    }
  }

  .tab-count {
    @extend .counter;
  }

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: inherit;
    }
  }
}

/*
  Vertical Tabs
*/

.tabs-vertical {
  list-style-type: none;
  position: relative;
  border-right: solid 1px $light-steel-blue;
  max-width: 500px;
  padding: 0;
}

.tabs-vertical-tab {
  padding: (calc(#{$default-margin-bottom} * 0.33333 )) (calc(#{$default-margin-bottom} * 0.5));
  color: $silver-chalice;
  cursor: pointer;
  border: solid 1px $white;
  border-right: none;

  .tab-count {
    @extend .counter;
  }

  a {
    display: block;
    color: inherit;
    padding: calc(#{$default-margin-bottom} * 0.5);
    margin: calc(-1 * #{$default-margin-bottom} * 0.5);
  }

  i {
    color: $attribluetion;
  }

  &:hover {
    color: $smugglers-cove;
  }

  &.tabs-vertical-tab-active {
    font-weight: $default-semibold-weight;
    background-color: $solitude;
    color: $smugglers-cove;
    cursor: default;
    border: solid 1px $light-steel-blue;
    border-right: none;
    border-radius: $default-border-radius 0 0 $default-border-radius;

    a {
      cursor: default;
    }

    i, .tab-count {
      font-weight: normal;
    }
  }
}

.tabs-vertical-content {
  position: relative;
}

.row [class*="col-"] .tabs-vertical-content {
  margin-left: 0;
  padding-left: 2.127659574468085%;

  &::after {
    left: -1px;
  }
}

/*
  White Label
*/

.white-label {
  .tabs-vertical-tab.tabs-vertical-tab-active {
    color: $default-font-color;
  }

  .tabs-tab {
    &:hover {
      color: $mine-shaft;
    }

    &.tabs-tab-active {
      border-bottom: solid 3px $mine-shaft;
    }
  }
}
