/* You can add global styles to this file, and also import other style files */
body {
  background-color: $solitude;
}

.sign-in-form {
  flex: 1 0 50%;
  align-items: center;
  justify-content: center;
  text-align: left;
  flex-flow: column nowrap;
  display: flex;
}
