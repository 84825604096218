$progress-bar-default-height: 4px;
$progress-bar-default-border-radius: 4px;
$progress-bar-default-transition: transform 300ms;
$progress-bar-white-label-background-color: $gun-powder;

.cr-progress-bar-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.cr-progress-bar {
  height: $progress-bar-default-height;
  flex: 1 1;

  &.cr-progress-bar-with-label {
    height: 10px;
  }
}

.cr-progress-bar-label {
  text-align: center;
  padding: 0 10px;
  color: $gun-powder;
}

.cr-progress-bar-inner {
  left: 0;
  height: 100%;
  top: 0;
  @include transition($progress-bar-default-transition);
  transform-origin: top left;
  width: 100%;
}

.cr-progress-bar-inner, .cr-progress-bar {
  border-radius: $progress-bar-default-border-radius;
}

/*
  Step Progress Bar
*/

.cr-step-progress-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  ul.cr-step-progress {
    list-style: none;
    padding: 0;
    align-self: center;
    font-size: 15px;
    line-height: 24px;
    width: auto;

    .cr-step-progress-list {
      display: flex;
      justify-content: center;
      text-align: left;

      li {
        padding-bottom: 10px;
        padding-right: 30px;
        @include transition(all 600ms ease 0s);

        &.active {
          font-weight: 600;
        }

        &.inactive {
          color: $mine-shaft;
          cursor: auto;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    .cr-step-progress-bar {
      height: 2px;
      position: relative;
      @include transition(all 600ms ease 0s);
      border-radius: 1px;
      background-color: $attribluetion;
      z-index: 1;

      &::after {
        content: "";
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: $attribluetion;
        position: absolute;
        right: -3px;
        top: -2px;
        z-index: 999;
      }

      &.inactive {
        position: relative;
        top: -2px;
        background-color: transparent;

        &::after {
          width: 0;
          height: 0;
        }
      }

      &.complete {
        width: 100% !important;
        background-color: $medium-green;

        &::after {
          width: 0;
          height: 0;
        }
      }
    }
  }
}

/*
  White Label
*/

.white-label {
  .cr-progress-bar-inner {
    background-color: $progress-bar-white-label-background-color;
  }

  .cr-step-progress-container ul.cr-step-progress .cr-step-progress-bar {
    background-color: $progress-bar-white-label-background-color;

    &.inactive {
      background-color: transparent;
    }

    &::after {
      background-color: $progress-bar-white-label-background-color;
    }
  }
}
