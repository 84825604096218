/*
  The elements that these selectors reference aren't found in any looky HTML. They are
  added to the page by Pendo when a Guide or Resource Center is triggered.

  These elements may have additional themes or guide-specific styling applied. Those
  styles can be edited by using the Pendo app interface (Nav > Guides > Themes).

  Why so many `!important`s throughout this file?
  Pendo inconsistently applies their own default styles to the Resource Center through
  a mix of their own external CSS stylesheet and JS-administed style atrributes. In
  terms of CSS specificity, any property added by Pendo via JS is more specific than
  any selector we could create in a stylesheet. Thus, the only option we have to
  overrule their JS-defined styles is with an `!important` here.
*/

/* --------------------------------
 * -- Resource Center Custom CSS --
 * -------------------------------- */
@keyframes RC_fadeIn {
  0% {
    margin-left: -20px;
    opacity: 0;
  }

  100% {
    margin-left: 0;
    opacity: 1;
  }
}

@keyframes RC_fadeIn_fromRight {
  0% {
    margin-left: 20px;
    opacity: 0;
  }

  100% {
    margin-left: 0;
    opacity: 1;
  }
}

#pendo-resource-center-container {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15) !important;
  width: auto !important;
  min-width: 400px;
  min-height: 400px;
  border: 1px solid $dark-solitude;
  border-radius: 4px !important;
  z-index: 1031 !important;
  animation-name: RC_fadeIn_fromRight;
  animation-duration: 250ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  transition: 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) height;
  top: 60px !important;
  right: 15px !important;
  left: auto !important;

  ._pendo-step-container-size {
    width: 100% !important;
    height: 100% !important;
  }

  &:before {
    display: none !important;
  }

  .pendo-mock-flexbox-row:has(._pendo-home-view-bubble) {
    position: absolute !important;
  }

  ._pendo-home-view-bubble {
    margin-top: -45px !important;
    margin-right: -28px !important;
  }
}

._pendo-resource-center-view-container {
  overflow: hidden !important;
  box-shadow: none !important;
  height: 100%;
}

#pendo-resource-center-module-title-container {
  // RC header
  padding: 12px 24px !important;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  border-top-width: 0 !important;

  ._pendo-simple-text {
    line-height: 32px !important;
    text-align: left !important;
    padding: 0 !important;
  }

  ._pendo-resource-center-left-caret {
    cursor: pointer;
    margin-left: 0px !important;
    top: 50% !important;
    margin-top: -16px !important;
    left: 6px !important;
    height: 32px !important;
    width: 32px !important;

    &::before {
      border-left: 2px solid $silver-chalice !important;
      border-top: 2px solid $silver-chalice !important;
      border-bottom: none !important;
      border-right: none !important;
      width: 8px;
      height: 8px;
      transform: rotate(-45deg);
      right: 11px !important;
      top: 12px !important;
    }

    &::after {
      content: none !important;
    }

    & + ._pendo-simple-text {
      flex-grow: 1;
      text-align: center !important;
    }
  }
}

._pendo-resource-center-close-button {
  position: absolute !important;
  top: calc(50% - 20px) !important;
  right: 20px !important;
}

._pendo-resource-center-home-title-text,
._pendo-resource-center-integration-module-title-text {
  line-height: 2 !important;
  padding: 0 !important;
  padding-left: 8px !important;
}

.pendo-resource-center-module-title-container + * {
  // RC body
  overflow: auto;
}

._pendo-resource-center-home-list {
  padding: 24px 24px 14px !important;
  border-color: $dark-solitude !important;
  box-sizing: border-box;
}

._pendo-resource-center-module-list-item {
  // RC list items — model Selection Cards
  padding: 12px 16px !important;
  margin-bottom: 12px;
  border: 1px solid $dark-solitude !important;
  background: $solitude;
  border-radius: var(--radius-default);
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    transform: scale(1.05);
    box-shadow: var(--cardList-shadow);
  }

  .pendo-mock-flexbox-element {
    width: 100% !important;
    position: relative !important;
  }

  ._pendo-resource-center-module-list-item .pendo-mock-flexbox-row {
    min-height: none !important;
  }

  ._pendo-resource-center-right-caret {
    display: none !important;
  }
}

._pendo-resource-center-module-list-item-title-text {
  color: $mine-shaft !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  margin-bottom: 0 !important;
}

._pendo-resource-center-module-list-item-subtitle-text {
  color: $gun-powder !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-transform: none !important;
  font-weight: normal !important;
  margin-bottom: 0 !important;
}

// Search results iframe
#_pendo-ext_ div[role="link"], ._pendo-ext-results-list-item_ {
  color: var(--smugglers-cove);
  font-size: 16px;
}

// Checklists
._pendo-resource-center-onboarding-module-guide-row {
  display: flex !important;

  .pendo-mock-flexbox-row {
    display: flex !important;
    width: auto !important;
    height: auto !important;

    &:first-of-type {
      flex-shrink: 1;
    }

    .pendo-mock-flexbox-element {
      display: flex !important;
      position: relative !important;
      left: auto !important;
    }
  }
}

._pendo-resource-center-onboarding-module-list {
  height: auto !important;
  flex-grow: 1;
  padding: 24px !important;
}

._pendo-resource-center-onboarding-module-listed-guide-link {
  padding-left: 0 !important;
}

#pendo-text-c6fcbaad:hover,
._pendo-resource-center-onboarding-module-listed-guide-link:hover ._pendo-resource-center-onboarding-module-listed-guide-text,
._pendo-resource-center-onboarding-module-listed-guide-link:hover ._pendo-resource-center-onboarding-module-listed-guide-step-complete {
  text-decoration: none !important;
  font-weight: 600 !important;
}

._pendo-resource-center-onboarding-module-listed-guide-text {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 20px !important;
  color: $gun-powder;
  margin-bottom: 4px;
}

._pendo-resource-center-onboarding-module-listed-guide-text:hover {
  font-weight: 600 !important;
}

._pendo-resource-center-onboarding-module-listed-guide-step-complete {
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  color: $silver-chalice;
}

.pendo-onboaring-guide-progress-circle {
  width: 20px !important;
  height: 20px !important;
  margin-top: 10px !important;
  margin-right: 12px !important;
}

circle.pendo-progress-circle-fill {
  stroke: $attribluetion;
}

svg.pendo-progress-circle-fill {
  stroke: $medium-green;
}

// Checklist Progress Footer
._pendo-resource-center-onboarding-module-progress-bar {
  height: auto !important;
  display: flex !important;
  padding: 24px !important;
}

._pendo-resource-center-onboarding-module-progress-bar-container,
._pendo-resource-center-onboarding-module-progress-bar-background,
._pendo-resource-center-onboarding-module-progress-bar-fill {
  height: 10px !important;
  border-radius: 5px !important;
}

._pendo-resource-center-onboarding-module-progress-bar-container {
  flex-grow: 1;
  margin-right: 12px;
}

._pendo-resource-center-onboarding-module-percent-complete {
  font-size: 12px !important;
  color: $silver-chalice;
  line-height: 10px !important;

  &:after {
    content: " Complete";
  }
}
