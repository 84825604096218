/* Scroll Tables
------------------------------------------------------- */

$table-scroll-fixed-cell-width: 115px;
$table-scroll-nav-gutter-width: 10px;
$table-scroll-left-fixed-cell-width: 150px;

.table-scroll-container {
  overflow-x: auto;
  @include box-sizing(border-box);
  margin-bottom: $default-margin-bottom;

  &.right-nav .table::after {
    display: block;
  }

  .tooltip-html-after {
    box-sizing: initial;
  }
}

.table-scroll-container .table {
  margin-bottom: 0;

  &::after {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 32px;
    right: $table-scroll-fixed-cell-width;
    @include box-shadow(inset -9px 0 6px -6px rgba($black--persistent, 0.1));
  }

  th,
  td {
    max-width: 400px;
    @include box-sizing(border-box);
  }

  .max-width-col {
    max-width: 140px;
    display: revert;
    float: none;
    width: unset;
  }

  .fixed-cell {
    position: absolute;
    top: auto;
    right: 0;
    background: $white;
    width: $table-scroll-fixed-cell-width;
  }

  .fixed-cell-overlay {
    min-width: $table-scroll-fixed-cell-width;
  }

  tr:hover, tr.active {
    td.fixed-cell {
      border-color: $light-steel-blue;
      background-color: $solitude;
    }
  }
}

.left-fixed-cell-table.table-scroll-container {
  .table tbody tr:hover td {
    background-color: $solitude;
  }

  &.left-nav {
    .left-fixed-cell-panel {
      display: block;
    }
  }

  .left-fixed-cell-panel {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 32px;
    z-index: 1;
    @include box-shadow(inset 9px 0 6px -6px rgba($black--persistent, 0.1));
  }

  .left-fixed-cell {
    z-index: 1;
    position: absolute;
    top: auto;
    left: 0;
    background: $white;
    width: $table-scroll-left-fixed-cell-width;
  }
}

.right-fixed-cell-table.table-scroll-container {
  &.hide-fixed-right-shadow .table::after {
    content: none;
  }

  &.right-nav {
    .right-fixed-cell-panel {
      display: block;
    }
  }

  .right-fixed-cell-panel {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 32px;
    z-index: 1;
    @include box-shadow(inset -9px 0 6px -6px rgba($black--persistent, 0.1));
  }
}

.table-scroll-container.no-right-fixed-cell-table {
  .table-scroll-nav {
    .btn-right {
      right: $table-scroll-nav-gutter-width;
    }
  }

  .table {
    &::after {
      right: 0;
    }
  }
}

.table-scroll-nav {
  z-index: 3;

  .btn--round {
    visibility: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
    background-color: $white;
    color: $gun-powder;
    line-height: .9;
    height: 34px;
    width: 34px;
    border: 1px solid rgba($black--persistent, 0.1);
    @include box-shadow(0 2px 8px rgba($black--persistent, 0.15));

    &:hover,
    &:active,
    &:focus {
      @include box-shadow(0 3px 10px rgba($black--persistent, 0.2));
      transform: none;
      color: $attribluetion;
    }

    i {
      font-size: $default-font-size;
      font-weight: 600;
      line-height: 34px;
    }

  }

  .btn-left {
    left: $table-scroll-nav-gutter-width;
  }

  .btn-right {
    right: $table-scroll-fixed-cell-width + $table-scroll-nav-gutter-width;
  }
}

.table-scroll-container.left-nav:hover .table-scroll-nav .btn-left,
.table-scroll-container.right-nav:hover .table-scroll-nav .btn-right {
  visibility: visible;
}

.table-scroll-container.table-no-fixed-cell {
  &.right-nav .table::after {
    display: block;
  }

  .table::after {
    content: '';
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 32px;
    right: 0;
    @include box-shadow(inset -9px 0 6px -6px rgba($black--persistent, 0.1));
    // @include box-shadow(none);
  }

  .table-scroll-nav {
    .btn-right {
      right: $table-scroll-nav-gutter-width;
    }
  }
}

.table-scroll-container .table thead th a i {
  line-height: 18px;
  margin-right: -5px;
  margin-left: -5px;
  position: relative;
  top: -1px;
}

/*
  White Label
*/

.white-label {
  .table-scroll-container .table tbody {
    tr:hover, tr.active {
      .fixed-cell {
        background-color: $solitude;
      }
    }
  }

  .table-scroll-nav .btn {
    &:hover,
    &:active,
    &:focus {
      color: inherit;
    }
  }
}
