ac-users-type-step {
  .cu-card-display {
    width: 192px;
    height: 130px;
    margin-right: 24px;

    p {
      line-height: 14px;
      margin: 0;
      margin-bottom: 12px;
      font-weight: 400;
    }

    h5 {
      margin: 0;
      margin-bottom: 10px;
    }
  }

  .flex-row {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;

    &.no-wrap {
      flex-wrap: nowrap;
    }
  }
}

ac-users-finish-step {
  .key-value-table tr td:last-child {
    max-width: inherit;
  }
}
