button {
  margin: 0; // remove top and left default margin from Safari

  // cancel-out the default button properties.  adapted from https://stackoverflow.com/a/12642009/24952
  // this class is separate from the `btn` hierarchy because that family of classes have their own
  // base properties.  Use this when we have a button that should look like a link - i.e. it inherits
  // all of its properties from its context.
  &.link {
    align-items: normal;
    background: none;
    border-style: none;
    box-sizing: content-box;
    display: inline;
    font: inherit;
    padding: 0;
    perspective-origin: 0 0;
    text-align: start;
    transform-origin: 0 0;
    -moz-appearance: none;
  }
}

.btn {
  border-radius: $accessibility-button-border-radius;
  @include box-sizing(border-box);
  background-color: transparent;
  border: 1px solid $attribluetion;
  color: $attribluetion;
  cursor: pointer;
  display: inline-block;
  font-family: $default-font-stack;
  font-size: $default-font-size;
  font-weight: $default-normal-weight;
  line-height: 34px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  @include transition($default-transition-ease);

  i {
    margin-right: 10px;

    &.icon-arrow-right {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  &:focus {
    @include default-outline;
    outline-offset: $default-outline-offset;
  }

  &.no-focus-outline:focus {
    outline: none;
  }

  &.no-focus-offset:focus {
    outline-offset: 0;
  }

  &:hover, &:focus, &.btn-active {
    border-color: $smugglers-cove;
    background-color: $smugglers-cove;
    color: $white;
  }

  &[disabled], &.disabled, &.inactive {
    background-color: transparent;
    border-color: $silver-chalice;
    color: $silver-chalice;
    cursor: not-allowed;
    opacity: 1;

    &:focus {
      outline-color: $silver-chalice;
    }
  }

  &.btn--color-amazonite {
    border-color: background('amazonite');
    color: background('amazonite');

    &:hover, &:focus, &.btn-active {
      border-color: background('superadmin');
      background-color: background('superadmin');
      color: background('white');
    }
  }

  /*
    Primary Button (formerly: call to action button)
  */

  &.btn--primary {
    background-color: $attribluetion;
    border-color: $attribluetion;
    color: $white;
    font-weight: $default-semibold-weight;

    &:hover, &:focus, &.btn-active {
      background-color: $smugglers-cove;
      border-color: $smugglers-cove;
    }

    &[disabled], &.disabled, &.inactive {
      background-color: $silver-chalice;
      border-color: $silver-chalice;
    }

    &.btn--color-amazonite {
      border-color: background('amazonite');
      background-color: background('amazonite');

      &:hover, &:focus, &.btn-active {
        border-color: background('superadmin');
        background-color: background('superadmin');
      }
    }
  }

  /*
    Danger Button
  */

  &.btn-danger:not([disabled]):not(.disabled):not(.inactive):not(.btn-link) {
    border-color: $valencia;
    color: $valencia;
    font-weight: $default-normal-weight;

    &.btn--primary {
      background-color: $valencia;
      color: $white;
      font-weight: $default-semibold-weight;
    }

    &:hover, &:focus, &.btn-active {
      background-color: $brick-red;
      border-color: $brick-red;
      color: $white;
      text-decoration: none;
    }

    &:focus {
      outline-color: $brick-red;
    }
  }

  &.btn-danger.btn-link:not([disabled]):not(.disabled):not(.inactive) {
    color: $valencia;

    &:hover, &:focus, &.btn-active {
      color: $brick-red;
    }
  }

  /*
    Pill Button
  */
  &.btn--pill {
    @include pill-button;
    line-height: 24px;
    padding: 0 8px;

    &:not([disabled]) {
      &:hover, &:focus, &:active {
        background-color: $smugglers-cove;
        border-color: $smugglers-cove;
        color: $white;
      }
    }

    &[disabled] {
      color: $silver-chalice;
      border-color: $dark-solitude;
      cursor: not-allowed;
    }

    &:focus {
      @include default-outline;
      outline-offset: $default-outline-offset;
    }

    // Hide Firefox's dotted focus box
    &::-moz-focus-inner {
      border: 0;
    }

    i {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
    }
  }

  /*
    Icon Button
  */

  &.btn-icon {
    line-height: $default-line-height * 1.5;
    padding: 0 8px;

    i {
      margin: 0;
    }

    &:not(.btn--primary) {
      border-color: transparent;

      &:hover, &:focus, &.btn-active {
        background-color: transparent;
        border-color: transparent;
        color: $smugglers-cove;
      }

      &[disabled], &.disabled, &.inactive {
        border-color: transparent;
        background-color: transparent;
        color: $silver-chalice;
        cursor: not-allowed;
      }
    }

    &:focus {
      @include default-outline;
    }

    &.no-focus-outline:focus {
      outline: none;
    }
  }

  /*
    Aligned Button
  */

  &.btn--tertiary-aligned {
    padding-left: 0;
    padding-right: 0;
  }

  /*
    Link Button
  */

  &.btn--tertiary {
    background-color: transparent;
    border: none;
    line-height: $default-line-height * 1.5;
    color: $attribluetion;

    &:hover, &.btn-active {
      color: $attribluetion;
      text-decoration: underline;
    }

    &[disabled], &.disabled, &.inactive {
      color: $silver-chalice;
      text-decoration: none;
    }
  }

  /*
    Color Buttons
  */

  /* stylelint-disable-next-line order/order */
  @each $color-name in $color-set-utility {
    $title: color-title($color-name);
    @if hover($color-name) {
      &.btn--#{$title} {
        outline-color: background($color-name);

        &.btn--tertiary {
          color: background($color-name);

          &:hover, &:focus {
            color: hover($color-name);
          }
        }

        &:not(.btn--tertiary) {
          background-color: background($color-name);
          border-color: background($color-name);
          color: $white;
          font-weight: $default-semibold-weight;

          &:hover, &:focus {
            background-color: hover($color-name);
            border-color: hover($color-name);
          }
        }
      }
    }
  }

  // When there's two buttons next to each other, and the
  // second one has .btn--tertiary-aligned, give it some extra
  // left padding so they're not scrunched together too much.
  + .btn.btn--tertiary.btn--tertiary-aligned {
    padding-left: 16px;
  }

  // Likewise, if there's another button night next to one
  // with .btn--tertiary-aligned, add some left margin to the
  // second button so they aren't scrunched together too much.
  &.btn--tertiary.btn--tertiary-aligned + .btn {
    margin-left: 16px;
  }

  // Green button, only used in Superadmin
  &.btn-green {
    background-color: $white;
    border-color: $silver;
    color: $silver;

    &:hover, &:focus, &.btn-active {
      background-color: $medium-green;
      border-color: $medium-green;
      color: $white;
    }
  }

  /*
    Full-width Button
  */

  &.btn-full-width {
    width: 100%;
  }

  /*
    Outlined Button
  */

  &.btn-outlined {
    border-style: dashed;
    border-width: 1px;
    font-size: 14px;
  }

  /*
    Mini Button
  */

  &.btn-mini {
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;

    &:not(.btn--tertiary-aligned) {
      padding: 0 18px;
    }
  }

  &.btn-inline {
    border: none;
    font-size: $default-font-size;
    font-weight: normal;
    color: $attribluetion;
    padding: 0;
    line-height: $default-line-height;

    &:hover, &:focus, &:active, &.btn-active {
      color: $smugglers-cove;
      background-color: transparent;

      i {
        color: $smugglers-cove;
        border-color: $smugglers-cove;
      }
    }
  }

  /*
    Round Button
  */

  &.btn--round {
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    padding: 0;
    font-size: 20px;
    text-align: center;
    line-height: 100%;

    i {
      margin: 0;
      top: 0;
    }

    &:focus {
      box-shadow: 0 0 0 3px $white, 0 0 6px 5px $attribluetion;
      outline: none;

      &.btn-danger {
        box-shadow: 0 0 0 3px $white, 0 0 6px 5px $valencia;
      }
    }

    &.btn-mini {
      width: 32px;
      height: 32px;
      border-radius: 16px;

      &:not(.btn--tertiary-aligned) {
        padding: 0;
      }
    }
  }

  &.btn--raised:not(.btn--tertiary):not(:focus) {
    @include z-depth(2);
    border: none;
  }

  &.btn-round-fixed-with-text {
    background-color: $attribluetion;
    border: 0;
    bottom: 25px;
    color: $white;
    position: fixed;
    height: 37px;
    line-height: 40px;
    border-radius: 25px;
    padding: 0 13px;
    right: 18px;
    width: auto;
    @include transition(none);
    @include transform(scale(1.05));
    @include transition($default-transition-ease);
    @include z-depth(3);

    &:hover, &:focus {
      background-color: $smugglers-cove;
    }

    .btn-round-text {
      display: inline;
      position: relative;
      top: -2px;
    }
  }

  /*
  Prev / Next / Waiting Buttons
*/
  &.btn--next:not(.btn--waiting-simple):not(.btn--waiting) {
    padding-right: 12px;
    @include icon(after, arrow-right-02, true);

    &::after {
      margin-left: 4px;
      vertical-align: text-bottom;
      font-size: $icon-font-size;
    }
  }

  &.btn--previous:not(.btn--waiting-simple):not(.btn--waiting) {
    padding-left: 12px;
    @include icon(before, arrow-left-02, true);

    &::before {
      margin-right: 4px;
      vertical-align: text-bottom;
      font-size: $icon-font-size;
    }
  }

  &.btn--next, &.btn--previous {
    transition: padding 0s, margin 0s, background $default-transition-ease;

    &::before, &::after {
      transition: padding 0s, margin 0s;
    }
  }

  &.btn--waiting-simple {
    overflow: hidden;
    color: transparent;
    pointer-events: none;
    @include icon(before, repeat, true);

    &::before {
      position: absolute;
      vertical-align: text-bottom;
      color: $smugglers-cove;
      left: calc(50% - 10px);
      top: calc(50% - 10px);
      font-size: $icon-font-size;
      animation: spin 2s infinite linear;
    }

    &.btn--tertiary {
      color: transparent;
    }

    &.disabled, &[disabled] {
      &::before {
        color: $silver-chalice;
      }
    }

    &.btn--previous, &.btn--next {
      /*
      this extra padding ensures the width of a previous/next button
      doesn't change when .btn--waiting-simple is applied to a
      btn--previous or btn--next element.

      36px = 12px + 20px + 4px + 4px
        12px is padding between arrow icon and button edge
        20px is width of arrow icon
        4px is padding between button text & arrow icon
        4px is the change from normal in padding-right on btn--next
      */
      padding-right: 40px;
    }

    &.btn--primary::before {
      color: $white;
    }

    &.btn--tertiary:not(.disabled):not([disabled])::before {
      color: $gun-powder;
    }
  }

  &.btn--waiting {
    pointer-events: none;
    @include icon(before, repeat, true);

    &::before {
      margin-right: 12px;
      vertical-align: text-bottom;
      font-size: $icon-font-size;
      animation: spin 2s infinite linear;
    }
  }

  &.btn--tertiary.btn--tertiary-aligned {
    &.btn--next, &.btn--previous, &.btn--waiting, &.btn--waiting-simple {
      padding-left: 0;
    }
  }
}

/*
  Progress Button
*/

.btn-progress {
  background-color: $light-steel-blue;
  height: 100%;
  width: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.5;
  @include transition(width 0.5s, background $default-transition-ease);
}

.progress-btn {
  overflow: hidden;

  .progress-btn-text {
    position: relative;
    z-index: 1;
    transition: color 0s;

    i.spin-icon, i.icon-arrow-down {
      margin-right: 0;
    }
  }

  i.icon-alert-success, i.icon-alert-error, i.icon-update {
    position: absolute;
    margin: -10px 0 0 -10px;
    left: 50%;
    top: 50%;
    opacity: 0;
    @include transform(scale(.9));
    z-index: 1;
  }

  &.progress-btn-success, &.progress-btn-error, &.progress-btn-spin {
    .progress-btn-text {
      visibility: hidden;
    }
  }

  .progress-btn-fill {
    background-color: $nepal;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    @include transition(width 0.5s, background $default-transition-ease);
  }

  &.btn--primary {
    color: $white;
    border-color: $attribluetion;

    .progress-btn-fill {
      background: $nepal;
    }

    &.progress-btn-spin {
      &:hover, &:focus, &:active {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

  &[disabled], &.disabled {
    .progress-btn-fill {
      background: $dark-solitude;
    }

    &.btn--primary .progress-btn-fill {
      background: $silver;
    }

    &.progress-btn-spin {
      background-color: transparent;
      border-color: transparent;
    }
  }

  &:not([disabled]):not(.disabled):not(.progress-btn--hoverable) {
    transition: color 0s;

    .progress-btn-text {
      transition: color 0s;
    }

    &:hover, &:focus, &:active {
      background-color: transparent;
      border-color: $attribluetion;

      &.progress-btn-spin, &.btn.progress-btn-spin {
        background-color: transparent;
        border-color: transparent;
      }

      .progress-btn-text {
        color: $attribluetion;
      }

      &.btn--primary {
        background-color: $attribluetion;

        .progress-btn-text {
          color: $white;
        }
      }
    }

    &.progress-btn-success {
      border-color: $frosted-mint;
      color: $forest-green;
      cursor: default;

      .progress-btn-fill {
        background-color: $frosted-mint;
        opacity: 1;
      }

      .progress-btn-success-icon {
        opacity: 1;
        @include transform(scale(1));
      }
    }

    &.progress-btn-error {
      border-color: $cosmos;
      background-color: transparent;
      color: $brick-red;
      cursor: default;

      .progress-btn-fill {
        background-color: $cosmos;
        opacity: 1;
      }

      .progress-btn-error-icon {
        opacity: 1;
        @include transform(scale(1));
      }
    }
  }

  &.progress-btn-spin {
    border-color: $white;
    background-color: transparent;
    color: $gun-powder;
    cursor: progress;

    .progress-btn-spin-icon {
      opacity: 1;
      @include transform(scale(1));
    }
  }
}

/*
  Button Group
*/

.btn-group {
  position: relative;
  display: inline-block;
  font-size: 0;
  vertical-align: middle;
  white-space: nowrap;

  &.btn--tertiary-group,
  &.btn--tertiary-group-aligned {
    .btn {
      font-weight: $default-normal-weight;

      &.btn-group-active {
        font-weight: $default-semibold-weight;
        background-color: transparent;
        border-color: transparent;
        color: $smugglers-cove;
      }
    }
  }

  &.btn--tertiary-group-aligned {
    .btn.btn--tertiary:last-child {
      padding-right: 0px;
    }

    .btn.btn--tertiary:first-child {
      padding-left: 0px;
    }
  }

  &.btn-group--inset {
    .btn {
      border-width: 0;
      border-radius: 0;
      font-weight: $default-semibold-weight;

      &:not(.btn--primary):not(.disabled):not(.btn-group-active) {
        background-color: $light-steel-blue;
        color: $attribluetion;

        &:hover {
          background-color: $smugglers-cove;
          color: $white;
        }
      }

      &:first-child, &:last-child {
        border-radius: 0;
      }
    }
  }

  &.btn-group-full-width {
    display: flex;
    width: 100%;

    .btn {
      flex-basis: 100%;
    }
  }

  .btn-group-active {
    background-color: $smugglers-cove;
    border-color: $smugglers-cove;
    color: $white;

    &.btn--round {
      background-color: $smugglers-cove;
      border-color: $smugglers-cove;
    }
  }

  .btn-mini {
    height: 23px;
  }

  .btn-split-dropdown-toggle {
    i {
      margin-left: 0;
      margin-right: 0;
      padding: 0 2px;
    }
  }

  // overwrite dropdown style specific for down arrows
  .icon-arrow-down {
    float: none;
    line-height: initial;
  }

  &.btn--primary {
    border-left: 1px solid $smugglers-cove;
  }

  &.btn-danger {
    border-left: 1px solid $brick-red;
  }

  .dropdown-menu {
    right: 0;
    left: initial;

    &.drop-up {
      bottom: 100%;
      top: initial;
    }
  }

  > .btn {
    position: relative;
    border-radius: 0;
    vertical-align: middle;
  }

  > .btn + .btn {
    margin-left: -1px;
  }

  > .btn:not(.btn--round):first-child {
    border-radius: $accessibility-button-border-radius 0 0 $accessibility-button-border-radius;
  }

  > .btn:not(.btn--round):last-child {
    border-radius: 0 $accessibility-button-border-radius $accessibility-button-border-radius 0;
  }
}

/*
  Pagination Buttons
*/

.btn-group-pagination {
  .btn {
    border: 0;
    font-weight: $default-normal-weight;
    padding: 0 13px;
    border-radius: 2em;

    &:hover:not(.btn-group-active), &:focus:not(.btn-group-active) {
      background-color: transparent;
      text-decoration: underline;
      color: $smugglers-cove;
    }

    &.btn-group-active {
      padding: 0;
      height: 36px;
      width: 36px;
      border-radius: 2em;
      outline: 0;

      // The .btn-group section above is causing first and last
      // page links appear square. This section maintains roundness.
      &:first-child, &:last-child {
        border-radius: 2em;
      }
    }

    &.contains-pagination-ellipsis {
      cursor: default;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &:focus {
      @include default-outline;
    }
  }
}

/*
  White Label
*/

.white-label {
  .btn {
    color: $gun-powder;
    border-color: $gun-powder;

    &:hover, &:focus, &.btn-active {
      color: $white;
      background-color: $mine-shaft;

      &.btn-inline:focus {
        background: transparent;

        i {
          color: $mine-shaft;
        }
      }
    }

    &:focus {
      outline-color: $default-outline-color-whitelabel;
    }

    &[disabled], &.disabled, &.inactive {
      border-color: $silver-chalice;
      background-color: transparent;
      color: $silver-chalice;
      cursor: not-allowed;

      &:focus {
        outline-color: $silver-chalice;
      }
    }

    &.btn-inline {
      &:hover, &:focus, &:active, &.btn-active {
        color: $mine-shaft;
        background-color: transparent;

        i {
          color: $mine-shaft;
        }
      }

      &[disabled], &.disabled {
        color: $gun-powder;
      }
    }

    // Primary Button (formerly: call to action)
    &.btn--primary {
      color: $white;
      background-color: $gun-powder;
      border-color: $gun-powder;

      &:hover, &:focus, &:active {
        background-color: $mine-shaft;
        border-color: $mine-shaft;
      }

      &[disabled], &.disabled, &.inactive {
        color: $white;
        background-color: $silver-chalice;
        border-color: $silver-chalice;
      }
    }

    &.btn-icon {
      &:not(.btn--primary) {
        &:hover, &:focus, &.btn-active {
          background-color: transparent;
          border-color: transparent;
          color: $mine-shaft;
        }

        &[disabled], &.disabled, &.inactive {
          border-color: transparent;
          background-color: transparent;
          color: $silver-chalice;
          cursor: not-allowed;
        }
      }
    }

    // Link Button
    &.btn--tertiary {
      &:hover, &:focus, &:active {
        background-color: transparent;
        color: $mine-shaft;
      }

      &[disabled], &.disabled {
        color: $silver-chalice;
      }
    }

    // ignore white label for lavender buttons
    // fixes upsell issue for now but needs a more permanent solution
    /* stylelint-disable-next-line order/order */
    @each $color-name in $color-set-utility {
      $title: color-title($color-name);
      @if hover($color-name) {
        &.btn--#{$title} {
          background-color: background($color-name);
          border-color: background($color-name);
          color: $white;

          &:hover, &:focus {
            background-color: hover($color-name);
            border-color: hover($color-name);
          }
        }
      }
    }

    .btn-progress {
      background-color: $silver;
    }

    &.btn--waiting-simple {
      color: transparent;

      &:not(.disabled):not([disabled]) {
        &::before {
          color: $gun-powder;
        }

        &.btn--primary::before {
          color: $white;
        }

        &.btn--tertiary::before {
          color: $gun-powder;
        }
      }

      &.disabled, &[disabled] {
        color: transparent;
      }
    }
  }

  .progress-btn {
    .progress-btn-text {
      color: $gun-powder;
    }

    .progress-btn-fill {
      background-color: $silver;
    }

    &.btn--primary {
      border-color: $gun-powder;

      .progress-btn-text {
        color: $white;
      }

      .progress-btn-fill {
        background: $silver;
      }
    }

    &[disabled], &.disabled {
      .progress-btn-fill {
        background: $dark-solitude;
      }

      .progress-btn-text {
        color: $silver-chalice;
      }

      &.btn--primary {
        .progress-btn-fill {
          background: $silver;
        }

        .progress-btn-text {
          color: $white;
        }
      }
    }

    &:not([disabled]):not(.disabled) {
      &:hover, &:focus, &:active {
        .progress-btn-text {
          color: $white;
        }
      }

      &:not(.progress-btn--hoverable):not(.progress-btn-success):not(.progress-btn-error) {
        &:hover, &:focus, &:active {
          border-color: $gun-powder;

          .progress-btn-text {
            color: $gun-powder;
          }

          &.btn--primary {
            background-color: $gun-powder;

            .progress-btn-text {
              color: $white;
            }
          }

          .progress-btn-fill {
            background: $silver;
          }
        }
      }
    }

    &.progress-btn-spin {
      border-color: $white;
      background-color: transparent;
      color: $gun-powder;
    }

    &.progress-btn-success {
      border-color: $frosted-mint;
      color: $forest-green;

      .progress-btn-fill {
        background-color: $frosted-mint;
      }
    }

    &.progress-btn-error {
      border-color: $cosmos;
      background-color: transparent;
      color: $brick-red;

      .progress-btn-fill {
        background-color: $cosmos;
      }
    }
  }

  .btn-group-pagination {
    .btn:hover:not(.btn-group-active) {
      color: $mine-shaft;
    }
  }

  .btn.btn-round-fixed-with-text {
    background-color: $gun-powder;

    &:hover, &:focus {
      background-color: $mine-shaft;
    }
  }

  .btn-group {
    &.btn-group--inset {
      .btn {
        &:not(.btn--primary):not(.disabled) {
          background-color: $dark-solitude;
          color: $gun-powder;

          &:hover {
            background-color: $mine-shaft;
            color: $white;
          }
        }
      }
    }
  }
}

/*
  Google Button
*/

.google-signin {
  background: cr-image-url('btn_google_signin_dark_normal_web@2x.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  width: 191px;
  height: 46px;
  border: none;

  &:hover {
    background: cr-image-url('btn_google_signin_dark_focus_web@2x.png');
    background-size: 100% auto;
  }

  &:focus {
    background: cr-image-url('btn_google_signin_dark_normal_web@2x.png');
    background-size: 100% auto;
  }

  &:disabled {
    background: cr-image-url('btn_google_signin_dark_disabled_web@2x.png');
    background-size: 100% auto;
  }
}
