@import 'settings';

$input-card-padding: 24px;

.input-card {
  position: relative;
  display: block; // necessary when the entire card is a label to make it clickable

  // Use to move the input box to the right side of the label
  .giant-right-input {
    input {
      float: right;
    }

    // Empty circle
    label {
      margin: 0;
      width: 100%;

      &::before {
        content: "";
        float: right;
        margin: 0;
        top: 3px;
      }

      &.mini-label {
        font-size: 12px;
        font-weight: 600;
        text-align: right;
      }
    }
  }

  .card-header {
    border-bottom: none;
    padding-bottom: 0px;

    .giant-right-input label::before {
      top: 3px;
    }
  }

  .card-header--border {
    border-bottom: $default-border;
    padding-bottom: calc(#{$input-card-padding} * 0.5);
  }

  .card-header + .card-body {
    padding-top: calc(#{$input-card-padding} * 0.5);
  }

  .card-body {
    .giant-right-input {
      position: absolute;
      top: 0;
      right: 0;

      label::before {
        // needs to be 18px from top and right
        right: 18px;
        top: 16px;
      }
    }
  }

  &.disabled-card {
    @include interactive-disabled;
  }

  &.selected-card {
    background-color: $solitude;
    border-color: $dark-solitude;
    @include box-shadow(inset 0px 0px 0px 2px $dark-solitude);
  }

  &.selected-card--disabled {
    background-color: $white;
    border-color: $dark-solitude;
    cursor: default;

    .giant-input label {
      cursor: default;
    }
  }

  &.img-input-card {
    @include flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    .img-input-card-img img {
      height: 32px;
      max-width: initial;
    }

    .card-header {
      padding: 0 0 0 16px;
      width: 100%;

      .img-input-card-label {
        font-family: $default-font-stack;
        font-size: 16px;
        font-weight: 600;
        line-height: 32px;
        height: 32px;
      }
    }
  }
}

label.input-card {
  cursor: pointer;
}
