.recorder {
  width: 388px;
  line-height: 46px;
  height: 46px;
  border: solid 1px $shadow-color;
  background: $white;
  border-radius: 2px;

  .recorder-volume-container {
    padding-top: 1px;
    margin-left: 4px;
    height: 100%;
  }

  .recorder-duration {
    margin: 0 14px;
    font-size: 14px;
    color: $smugglers-cove;
  }

  .btn {
    padding: 0;
    margin: 0;
    line-height: 46px;
    height: 46px;
    width: 39px;
    border: none;
    border-right: solid 1px $shadow-color;
    border-radius: 0;

    &:hover, &:focus {
      border: none;
      border-right: solid 1px $shadow-color;
    }

    i {
      margin: 0;
    }
  }
}
