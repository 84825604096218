/*
  Default Tooltips
*/

$tooltip-large-width: 268px;
$tooltip-large-padding: 16px;
$tooltip-large-offset: calc(134px + 16px + 1px); // ($tooltip-large-width / 2) + padding + border
$tooltip-v-offset: 18px;
$tooltip-h-offset: 15px;
$tooltip-lr-hoverable-area-width: $tooltip-h-offset + 6px;
$tooltip-bt-hoverable-area-height: $tooltip-v-offset + 6px;
$tooltip-top-left-arrow-right-offset: 8px;
$tooltip-top-left-offset-v: 12px;
$tooltip-top-left-offset-h: 12px;
$tooltip-arrow-size: 8px;
$tooltip-arrow-offset: -6px;
$tooltip-lr-arrow-top-offset: 9px;
