@import 'mixins';
@import 'colors';
@import 'icon-list';

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  color: $mine-shaft;
  outline: none;

  & i {
    margin-right: 10px;
  }

  .pills-pill {
    margin-right: 5px;
    background-color: $ringo-mint;
    color: $solitude;
  }
}

h1 i, h3 i {
  vertical-align: baseline !important;
}

h1 {
  font-size: 30px;
  line-height: $default-line-height * 1.5;
  margin-bottom: $default-margin-bottom;
  font-weight: $default-semibold-weight;

  &.lite, .lite {
    font-weight: 200;
  }

  .pills-pill {
    margin-top: 9px;
  }
}

h2 {
  color: $mine-shaft;
  font-size: 18px;
  line-height: $default-line-height * 1.5;
  margin-bottom: $default-margin-bottom;
  font-weight: $default-normal-weight;

  .pills-pill {
    margin-top: 6px;
  }
}

h1 .sub-header, h2 .sub-header, h4 .sub-header {
  position: absolute;
  display: block;
  margin-bottom: 0;
  color: $silver;
  font-size: 14px;
  font-weight: $default-normal-weight;
  line-height: normal;
}

h1 .sub-header, h4 .sub-header {
  margin-top: 0;
}

h2 .sub-header {
  margin-top: -6px;
}

h3 {
  color: $mine-shaft;
  font-size: 23px;
  line-height: $default-line-height * 2;
  font-weight: $default-normal-weight;

  .pills-pill {
    margin-top: 14px;
  }
}

h4 {
  font-size: 18px;
  line-height: $default-line-height;
  margin-bottom: calc(#{$default-margin-bottom} * 0.5);
  font-weight: $default-normal-weight;

  .pills-pill {
    margin-top: 0px;
  }
}

h5 {
  font-size: 18px;
  line-height: $default-line-height;
  margin-bottom: $default-margin-bottom;
  font-weight: $default-normal-weight;

  .pills-pill {
    margin-top: 0px;
  }
}

h6 {
  color: $default-font-color;
  font-size: 18px;
  line-height: $default-line-height;
  margin-bottom: calc(#{$default-margin-bottom} * 0.25);
  font-weight: $default-semibold-weight;

  .pills-pill {
    margin-top: 0px;
  }
}

.breadcrumbs {
  font-size: 12px;
  line-height: 18px;
  color: $mine-shaft;

  .divider {
    margin: 0px 3px;
    color: $silver-chalice;
    @include icon(before, arrow-right);
  }

  .label {
    margin-right: 5px;
    color: $silver-chalice;
  }
}

.header--promoted {
  color: $amazonite;
}

.display-digits {
  font-size: 48px;
  line-height: $default-line-height * 2;
  font-weight: $default-semibold-weight;
  letter-spacing: 4px;
}

.divider {
  vertical-align: middle;
}
// commit that updates the name of this b4 other commits
.link-bold-attribluetion {
  @include link-bold($attribluetion);
}

.link-bold-steel-blue {
  @include link-bold($steel-blue);
}

.link-double-silver-chalice {
  @include link-double($silver-chalice);
}

/*
Hierarchal Headers
*/

h1.hierarchal-heading {
  font-size: 30px;
  line-height: $default-line-height * 1.5;
  margin-bottom: $default-margin-bottom;
  font-weight: $default-semibold-weight;

  &.lite, .lite {
    font-weight: 200;
  }

  .pills-pill {
    margin-top: 9px;
  }
}

h2.hierarchal-heading {
  color: $mine-shaft;
  font-size: 22px;
  line-height: $default-line-height * 1.5;
  margin-bottom: $default-margin-bottom;
  font-weight: $default-normal-weight;

  .pills-pill {
    margin-top: 6px;
  }
}

h3.hierarchal-heading {
  color: $mine-shaft;
  font-size: 20px;
  line-height: $default-line-height * 2;
  font-weight: $default-normal-weight;

  .pills-pill {
    margin-top: 14px;
  }
}

h4.hierarchal-heading {
  font-size: 18px;
  line-height: $default-line-height;
  margin-bottom: calc(#{$default-margin-bottom} * 0.5);
  font-weight: $default-normal-weight;

  .pills-pill {
    margin-top: 0px;
  }
}

h5.hierarchal-heading {
  font-size: 16px;
  line-height: $default-line-height;
  margin-bottom: $default-margin-bottom;
  font-weight: $default-normal-weight;

  .pills-pill {
    margin-top: 0px;
  }
}

h6.hierarchal-heading {
  color: $default-font-color;
  font-size: 14px;
  line-height: $default-line-height;
  margin-bottom: calc(#{$default-margin-bottom} * 0.25);
  font-weight: $default-semibold-weight;

  .pills-pill {
    margin-top: 0px;
  }
}

/*
  White Label
*/

.white-label {
  h1, h2, h3, h4, h5, h6 {
    color: $default-font-color;
  }
}
