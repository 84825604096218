/* Dead Simple Grid (c) 2015 Vladimir Agafonkin
https://github.com/mourner/dead-simple-grid/blob/gh-pages/README.md
*/

.ds-row .ds-row {
  margin: 0 -1em;
}

.ds-col {
  padding: 0 1em;
}

.ds-row:after {
  content: "";
  clear: both;
  display: table;
}

@media only screen {
  .ds-col {
    float: left;
    width: 100%;
    box-sizing: border-box;
  }
}

/** CSS Grid - WIP **/
/*
  This is an experimental set of classes to implement some re-usable class names
  to build a responsive layout using CSS Grid.
*/
.ds-grid-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  column-gap: 1em;
  row-gap: 0;
  justify-items: stretch;
  align-items: start;
  justify-content: start;
}

.ds-grid-item {
  box-sizing: border-box;
}
