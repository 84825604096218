@import 'libs/looky/ui/styles/functions';

.announcements {
  height: 100vh;
  background-color: var(--color-attribluetion);
  background-image: cr-image-url('login-background-pattern.svg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .card {
    width: 410px;
    font-size: 16px;
    box-shadow: 0px 15px 35px rgba(var(--color-black), 0.1);
    box-sizing: border-box;
  }
}

// Media queries
@media screen and (max-width: 1190px) {
  .announcements .card {
    width: 300px;
  }
}

@media screen and (max-width: 940px) {
  .announcements {
    display: none !important;
  }
}
